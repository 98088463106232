import useUser from "lib/hooks/useUser";
import React from "react";
import ClientHeader from "shared/components/ClientHeader";
import CompleteYourProfile from "shared/components/CompleteYourProfile";
import SuspenseWrapper from "shared/components/SuspenseWrapper";
import WillContactYouSoon from "shared/components/WillContactYousoon";
import SideBar from "./SideBar";

function MainLayout(props) {
  const { company } = useUser();
  return (
    <div className="h-full">
      <ClientHeader />
      <div className="flex">
        <SideBar />
        <div className="flex-1 py-20" id="pageContent">
          {company?.isVerified && <CompleteYourProfile />}
          <SuspenseWrapper>
            {company?.isVerified ? props.children : <WillContactYouSoon />}
          </SuspenseWrapper>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
