export const removeItemAtIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 *
 * @param index
 * @param array
 * @param item
 *
 * @returns array with the modified item
 */

export const editItemAtIndex = (index, array, item): Array<any> => {
  let _array = [...array];
  let _item = _array[index];
  _item = {
    ..._item,
    ...item,
  };
  _array[index] = _item;
  return _array;
};

/**
 *
 * @param array
 *
 * Eliminate empty values from array
 * @returns array without empty values
 */

export const eliminateEmptyValues = (array) => {
  return array.filter(function (el) {
    return !!el;
  });
};

/**
 *
 * @param array
 * @param value value hows the array will be sort by
 *
 * @returns sorted array
 */

export const sortBy = (
  array,
  value?,
  options?: { valueType?: string; sortType?: string }
) => {
  return array.sort(function (a, b) {
    if (value && options?.valueType === "date") {
      const aDate = new Date(a[value]) as any;
      const bDate = new Date(b[value]) as any;
      return options?.sortType === "desc" ? bDate - aDate : aDate - bDate;
    } else if (!value && options?.valueType === "date") {
      const aDate = new Date(a) as any;
      const bDate = new Date(b) as any;
      return options?.sortType === "desc" ? bDate - aDate : aDate - bDate;
    } else {
      return options?.sortType === "desc"
        ? b[value] - a[value]
        : a[value] - b[value];
    }
  });
};

/**
 * This function is used to `group by` array of objects based on two props
 * @param array
 * @param props
 */

export const groupByMultible = (array, props) => {
  const helper = {};

  const result = array.reduce(function (r, o) {
    var key = o[props[0]] + "&&" + o[props[1]];
    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].used += o.used;
      helper[key].instances += o.instances;
    }

    return r;
  }, []);

  return result;
};

/**
 * This function is used to check if two arrays are equal or not
 */

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

/**
 * This function is used to convert object to an array of objects
 * @param object
 *
 * @returns array of objects
 */

export const convertObjectToArray = (object) => {
  const keys = Object.keys(object).filter((key) => (key ? key : null));
  const values = Object.values(object).map((value, key) => ({
    value,
    key: keys[key],
  }));
  return values;
};

export const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};
