import { chatBotApis } from "lib/apis/chatbot-apis";
import { ChatBotContext } from "lib/contexts/ChatBotContext";
import React from "react";

const useChatBot = () => {
  const { chatbotState, dispatchChatbotState } = React.useContext(
    ChatBotContext
  ) as any;

  const dispatch = (action) => {
    action({ chatbotState, dispatchChatbotState });
  };

  const getActiveRooms = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const { botRoom } = (await chatBotApis.rooms.listActive()) as any;
        resolve(botRoom);
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const createRoom = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const { botRoom } = (await chatBotApis.rooms.create()) as any;
        resolve(botRoom);
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const getRoom = ({ roomId }) => {
    return new Promise((resolve, reject) => {
      try {
        const { botRoom } = chatBotApis.rooms.getById({ roomId }) as any;
        resolve(botRoom);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getRoomMessages = ({ roomId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const botRoomMessages = (await chatBotApis.rooms.getMessages({
          roomId,
        })) as any;

        console.log(botRoomMessages, "botRoomMessagesbotRoomMessages");

        resolve(botRoomMessages);
      } catch (error) {
        reject(error);
      }
    });
  };

  const endRoom = ({ roomId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { botRoom } = (await chatBotApis.rooms.end({ roomId })) as any;
        resolve(botRoom);
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    getActiveRooms,
    createRoom,
    getRoom,
    getRoomMessages,
    endRoom,
    dispatch,
    chatbotState,
  };
};

export default useChatBot;
