import React from "react";
import Logo from "../Logo";

import useSiteLanguages from "lib/hooks/useSiteLanguages";
import useLocales from "lib/hooks/useLocales";

export default function () {
  const { changeLanguage } = useSiteLanguages();
  const header = useLocales("header") as any;
  const handleChangeLanguage = () => {
    changeLanguage();
    window.location.reload();
  };

  return (
    <div className="flex p-4 px-16 justify-between">
      <Logo />
      <h1 className="font-avenir-medium text-dark" onClick={handleChangeLanguage}>
        {header.language}
      </h1>
    </div>
  );
}
