import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import configureStore from "./lib/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ThemeProvider } from "./theme/global/styled-components";
import { theme } from "./theme/global/theme.ts";
import { GlobalStyle } from "./theme/global/style";
import { ScrollingProvider } from "lib/contexts/ScrollingContext";
import NotificationsState from "lib/contexts/NotificationContext";
import AuthState from "lib/contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import "./lib/apis";
// import "./lib/services/chatbot";
import "antd/dist/antd.css";
import "./css/index.scss";
import SurveyState from "lib/contexts/SurveyContext";
import ChatBotProvider from "lib/contexts/ChatBotContext";
import { createRoot } from "react-dom/client";

export const { store, persistor } = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    <NotificationsState>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AuthState>
              <ScrollingProvider>
                <SurveyState>
                  <ChatBotProvider>
                    <ThemeProvider theme={theme}>
                      <App />
                      <GlobalStyle />
                    </ThemeProvider>
                  </ChatBotProvider>
                </SurveyState>
              </ScrollingProvider>
            </AuthState>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </NotificationsState>
  </>
);

// serviceWorker.unregister();
