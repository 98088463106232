export enum KaderAppLinks {
  ANDROID = "https://play.google.com/store/apps/details?id=com.kader.recruiterapp&hl=en&gl=US",
  IOS = "https://apps.apple.com/jo/app/kader-hiring-immediate-staff/id1497724500",
}

export enum SocialLinks {
  FACEBOOK = "https://www.facebook.com/kaderapp",
  INSTAGRAM = "https://www.instagram.com/kaderapp/?utm_source=ig_profile_share&igshid=1e466gbasqcp0",
  TWITTER = "https://twitter.com/kaderapp",
  LINKEDIN = "https://www.linkedin.com/company/kaderapp",
}

export enum KaderSites {
  RECRUITER = "https://recruiter.kaderapp.com/",
}

export enum KaderEmails {
  SUPPORT = "info@kaderapp.com",
  APPLY_CAREERS = "family@kaderapp.com",
}

export const firebaseConfig = {
  apiKey: "AIzaSyBKwyNzcsgGDUC1xDJrZWY2gDS6sm8PXqA",
  authDomain: "kaderapp-employers.firebaseapp.com",
  projectId: "kaderapp-employers",
  storageBucket: "kaderapp-employers.appspot.com",
  messagingSenderId: "37500290710",
  appId: "1:37500290710:web:24789a0d6ad44f1702e4b7",
  measurementId: "G-XP97FZRKPM",
};

export enum routesConstants {
  BASE_PATHNAME = "/",
  SUCCESS_SCREEN = "/done/:title",
  HOME = "/dashboard",
  JOBS = "/jobs",
  FAVORITES = "/favorites",
  TOP_KADEROS = "/top-kaderos",
  SHIFTS_LOGS = "/shifts-logs",
  CHAT_BOT = "/chatbot/:roomId",
  NOT_FOUND = "/404",
  //GUEST
  GUEST = "/guest",
  GUEST_LOGIN = `/guest/login`,
  GUEST_REGISTER = "/guest/register",
  GUEST_OTP = "/guest/otp/:phone",

  //VERIFICATION
  VERIFICATION = "/verification",
  VERIFICATION_EMAIL = "/verification/email",

  //FORGOT PASSWORD
  FORGOT_PASSWORD = "/forgot-password",
  FORGOT_PASSWORD_SEND_CODE = "/forgot-password/send-code",
  FORGOT_PASSWORD_SEND_VERIFICATION = "/forgot-password/send-verification",
  FORGOT_PASSWORD_VERIFICATION = "/forgot-password/verification",
  FORGOT_PASSWORD_NEW_PASSWORD = "/forgot-password/new-password",

  //PROFILE
  PROFILE = "/profile",
  PROFILE_RECRUITER = "/recruiter-profile",
  COMPANY_PROFILE = "/company",
  PROFILE_COMPANY = "/profile/company",
  PROFILE_BOOKING_STATISTICS = "/profile/booking-statistics",
  PROFILE_BALANCE = "/company/balance",
  CREDIT_CARD_REDIRECT = "/credit-card",

  //BOOKING
  BOOKING = "/job",
  BOOKING_VIEW = "/job/:gigId",
  NEW_JOB = "/jobs/create",
  NEW_JOB_CREATED = "/jobs/create/success",
  EDIT_JOB = "/jobs/edit/:gigId",
  REPOST_JOB = "/jobs/re-post/:gigId",
  CREATE_BOOKING = "/gig-create",
  BOOKING_DESCRIBE = "/gig-create/describe",
  BOOKING_PAYMENT = "/gig-create/payment",

  //Surveys
  SURVEYS = "/surveys",
  CREATE_SURVEY = "/surveys-create",
  EDIT_SURVEY = "/surveys-edit/:surveyId",
  SURVEY = "/survey/:surveyId",
  USER = "/user/:userId",
}

export const whiteListedRoutes = [
  routesConstants.GUEST_REGISTER,
  routesConstants.GUEST_LOGIN,
  routesConstants.VERIFICATION_EMAIL,
  routesConstants.FORGOT_PASSWORD_SEND_VERIFICATION,
  routesConstants.FORGOT_PASSWORD_NEW_PASSWORD,
  routesConstants.FORGOT_PASSWORD_VERIFICATION,
  routesConstants.FORGOT_PASSWORD_SEND_CODE,
] as any;

export const mobileAllowed = [
  routesConstants.VERIFICATION_EMAIL,
  routesConstants.FORGOT_PASSWORD_SEND_VERIFICATION,
  routesConstants.FORGOT_PASSWORD_NEW_PASSWORD,
  routesConstants.FORGOT_PASSWORD_VERIFICATION,
  routesConstants.FORGOT_PASSWORD_SEND_CODE,
];
