import React from "react";
//Guest
import GuestStory from "../shared/layouts/guest";
import RegisterationStory from "../shared/layouts/registeration";
import VerifyLayout from "../shared/layouts/registeration/VerifyLayout";

import Client from "../shared/layouts/main";

import Profile from "pages/Profile";
import CreateGigLayout from "shared/layouts/CreateGigLayout";
import { routesConstants } from "lib/constants/urls";

const Dashboard = React.lazy(() => import("pages/Dashboard"));
const Jobs = React.lazy(() => import("pages/Jobs"));

const RecruiterProfile = React.lazy(() => import("pages/RecruiterProfile"));
const BookingStatistics = React.lazy(() => import("pages/BookingStatistics"));
const CompanyBalance = React.lazy(() => import("pages/CompanyBalance"));
const SurveyScreen = React.lazy(() => import("pages/Survey"));
const UserScreen = React.lazy(() => import("pages/User"));

const CompanyProfile = React.lazy(() => import("pages/CompanyProfile"));

const GigView = React.lazy(() => import("pages/GigView"));
const DescribeGig = React.lazy(
  () => import("pages/CreateGigStory/DescribeGig")
);
const GigPayment = React.lazy(() => import("pages/CreateGigStory/Payment"));
const GigPosted = React.lazy(() => import("pages/CreateGigStory/GigPosted"));
const CreditCardRedirect = React.lazy(() => import("pages/CreditCardRedirect"));

const RegisterOTPForm = React.lazy(
  () => import("pages/RegisterStory/RegisterOTPForm")
);

const Login = React.lazy(() => import("pages/GuestStory/Login"));
const Register = React.lazy(() => import("pages/GuestStory/Register"));
const loginOTPForm = React.lazy(() => import("pages/GuestStory/LoginOTPForm"));
const ForgotPassword = React.lazy(
  () => import("pages/GuestStory/ForgetPasswordStory/ForgotPassword")
);
const ForgotPasswordOTPForm = React.lazy(
  () => import("pages/GuestStory/ForgetPasswordStory/ForgotPasswordOTPForm")
);
const VerifyEmail = React.lazy(() => import("pages/GuestStory/VerifyEmail"));
const ResetPasswordForm = React.lazy(
  () => import("pages/GuestStory/ForgetPasswordStory/ResetPasswordForm")
);

const NewJob = React.lazy(() => import("pages/NewJob"));
const SuccessScreen = React.lazy(() => import("pages/SuccessScreen"));
const EditJob = React.lazy(() => import("pages/EditJob"));
const RepostJob = React.lazy(() => import("pages/RepostJob"));

const SurveysPage = React.lazy(() => import("pages/SurveysPage"));
const CreateSurveyPage = React.lazy(
  () => import("pages/SurveysPage/CreateSurvey")
);

const EditSurveyPage = React.lazy(() => import("pages/SurveysPage/EditSurvey"));
const Favorites = React.lazy(() => import("pages/Favorites"));
const ShiftsLogs = React.lazy(() => import("pages/ShiftsLogs"));

const ChatBot = React.lazy(() => import("pages/chatbot"));

function NotFound() {
  return (
    <div>
      <span>Not found</span>
    </div>
  );
}

const routes = [
  {
    path: routesConstants.GUEST,
    component: GuestStory,
    children: [
      {
        path: routesConstants.GUEST_LOGIN,
        key: routesConstants.GUEST_LOGIN,
        component: Login,
      },
      {
        // path: "/guest/otp/:phone",
        path: routesConstants.GUEST_OTP,
        key: routesConstants.GUEST_OTP,
        component: loginOTPForm,
      },
      {
        path: routesConstants.GUEST_REGISTER,
        key: routesConstants.GUEST_REGISTER,
        component: Register,
      },
    ],
  },
  {
    path: routesConstants.VERIFICATION,
    component: VerifyLayout,
    children: [
      {
        path: routesConstants.VERIFICATION_EMAIL,
        key: routesConstants.VERIFICATION_EMAIL,
        component: VerifyEmail,
      },
    ],
  },
  {
    path: routesConstants.FORGOT_PASSWORD,
    component: RegisterationStory,
    children: [
      {
        path: routesConstants.FORGOT_PASSWORD_SEND_CODE,
        key: routesConstants.FORGOT_PASSWORD_SEND_CODE,
        component: RegisterOTPForm,
      },
      {
        path: routesConstants.FORGOT_PASSWORD_SEND_VERIFICATION,
        key: routesConstants.FORGOT_PASSWORD_SEND_VERIFICATION,
        component: ForgotPassword,
      },
      {
        path: routesConstants.FORGOT_PASSWORD_VERIFICATION,
        key: routesConstants.FORGOT_PASSWORD_VERIFICATION,
        component: ForgotPasswordOTPForm,
      },
      {
        path: routesConstants.FORGOT_PASSWORD_NEW_PASSWORD,
        key: routesConstants.FORGOT_PASSWORD_NEW_PASSWORD,
        component: ResetPasswordForm,
      },
    ],
  },
  {
    path: routesConstants.PROFILE,
    authenticated: true,
    component: Client,
    children: [
      {
        path: routesConstants.PROFILE_RECRUITER,
        key: routesConstants.PROFILE_RECRUITER,
        component: RecruiterProfile,
      },
      {
        path: routesConstants.PROFILE_COMPANY,
        key: routesConstants.PROFILE_COMPANY,
        component: CompanyProfile,
      },
      {
        path: routesConstants.PROFILE_BOOKING_STATISTICS,
        key: routesConstants.PROFILE_BOOKING_STATISTICS,
        component: BookingStatistics,
      },
    ],
  },
  {
    path: routesConstants.BOOKING,
    authenticated: true,
    component: Client,
    children: [
      {
        path: routesConstants.BOOKING_VIEW,
        key: routesConstants.BOOKING_VIEW,
        component: GigView,
      },
    ],
  },
  {
    path: routesConstants.CREATE_BOOKING,
    authenticated: true,
    component: CreateGigLayout,
    children: [
      {
        path: routesConstants.BOOKING_DESCRIBE,
        key: routesConstants.BOOKING_DESCRIBE,
        component: DescribeGig,
      },
      {
        path: routesConstants.BOOKING_PAYMENT,
        key: routesConstants.BOOKING_PAYMENT,
        component: GigPayment,
      },
    ],
  },
  {
    path: routesConstants.BASE_PATHNAME,
    exact: true,
    authenticated: true,
    component: Client,
    children: [
      {
        path: routesConstants.USER,
        key: routesConstants.USER,
        component: UserScreen,
      },
      {
        path: routesConstants.SURVEY,
        key: routesConstants.SURVEY,
        component: SurveyScreen,
      },
      {
        path: routesConstants.CREATE_SURVEY,
        key: routesConstants.CREATE_SURVEY,
        component: CreateSurveyPage,
      },
      {
        path: routesConstants.EDIT_SURVEY,
        key: routesConstants.EDIT_SURVEY,
        component: EditSurveyPage,
      },
      {
        path: routesConstants.FAVORITES,
        key: routesConstants.FAVORITES,
        component: Favorites,
      },
      {
        path: routesConstants.TOP_KADEROS,
        key: routesConstants.TOP_KADEROS,
        component: Favorites,
      },
      {
        path: routesConstants.SHIFTS_LOGS,
        key: routesConstants.SHIFTS_LOGS,
        component: ShiftsLogs,
      },
      {
        path: routesConstants.CHAT_BOT,
        key: routesConstants.CHAT_BOT,
        component: ChatBot,
      },
      {
        path: routesConstants.PROFILE_BALANCE,
        key: routesConstants.PROFILE_BALANCE,
        component: CompanyBalance,
      },
      {
        path: routesConstants.COMPANY_PROFILE,
        authenticated: true,
        component: CompanyProfile,
      },
      {
        path: routesConstants.PROFILE_RECRUITER,
        authenticated: true,
        component: RecruiterProfile,
      },
      {
        path: routesConstants.REPOST_JOB,
        key: routesConstants.REPOST_JOB,
        component: RepostJob,
      },
      {
        path: routesConstants.SURVEYS,
        key: routesConstants.SURVEYS,
        component: SurveysPage,
      },
      {
        path: routesConstants.EDIT_JOB,
        key: routesConstants.EDIT_JOB,
        component: EditJob,
      },
      {
        path: routesConstants.NEW_JOB_CREATED,
        key: routesConstants.NEW_JOB_CREATED,
        component: SuccessScreen,
      },
      {
        path: routesConstants.NEW_JOB,
        key: routesConstants.NEW_JOB,
        component: NewJob,
      },
      {
        path: routesConstants.CREDIT_CARD_REDIRECT,
        key: routesConstants.CREDIT_CARD_REDIRECT,
        component: CreditCardRedirect,
      },
      {
        path: routesConstants.SUCCESS_SCREEN,
        key: routesConstants.SUCCESS_SCREEN,
        component: GigPosted,
      },
      {
        path: routesConstants.JOBS,
        key: routesConstants.JOBS,
        component: Jobs,
      },
      {
        path: routesConstants.HOME,
        key: routesConstants.HOME,
        component: Dashboard,
      },
    ],
  },
  {
    path: routesConstants.NOT_FOUND,
    key: routesConstants.NOT_FOUND,
    component: NotFound,
  },
];

// const localizeRoute = (route) => ({
//   ...route,
//   path: `/:locale${route.path}`,
// });

// let localizedRoutes = routes.map((route: any) => {
//   if (route.children) {
//     return {
//       ...route,
//       path: `/:locale${route.path}`,
//       children: route.children.map((routeData) => localizeRoute(routeData)),
//     };
//   } else {
//     return localizeRoute(route);
//   }
// });

export default routes;
