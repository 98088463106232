import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
  // whitelist: ["User", "Shared",""]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = () => {
  const middlewares = [ReduxThunk];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }
  let store = createStore(persistedReducer, applyMiddleware(...middlewares));
  let persistor = persistStore(store);
  return { store, persistor };
};

export default store;
