import { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSettings } from "../../actions/shared";
import { settingsApi } from "../../apis/settings";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const useSettings = () => {
  const fetchRef = useRef() as any;
  fetchRef.current = () => fetchIfNeeded();
  // @ts-ignore
  let settings = useSelector(({ Settings }) => Settings);
  let dispatch = useDispatch();

  useEffect(() => {
    fetchRef.current();
  }, []);

  const fetchSettings = async (success?) => {
    let data = await settingsApi.list();
    dispatch(setSettings(data.settings));
    success && success();
  };

  const fetchIfNeeded = useCallback(() => {
    try {
      if (typeof settings === "object" && !isEmpty(settings)) {
        return;
      } else if (typeof settings === "string" && settings) {
        return;
      } else {
        fetchSettings();
      }
    } catch (e) {}
  }, [settings, dispatch]);

  const getSettingOption = useCallback(
    (path) => {
      // console.log(settings, "settingssettingssettings");
      let value = get(settings, `${path}`, {});
      if (isEmpty(value)) {
        fetchSettings(() => {
          value = get(settings, `${path}`, {});
        });
      }
      return value;
    },
    [settings]
  );

  return {
    getSettingOption,
    settings,
  };
};

export default useSettings;
