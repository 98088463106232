import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  user: null,
  company: null,
  token: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.value,
      };
    case ActionTypes.SET_COMPANY_PROFILE:
      return {
        ...state,
        company: action.value,
      };
    default:
      return state;
  }
};

export default UserReducer;
