import React, { useRef } from "react";
import CompleteProfileWrapper from "./CompleteProfileWrapper";
import CompleteCompany from "./CompleteCompany";
import VerifyMobileNumber from "./VerifyMobileNumber";
import useUser from "lib/hooks/useUser";
import VerifyEmailAddress from "./VerifyEmailAddress";

const CompleteWrapper = (props) => {
  return (
    <div className="py-10 bg-white">
      <div className="container px-6">{props?.children}</div>
    </div>
  );
};

const CompleteYourProfile = () => {
  const rootRef = useRef() as any;

  const { user, company } = useUser();

  const handleDone = () => {
    // console.log("handleDone");
  };

  const onNextStep = () => {
    // console.log("onNextStep");
  };

  if (user?.isMobileVerified && user?.isEmailVerified && user?.companyId)
    return null;
  return (
    <div ref={rootRef}>
      <CompleteProfileWrapper
        activeStep={0}
        onNextStep={onNextStep}
        handleDone={handleDone}
      >
        {!user?.isMobileVerified ? (
          <CompleteWrapper>
            <VerifyMobileNumber />
          </CompleteWrapper>
        ) : null}
        {!user?.isEmailVerified ? (
          <CompleteWrapper>
            <VerifyEmailAddress />{" "}
          </CompleteWrapper>
        ) : null}
      </CompleteProfileWrapper>
    </div>
  );
};

export default CompleteYourProfile;
