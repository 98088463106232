import React, { useEffect, useMemo, useRef, useState } from "react";
import { routesConstants } from "lib/constants/urls";
import { getLinkSearchParams } from "lib/helpers/general";
import useLocales from "lib/hooks/useLocales";
import useWalletCards from "lib/hooks/useWalletCards";
import { MdDone } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { SimpleLoader } from "../Loader";

const Circle = styled.div.attrs({})`
  // background: ${({ theme: { linears } }) => linears.bubble};
  border-radius: 100px;
  border: 1px solid #f89c1c;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompelteCreditCardFlow = () => {
  const { createCreditCard: createCreditCardAction } = useWalletCards();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(Boolean);

  let timeOutRef = useRef() as any;

  const searchParams = useMemo(
    () => getLinkSearchParams(location?.search),
    [location?.search]
  );

  const closeFlow = () => {
    timeOutRef.current = setTimeout(() => {
      navigate(window.location.pathname, { replace: true });
    }, 5000);
  };

  const createCreditCard = async () => {
    setLoading(true);
    try {
      await createCreditCardAction({
        checkoutId: searchParams?.id,
      });
      closeFlow();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams && searchParams?.id) {
      createCreditCard();
    }

    return () => {
      if (timeOutRef && timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [searchParams]);

  const locales = useLocales("creditCardRedirect");

  return (
    <div className="py-10 bg-white">
      <div className="container px-6">
        <div className="flex items-center justify-center">
          <Circle>
            {loading ? <SimpleLoader /> : <MdDone size={60} color="#F89C1C" />}
          </Circle>
          <p className="font-avenir-medium text-lg text-center tracking-wide sm:text-xl md:text-2xl ml-2">
            {loading ? locales.loading : locales.success}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompelteCreditCardFlow;
