import React from "react";

import styled from "styled-components";

import { MdClose } from "react-icons/md";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
`;

const ModalContent = styled.div`
  position: absolute;
  background: white;
  width: ${(props) => props.width};
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  max-height: 85vh;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    width: 85%;
    overflow: auto;
  }
`;

interface ModalProps {
  open: boolean;
  close: () => any;
  width?;
  children;
}

const Modal = (props: ModalProps) => {
  return (
    <ModalContainer
      className={props.open ? `block` : `hidden`}
      onClick={props.close}
    >
      <ModalContent
        onClick={(e) => e.stopPropagation()}
        width={props.width || "80%"}
      >
        <div className="relative">
          <MdClose
            size={30}
            style={{ top: 15, right: 15 }}
            onClick={props.close}
            className="cursor-pointer absolute"
          />
        </div>
        {props.children}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
