import { dateDropdownValues, DEFAULT_DAET_FORMAT } from "lib/constants/general";
import moment from "moment";

export const getMinutesSince = (date) => {
  const now = moment();
  const since = moment(date);
  return now.diff(since, "minutes");
};

export const getAge = (date) => {
  const now = moment();
  const since = moment(date);
  return now.diff(since, "years");
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const getDuration = (startDate, endDate) => {
  const start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");

  if (moment(start).format("YYYY-MM-DD") === moment(end).format("YYYY-MM-DD")) {
    return 1;
  } else {
    /**
     * reasoan for +1 is that moment.dif is return count between two dates,
     * lets say end date is xxxx/09/10 and the start date is xxxx/09/9,
     * the differance is 1 without counting the first data as a day
     */
    return end.diff(start, "days") + 1;
  }
};

export const getHoursDuration = (endTime, time) => {
  const endTimeMoment = moment.isMoment(endTime) ? endTime : moment(endTime);
  const startTimeMoment = moment.isMoment(time) ? time : moment(time);

  let duration = moment.duration(endTimeMoment.diff(startTimeMoment));
  return duration.hours();
};

export const getTimeDuration = (endTime, time) => {
  const endTimeMoment = moment.isMoment(endTime) ? endTime : moment(endTime);
  const startTimeMoment = moment.isMoment(time) ? time : moment(time);

  let duration = moment.duration(endTimeMoment.diff(startTimeMoment));
  return duration;
};

export const enumerateDaysBetweenDates = function (startDate, endDate) {
  var dates = [] as any;

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(endDate).startOf("day");

  dates.push(currDate.clone().toDate());
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const getDateFilterValue = (value, key?) => {
  switch (value) {
    case dateDropdownValues.TODAY:
      return {
        key: key || "createdAt",
        start: moment().lang("en").format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.YESTERDAY:
      return {
        key: key || "createdAt",
        start: moment()
          .subtract(1, "days")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.THIS_WEEK:
      return {
        key: key || "createdAt",
        end: moment().lang("en").format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(6, "days")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.THIS_MONTH:
      return {
        key: key || "createdAt",
        end: moment().lang("en").format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(1, "month")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.THIS_YEAR:
      return {
        key: key || "createdAt",
        end: moment().lang("en").format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(1, "year")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.LAST_WEEK:
      return {
        key: key || "createdAt",
        end: moment()
          .subtract(1, "week")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(2, "week")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.LAST_MONTH:
      return {
        key: key || "createdAt",
        end: moment()
          .subtract(1, "month")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(2, "month")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    case dateDropdownValues.LAST_YEAR:
      return {
        key: key || "createdAt",
        end: moment()
          .subtract(1, "year")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
        start: moment()
          .subtract(2, "year")
          .lang("en")
          .format(DEFAULT_DAET_FORMAT),
      };
    default:
      return null;
  }
};

export const convertDecimalToHours = (time) => {
  var n = new Date(0, 0);
  n.setSeconds(time * 60 * 60);
  return n.toTimeString().slice(0, 8);
};
