export enum CHAT_BOT_ACTION_TYPES {
    UPDATE_MESSAGES = "UPDATE_MESSAGES"
}


const addMessage = (message) => ({ chatbotState, dispatchChatbotState }: { chatbotState?, dispatchChatbotState }) => {
    console.log(chatbotState, "statestate")
    const newMEssages = JSON.parse(JSON.stringify(chatbotState?.messages))
    newMEssages.push(message);
    dispatchChatbotState({
        type: CHAT_BOT_ACTION_TYPES.UPDATE_MESSAGES,
        payload: newMEssages
    })
}

export default { addMessage }