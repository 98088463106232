import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";
import routes from "./routes";
import _ from "lodash";
import withImports from "./lib/hocs/withImports";
import withContext from "./lib/hocs/withContext";
import useDirection from "./lib/hooks/useDirection";
import { ConfigProvider } from "antd";
import arabicLocale from "antd/es/locale/ar_EG";
import englishLocale from "antd/es/locale/en_US";
import { firebaseService } from "lib/services/firebase";
import FlashMessage from "shared/components/FlashMessage";
import SuspenseWrapper from "shared/components/SuspenseWrapper";
import DownloadApp from "shared/components/DownloadApp";
import { mobileAllowed } from "lib/constants/urls";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { isProduction } from "lib/helpers/general";
import ChatBotIcon from "shared/components/ChatBotIcon";

const NotFound = () => (
  <div>
    <span>Not found</span>
  </div>
);

const Pages = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {routes.map((route) =>
        _.map(route.children, (child: any) => {
          return (
            <Route
              key={child.path}
              path={child.path}
              element={
                <route.component>
                  <child.component />
                </route.component>
              }
            />
          );
        })
      )}
    </Routes>
  );
};

function App() {
  const { dir } = useDirection();
  const location = useLocation() as any;
  const [analyticsObject, setAnalyticsObject] = React.useState() as any;

  React.useEffect(() => {
    if (isProduction()) {
      Sentry.init({
        dsn: "https://1eeb6c97a3fc4d1581e8b684af41ff84@sentry.kaderapp.com/8",
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.25,
      });
    }

    firebaseService.init();
    const analytics = firebaseService.initAnalytics() as any;
    setAnalyticsObject(analytics);
  }, []);

  React.useEffect(() => {
    if (analyticsObject) {
      analyticsObject?.logEvent("page_view", { name: location.pathname });
    }
  }, [location, analyticsObject]);

  return (
    <div dir={dir} className="w-full h-full relative">
      {window?.innerWidth > 750 ||
      mobileAllowed.includes(location?.pathname) ? (
        <>
          <FlashMessage />
          <ConfigProvider
            direction={dir}
            locale={dir === "rtl" ? arabicLocale : englishLocale}
          >
            <ChatBotIcon />
            <SuspenseWrapper>
              <Pages />
            </SuspenseWrapper>
          </ConfigProvider>
        </>
      ) : (
        <DownloadApp />
      )}
    </div>
  );
}

export default withContext(withImports(App));
