import request from ".";
import { clean } from "../helpers/object";

export const gigsApi = {
  estimatePrice: (data: { startDate?; endDate?; gigAttendances? }) =>
    request.post(`/companies/gigs/estimate-price`, data),
  list: (params?) => request.post(`/companies/gigs/list`, params),
  create: ({
    description,
    extra,
    addressId,
    startDate,
    endDate,
    numberOfUsers,
    gigAttendances,
    paymentType,
    walletCardId,
    displayTitle,
    survey,
    attachSurveyId,
  }) => {
    const payload = clean({
      description,
      addressId,
      startDate,
      endDate,
      numberOfUsers,
      gigAttendances,
      paymentType,
      walletCardId,
      displayTitle,
      survey,
      extra,
      attachSurveyId,
    });
    return request.post(`/companies/gigs`, payload);
  },
  update: ({ gigId, values }) =>
    request.put(`/companies/gigs/${gigId}`, values),
  delete: ({ gigId }) => request.delete(`/companies/gigs/${gigId}`),
  get: ({ id }) => request.get(`/companies/gigs/${id}`),
  statistics: (filter?) => request.post(`/companies/gigs/statistics`, filter),
  topPerformingKaderos: (filter?) =>
    request.post(`/companies/gigs/statistics/top-users`, filter),
  attendances: {
    listAll: (filter?) => request.post(`/companies/gigs/attendances`, filter),
    list: ({ gigId, params }: { gigId: number; params?: object }) =>
      request.get(`/companies/gigs/${gigId}/attendances`, { params }),
    getUser: ({
      gigId,
      attendanceId,
      params,
    }: {
      gigId: number;
      attendanceId: number;
      params?: object;
    }) =>
      request.get(
        `/companies/gigs/${gigId}/attendances/${attendanceId}/users`,
        { params }
      ),
    getUsersHistory: ({
      gigId,
      attendanceId,
      params,
    }: {
      gigId: number;
      attendanceId: number;
      params?: object;
    }) =>
      request.get(
        `/companies/gigs/${gigId}/attendances/${attendanceId}/users/history`,
        { params }
      ),
    delete: ({ gigId, attendanceId }) =>
      request.delete(`/companies/gigs/${gigId}/attendances/${attendanceId}`),
    update: ({ gigId, attendanceId, values }) =>
      request.put(
        `/companies/gigs/${gigId}/attendances/${attendanceId}`,
        values
      ),
    updateRating: ({
      gigId,
      attendanceId,
      values,
    }: {
      gigId: number;
      attendanceId: number;
      values?;
    }) =>
      request.put(
        `/companies/gigs/${gigId}/attendances/${attendanceId}/rating`,
        values
      ),

    download: (filters) =>
      request.post(`/companies/gigs/attendances/download-csv`, filters),
    statistics: {
      status: (filter?) =>
        request.post(`/companies/gigs/attendances/statistics/status`, filter),
      subCategories: (filter?) =>
        request.post(
          `/companies/gigs/attendances/statistics/sub-categories`,
          filter
        ),
    },
  },
  payment: ({ gigId }) => request.get(`/companies/gigs/${gigId}/payment`),
  surveys: {
    list: ({ gigId }) => request.get(`/companies/gigs/${gigId}/surveys`),
  },
};

// "rating": 4.5,
// "ratingReason": 4,
// "ratingReasonText": "test"
