import React, { createContext, useReducer } from "react";
import SurveyReducer, { surveyInitialState } from "./SurveyReducer";

// Survey state which its provide context for children
const SurveyState = ({ children }) => {
  const [surveyState, dispatchSurveyState] = useReducer(
    SurveyReducer,
    surveyInitialState
  );

  return (
    <SurveyContext.Provider value={{ surveyState, dispatchSurveyState }}>
      {children}
    </SurveyContext.Provider>
  );
};

// Create Survey Context
export const SurveyContext = createContext(surveyInitialState);

// Export Survey State Context Component
export default SurveyState;
