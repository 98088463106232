import * as Yup from "yup";
import { ifArabic } from "lib/helpers/i18n";
import { SURVEY_QUESTION_TYPES } from "lib/constants/general";

export const SiurveyBuilderSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(
      100,
      ifArabic(
        "يجب ألا يزيد العنوان عن 100 حرفًا",
        "Your survey title should not be more than 100 characters"
      )
    )
    .min(
      2,
      ifArabic(
        "يجب أن يكون العنوان على الأقل حرفين",
        "Your survey title should atleast be 2 characters"
      )
    )
    .required(
      ifArabic("الرجاء اختيار عنوان", "Please choose a title for your survey")
    ),
  description: Yup.string().trim().nullable(),
  surveyQuestions: Yup.array()
    .min(
      1,
      ifArabic(
        "الرجاء إدخال سؤال واحد على الأقل",
        "Please insert at least one question"
      )
    )
    .of(
      Yup.object({
        title: Yup.string()
          .trim()
          .max(
            100,
            ifArabic(
              "يجب ألا يزيد العنوان عن 100 حرفًا",
              "Your survey title should not be more than 100 characters"
            )
          )
          .min(
            2,
            ifArabic(
              "يجب أن يكون العنوان على الأقل حرفين",
              "Your survey title should atleast be 2 characters"
            )
          )
          .required(
            ifArabic(
              "الرجاء اختيار عنوان",
              "Please choose a title for your survey"
            )
          ),
        description: Yup.string().trim().nullable(),
        type: Yup.string()
          .typeError(ifArabic("هذا الحقل إجباري", "This field is required"))
          .required(ifArabic("هذا الحقل إجباري", "This field is required")),
        surveyQuestionOptions: Yup.mixed().when("type", {
          is: (type) => {
            return (
              type === `${SURVEY_QUESTION_TYPES.CHECKBOXES}` ||
              type === `${SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE}`
            );
          },
          then:
            Yup.array()
              .of(Yup.object({
                title: Yup.string()
                  .trim()
                  .max(
                    100,
                    ifArabic(
                      "يجب ألا يزيد العنوان عن 100 حرفًا",
                      "Your survey question option should not be more than 100 characters"
                    )
                  )
                  .min(
                    1,
                    ifArabic(
                      "يجب أن يكون العنوان على الأقل حرف واحد",
                      "Your survey question option should atleast be 1 characters"
                    )
                  )
                  .required(
                    ifArabic(
                      "هذا الحقل إجباري",
                      "This field is required"
                    )
                  ),
              }))
              .min(
                2,
                ifArabic(
                  "الرجاء إدخال خيارين على الأقل",
                  "Please insert at least two options"
                )
              )
              .typeError(
                ifArabic(
                  "الرجاء إدخال خيارين على الأقل",
                  "Please insert at least two options"
                )
              )
              .required(ifArabic("هذا الحقل إجباري", "This field is required")),
        }),

      })
    )
    .required(ifArabic("هذا الحقل إجباري", "This field is required")),
});

export const SiurveyBuilderQuestionSchema = Yup.object({
  title: Yup.string()
    .max(
      100,
      ifArabic(
        "يجب ألا يزيد العنوان عن 100 حرفًا",
        "Your survey question title should not be more than 100 characters"
      )
    )
    .min(
      2,
      ifArabic(
        "يجب أن يكون العنوان على الأقل حرفين",
        "Your survey question title should atleast be 2 characters"
      )
    )
    .required(
      ifArabic("الرجاء اختيار عنوان", "Please choose a title for your survey")
    ),
  description: Yup.string().nullable(),
  type: Yup.string()
    .typeError(ifArabic("هذا الحقل إجباري", "This field is required"))
    .required(ifArabic("هذا الحقل إجباري", "This field is required")),
  surveyQuestionOptions: Yup.mixed().when("type", {
    is: (type) => {
      return (
        type === `${SURVEY_QUESTION_TYPES.CHECKBOXES}` ||
        type === `${SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE}`
      );
    },
    then:
      Yup.array()
        .of(Yup.object({
          title: Yup.string()
            .trim()
            .max(
              100,
              ifArabic(
                "يجب ألا يزيد العنوان عن 100 حرفًا",
                "Your survey question option should not be more than 100 characters"
              )
            )
            .min(
              1,
              ifArabic(
                "يجب أن يكون العنوان على الأقل حرف واحد",
                "Your survey question option should atleast be 1 characters"
              )
            )
            .required(
              ifArabic(
                "هذا الحقل إجباري",
                "This field is required"
              )
            ),
        }))
        .min(
          2,
          ifArabic(
            "الرجاء إدخال خيارين على الأقل",
            "Please insert at least two options"
          )
        )
        .typeError(
          ifArabic(
            "الرجاء إدخال خيارين على الأقل",
            "Please insert at least two options"
          )
        )
        .required(ifArabic("هذا الحقل إجباري", "This field is required")),
  }),
});

// const dd = {
//   email: Yup.lazy((val) =>
//     Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()
//   ),
// };
