import ar from "./ar";
import arEg from "./ar-EG";
import en from "./en";

const locales = {
  ar,
  en,
  "ar-EG": arEg
};

export default locales;
