import Axios, { AxiosResponse, AxiosError, AxiosStatic } from "axios";
import { StorageKeys } from "lib/constants/storageKeys";
import { routesConstants, whiteListedRoutes } from "lib/constants/urls";
import { isProduction } from "lib/helpers/general";

export const getChatBotServiceBaseUrl = () => {
    return isProduction()
        ? "https://chat-api.kaderapp.com"
        : "https://chat-stg.kaderapp.com";
};


const onSuccess = function (response: AxiosResponse) {
    console.log("Request Success:", response.config);

    return response.data;
};

const onError = function (error: AxiosError) {
    console.log("Request Failed:", error.config);
    if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log("Status:", error.response.status);
        console.log("Data:", error.response.data);
        console.log("Headers:", error.response.headers);

        switch (error.response.status) {
            case 401:
                localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
                if (!whiteListedRoutes.includes(window.location.pathname)) {
                    setTimeout(() => {
                        window.location.replace(routesConstants.GUEST_LOGIN);
                    }, 500);
                }
                break;
            default:
                break;
        }
        return Promise.reject(error.response.data);
    } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log("Error Message:", error.message);
    }

    return Promise.reject(error);
};

const chatbotInstance = Axios.create({
    baseURL: `${getChatBotServiceBaseUrl()}/api/v1/client/chatbot`,
})

chatbotInstance.interceptors.response.use(onSuccess, onError);
// chatbotInstance.defaults.headers.common["Accept-Language"] = getLang();

chatbotInstance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
    StorageKeys.ACCESS_TOKEN
)}`;

chatbotInstance["setSession"] = function (token) {
    this.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

chatbotInstance["removeSession"] = function (token) {
    delete this.defaults.headers.common["Authorization"];
};

const request = chatbotInstance as AxiosStatic & { setSession: any; removeSession: any };

export default request;
