import { surveysApis } from "lib/apis/survey";
import {
  DOWNLOADABLE_FILE_TYPES,
  SURVEY_QUESTION_TYPES,
} from "lib/constants/general";
import {
  moveArrayItemToNewIndex,
  removeItemAtIndex,
  sortBy,
} from "lib/helpers/array";
import { questionInitialValue } from "./SurveyReducer";
import map from "lodash/map";
import filter from "lodash/filter";
import moment from "moment";
import groupBy from "lodash/groupBy";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import { SiurveyBuilderQuestionSchema } from "lib/validation/surveyBuilderSchema";
import { cleanObject } from "lib/helpers/object";
import { message } from "antd";
import { ifArabic } from "lib/helpers/i18n";

export enum SURVEY_ACTIONS {
  SURVEY_DATA_CHANGED = "SURVEY_DATA_CHANGED",
  SURVEY_QUESTIONS_CHANGED = "SURVEY_QUESTIONS_CHANGED",
  SURVEY_DATA_QUESTIONS_ANSWERS = "SURVEY_DATA_QUESTIONS_ANSWERS",
  SURVEY_QUESTION_DATA_CHANGED = "SURVEY_QUESTION_DATA_CHANGED",
  RESET = "RESET",
}

export const createSurvey =
  (values, success, error) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const data = await surveysApis.create(values);
        success && success(data);
      } catch (createSurveyError) {
        error && error(createSurveyError);
      }
    };

export const fetchSurvey = async ({ surveyId }, success) => {
  try {
    const { survey } = await surveysApis.show({ surveyId });
    success && success(survey);
  } catch (errorFetchingSurvey: any) {
    console.log("errorFetchingSurvey", errorFetchingSurvey);
  }
};

export const handleChangeSurveyData =
  (surveyData, success?) =>
    ({ surveyState, dispatchSurveyState }) => {
      let copiedSurvey = JSON.parse(JSON.stringify(surveyState.survey)) as any;

      copiedSurvey = surveyData;
      if (surveyData) {
        success && success(copiedSurvey);
        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
          payload: copiedSurvey,
        });
      }
    };

export const onCopySurveyQuestion =
  ({ index }, success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const copiedSurvey = JSON.parse(JSON.stringify(surveyState.survey));

        const newQuestions = await JSON.parse(
          JSON.stringify(copiedSurvey.surveyQuestions)
        );
        await newQuestions.push(newQuestions[index]);
        copiedSurvey.surveyQuestions = await newQuestions;
        success && success(newQuestions);
        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
          payload: copiedSurvey,
        });
      } catch (onCopySurveyQuestionError) { }
    };

export const addQuestion =
  (values, success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        createSurvey(
          values,
          (survey) => {
            success && success(survey);
          },
          () => { }
        )({ surveyState, dispatchSurveyState });
      } catch (error: any) {
        console.log(error);
      }
    };

export const addAnotherQuestion =
  (success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const copiedSurvey = JSON.parse(JSON.stringify(surveyState.survey));
        const newQuestions = await JSON.parse(
          JSON.stringify(copiedSurvey.surveyQuestions)
        );
        await newQuestions.push({
          ...questionInitialValue,
          sort: copiedSurvey.surveyQuestions?.length + 1,
        });
        copiedSurvey.surveyQuestions = await newQuestions;
        success && success(newQuestions);
        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
          payload: copiedSurvey,
        });
      } catch (onCopySurveyQuestionError) {
        console.log(onCopySurveyQuestionError, "onCopySurveyQuestionError");
      }
    };

export const onDeleteSurveyQuestion =
  ({ index }, success?) =>
    ({ surveyState, dispatchSurveyState }) => {
      try {
        const copiedSurvey = JSON.parse(JSON.stringify(surveyState.survey));

        const newSurveyQuestions = removeItemAtIndex(
          copiedSurvey.surveyQuestions,
          index
        );

        copiedSurvey.surveyQuestions = newSurveyQuestions;
        success && success(newSurveyQuestions);
        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
          payload: copiedSurvey,
        });
      } catch (error) { }
    };

export const handleChangeQuestionValues =
  ({ index, values }) =>
    async ({ surveyState, dispatchSurveyState }) => {
      const copiedSurvey = JSON.parse(JSON.stringify(surveyState.survey));

      copiedSurvey.surveyQuestions[index] = await {
        ...values,
        title: values?.title?.trim(),
        description: values?.description?.trim(),
      };

      dispatchSurveyState({
        type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
        payload: copiedSurvey,
      });
    };

export const fetchSurveyData =
  ({ surveyId }, success?, error?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const { survey } = await surveysApis.show({ surveyId });
        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_CHANGED,
          payload: survey,
        });
        success && success(survey);
      } catch (fetchSurveyError) {
        error && error(fetchSurveyError);
      }
    };

const getMultipleChoiceAnswersStatistics = (questionData) => {
  const surveyQuestionOptions = orderBy(
    questionData?.surveyQuestionOptions,
    ["id"],
    ["asc"]
  );

  let allOptions = [] as any;
  map(questionData?.surveyQuestionAnswers, (questionAnswer) => {
    map(questionAnswer?.answerOptions, (answerOption) => {
      const isOptionCreate = filter(surveyQuestionOptions, {
        title: answerOption,
      });
      if (!isOptionCreate?.length) {
        allOptions.push(answerOption);
      }
    });
  });
  allOptions = [...(new Set([...surveyQuestionOptions, ...allOptions]) as any)];
  const optionsWithItsAnswers = [] as any;
  let totalAnswers = 0 as any;
  map(allOptions, (option) => {
    const optionAnswers = filter(questionData?.surveyQuestionAnswers, {
      answerOptions: [`${option?.title || option}`],
    });
    totalAnswers += optionAnswers?.length;
    optionsWithItsAnswers.push({
      option: `${option?.id ? option?.title : option}`,
      totalOptionAnswers: optionAnswers?.length,
    });
  });

  return { totalAnswers, optionsWithItsAnswers };
};

const getDateAnswersStatistics = (questionData) => {
  let allDatesAnswers = [] as any;
  map(questionData?.surveyQuestionAnswers, (questionAnswer) => {
    allDatesAnswers.push(questionAnswer?.answerText);
  });

  let allMonths = map(allDatesAnswers, (date) =>
    moment(date, "YYYY-MM-DD").format("YYYY-MM")
  );

  allMonths = [...(new Set(allMonths) as any)];

  const optionsWithItsAnswers = [] as any;
  let totalAnswers = 0 as any;
  map(allMonths, (option) => {
    const optionAnswers = map(
      filter(questionData?.surveyQuestionAnswers, (surveyQuestionAnswer) => {
        return (
          moment(surveyQuestionAnswer?.answerText, "YYYY-MM-DD").month() ===
          moment(option, "YYYY-MM").month()
        );
      }),
      (answer) => moment(answer?.answerText, "YYYY-MM-DD").format("DD")
    );

    const uniqDates = [...(new Set(optionAnswers) as any)];

    const uniqAnswers = [] as any;
    map(uniqDates, (uniqDate) => {
      uniqAnswers.push({
        date: uniqDate,
        answersCount: groupBy(optionAnswers)[uniqDate]?.length,
      });
    });

    totalAnswers += optionAnswers?.length;
    optionsWithItsAnswers.push({
      option: `${option}`,
      totalOptionAnswers: optionAnswers?.length,
      optionAnswers: uniqAnswers,
    });
  });

  return { totalAnswers, optionsWithItsAnswers };
};

const getTimeAnswersStatistics = (questionData) => {
  let allTimesAnswers = [] as any;
  map(questionData?.surveyQuestionAnswers, (questionAnswer) => {
    allTimesAnswers.push(questionAnswer?.answerText);
  });

  let allTimes = map(allTimesAnswers, (time) =>
    moment(time, "hh:mm").format("hh")
  );

  allTimes = [...(new Set(allTimes) as any)];

  const optionsWithItsAnswers = [] as any;
  let totalAnswers = 0 as any;
  map(allTimes, (option) => {
    const optionAnswers = map(
      filter(questionData?.surveyQuestionAnswers, (surveyQuestionAnswer) => {
        return (
          moment(surveyQuestionAnswer?.answerText, "hh:mm").hour() ===
          moment(option, "hh").hour()
        );
      }),
      (answer) => moment(answer?.answerText, "hh:mm a").format("hh:mm a")
    );

    const uniqTimes = [...(new Set(optionAnswers) as any)];

    const uniqAnswers = [] as any;
    map(uniqTimes, (uniqTime) => {
      uniqAnswers.push({
        time: uniqTime,
        answersCount: groupBy(optionAnswers)[uniqTime]?.length,
      });
    });

    totalAnswers += optionAnswers?.length;
    optionsWithItsAnswers.push({
      option: `${option}`,
      totalOptionAnswers: optionAnswers?.length,
      optionAnswers: uniqAnswers,
    });
  });
  return { totalAnswers, optionsWithItsAnswers };
};

const getLocationAnswerData = (questionData) => {
  const answers = [] as any;
  map(questionData?.surveyQuestionAnswers, (questionAnswer) => {
    const [latitude = null, longitude = null, locationLabel = "not filled"] =
      questionAnswer?.answerOptions;
    return answers.push({
      location: { latitude, longitude },
      locationLabel,
    });
  });
  return { answers };
};

const getAnswersStatistics = (questionData) => {
  switch (questionData.type) {
    case SURVEY_QUESTION_TYPES.CHECKBOXES:
      return {
        ...questionData,
        answersStatistics: getMultipleChoiceAnswersStatistics(questionData),
      };
    case SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE:
      return {
        ...questionData,
        answersStatistics: getMultipleChoiceAnswersStatistics(questionData),
      };
    case SURVEY_QUESTION_TYPES.DATE:
      return {
        ...questionData,
        answersStatistics: getDateAnswersStatistics(questionData),
      };
    case SURVEY_QUESTION_TYPES.TIME:
      return {
        ...questionData,
        answersStatistics: getTimeAnswersStatistics(questionData),
      };
    case SURVEY_QUESTION_TYPES.LOCATION:
      return {
        ...questionData,
        answersStatistics: getLocationAnswerData(questionData),
      };

    default:
      return { ...questionData };
  }
};

export const fetchSurveyQuestions =
  ({ surveyId }, success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const { surveyQuestions, count } = await surveysApis.questions.list({
          surveyId,
        });
        success && success(surveyQuestions);
      } catch (fetchSurveyError) { }
    };

export const fetchSurveyQuestionsAnswers =
  ({ surveyId }, success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const { surveyQuestions, count } =
          await surveysApis.questions.listWithAnswers({ surveyId });

        dispatchSurveyState({
          type: SURVEY_ACTIONS.SURVEY_DATA_QUESTIONS_ANSWERS,
          payload: {
            surveyQuestions: map(surveyQuestions, (question) =>
              getAnswersStatistics(question)
            ),
            count,
          },
        });
      } catch (fetchSurveyError) { }
    };

export const fetchSurveyUserAnswers =
  ({ surveyId, filters }, success?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const data = await surveysApis.questions.listWithUserAnswers({
          surveyId,
          filters,
        });
        success && success(data);
      } catch (fetchSurveyError) { }
    };

export const downloadSurveyCsv =
  ({ fileType }, success?, error?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        var hiddenElement = document.createElement("a");

        if (fileType === DOWNLOADABLE_FILE_TYPES.EXCEL) {
          const fileData = await surveysApis.downloadExcel({
            surveyId: surveyState?.survey?.id,
          });
          try {
            const blob = await new Blob([fileData], {
              type: "application/csv",
            });
            const url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob);
            hiddenElement.href = link;
            // hiddenElement.target = "_blank";
            hiddenElement.download = `${moment().format(
              "DD/MM/YYYY"
            )}-${(surveyState?.survey?.title).replace(" ", "_")}.${fileType}`;
            hiddenElement.click();
          } catch (fileError) { }
        } else {
          const fileData = await surveysApis.downloadCsv({
            surveyId: surveyState?.survey?.id,
          });

          hiddenElement.href = `data:text/${fileType};charset=${fileType === DOWNLOADABLE_FILE_TYPES.CSV ? "utf-8-sig" : "utf-8"
            },${encodeURI(fileData)}`;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${moment().format(
            "DD/MM/YYYY"
          )}-${(surveyState?.survey?.title).replace(" ", "_")}.${fileType}`;
          hiddenElement.click();
        }
        success && success();
      } catch (fetchSurveyError) {
        error && error();
      }
    };

export const resetSurveyState =
  () =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        dispatchSurveyState({
          type: SURVEY_ACTIONS.RESET,
        });
      } catch (fetchSurveyError) { }
    };

export const shapeSurveyObject = (survey) => {
  try {
    const shapedSurveyObject = {
      id: survey?.id,
      title: survey?.title,
      description: survey?.description,
      surveyQuestions: map(
        sortBy(
          survey?.surveyQuestions?.length
            ? survey?.surveyQuestions
            : [questionInitialValue],
          "sort"
        ),
        (question) => ({
          id: question?.id,
          title: question?.title,
          description: question?.description,
          type: `${question?.type}`,
          surveyQuestionOptions: question?.surveyQuestionOptions,
          isRequired: question?.isRequired,
          allowOther: question?.allowOther,
          sort: question?.sort,
        })
      ),
    } as any;
    return shapedSurveyObject;
  } catch (shapeSurveyError) {
    console.log(shapeSurveyError);
  }
};

export const updateQuestionData =
  ({ questionId, key }, success, error?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      console.log(surveyState, questionId, "surveyStatesurveyState")
      try {
        if (questionId) {
          const surveyQuestion = JSON.parse(
            JSON.stringify(
              find(surveyState?.survey?.surveyQuestions, { id: questionId })
            )
          );
          SiurveyBuilderQuestionSchema.validate(cleanObject(surveyQuestion))
            .then(async () => {
              if (surveyQuestion?.id) {
                await delete surveyQuestion?.surveyQuestionOptions;
              }
              await delete surveyQuestion?.id;
              await surveysApis.questions.update({
                surveyId: surveyState?.survey?.id,
                questionId,
                values: cleanObject({
                  ...surveyQuestion,
                  title: surveyQuestion?.title?.trim(),
                  description: surveyQuestion?.title?.trim(),
                }),
              });
              message.success(
                ifArabic("تم تحديث السؤال بنجاح", "Question updated successfully")
              );
              success && success({});
            })
            .catch((validationError) => {
              error && error(validationError);
            });
        } else {
          const surveyQuestions = JSON.parse(
            JSON.stringify(surveyState?.survey?.surveyQuestions)
          );
          SiurveyBuilderQuestionSchema.validate(cleanObject(surveyQuestions[key]))
            .then(async () => {
              const { surveyQuestion } = await surveysApis.questions.create({
                surveyId: surveyState?.survey?.id,
                values: cleanObject({
                  ...surveyQuestions[key],
                  surveyQuestionOptions: map(
                    surveyQuestions[key]?.surveyQuestionOptions,
                    (questionOption) =>
                      cleanObject({ ...questionOption, optionKey: null })
                  ),
                }),
              });
              const shapedObject = {
                id: surveyQuestion?.id,
                title: surveyQuestion?.title,
                description: surveyQuestion?.description,
                type: `${surveyQuestion?.type}`,
                surveyQuestionOptions: map(
                  surveyQuestion?.surveyQuestionOptions,
                  (question) => cleanObject({ ...question, optionKey: null })
                ),
                isRequired: surveyQuestion?.isRequired,
                allowOther: surveyQuestion?.allowOther,
              };
              message.success(
                ifArabic("تم إنشاء السؤال بنجاح", "Question created successfully")
              );
              handleChangeQuestionValues({
                index: key,
                values: shapedObject,
              })({ surveyState, dispatchSurveyState });

              success && success({ shapedObject, key });

            })
            .catch((validationError) => {
              error && error(validationError);
            });
        }
      } catch (updateQuestionError) {
        console.log(
          updateQuestionError,
          "updateQuestionErrorupdateQuestionError"
        );
      }
    };

export const updateSurvey =
  ({ surveyId, values }, success?, error?) =>
    async ({ surveyState, dispatchSurveyState }) => {
      try {
        const data = await surveysApis.update({ surveyId, values });
        success && success();
      } catch (createSurveyError) {
        error && error(createSurveyError);
      }
    };

export const reOrderQuestions =
  ({ questions, sourceIndex, destinationIndex }, success) =>
    async ({ surveyState, dispatchSurveyState }) => {
      let copiedValues = JSON.parse(JSON.stringify(questions));

      const sortedQuestions = moveArrayItemToNewIndex(
        copiedValues,
        sourceIndex,
        destinationIndex
      );

      copiedValues = sortedQuestions.map((question, index) => ({
        ...question,
        sort: index + 1,
      }));

      dispatchSurveyState({
        type: SURVEY_ACTIONS.SURVEY_QUESTIONS_CHANGED,
        payload: copiedValues,
      });

      success && success(copiedValues);

      if (surveyState?.survey?.id) {
        try {
          const shapedSortedData = map(copiedValues, (question) => ({
            id: question?.id,
            sort: question?.sort,
          }));
          const data = await surveysApis.questions.sort({
            surveyId: surveyState?.survey?.id,
            values: { surveyQuestionSorts: shapedSortedData },
          });
        } catch (createSurveyError: any) {
          // error && error(createSurveyError);
        }
      }
    };

export const addOptionToQuestion = async (
  { surveyId, surveyQuestionId, values },
  success?,
  error?
) => {
  try {
    const { surveyQuestionOption } = await surveysApis.questions.options.create(
      { surveyId, surveyQuestionId, values }
    );
    success && success(surveyQuestionOption);
  } catch (createSurveyError) {
    error && error(createSurveyError);
  }
};

export const updateQuestionOption = async (
  { surveyId, surveyQuestionId, surveyQuestionOptionId, values },
  success?,
  error?
) => {
  try {
    const data = await surveysApis.questions.options.update({
      surveyId,
      surveyQuestionId,
      surveyQuestionOptionId,
      values,
    });
    success && success();
  } catch (createSurveyError) {
    error && error(createSurveyError);
  }
};

export const deleteOptionFromQuestion = async (
  { surveyId, surveyQuestionId, surveyQuestionOptionId },
  success,
  error
) => {
  try {
    await surveysApis.questions.options.delete({
      surveyId,
      surveyQuestionId,
      surveyQuestionOptionId,
    });
    success && success();
  } catch (createSurveyError) {
    error && error(createSurveyError);
  }
};
