import ChatBotRequest from "../services/chatbot";

export const chatBotApis = {
  rooms: {
    listActive: () =>
      ChatBotRequest.get("/rooms/active"),
    create: () =>
      ChatBotRequest.post("/rooms"),
    getById: ({ roomId }) => ChatBotRequest.get("/rooms", { params: { roomId } }),
    getMessages: ({ roomId, filter }: { roomId; filter?}) => ChatBotRequest.get(`/rooms/${roomId}/messages`, { params: { ...filter } }),
    end: ({ roomId }) => ChatBotRequest.put(`/rooms/${roomId}`, { roomId })
  },
};
