import { countriesCodes, siteCountries } from "lib/constants/general";
import { getCountry } from "lib/services/LocalStorage";
import map from "lodash/map";
import { isObject } from "lodash";
import { convertDecimalToHours } from "./date";
import moment from "moment";

/**
 * Function used to calculate distance between two points
 */

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const encodeText = (text) => encodeURI(text);

export const convertObjectToFormData = (object) => {
  const formData = new FormData();
  map(object, (value, key) => {
    if (key === "file") {
      formData.append(`${key}`, value);
    } else {
      if (isObject(value)) {
        map(value, (nestedValue, keyValue) => {
          formData.append(`${key}[${keyValue}]`, nestedValue);
        });
      } else {
        formData.set(`${key}`, value);
      }
    }
  });
  return formData;
};

export const getLinkSearchParams = (params) => {
  const searchParamsString = params.slice(1).split("&");
  const searchObject = {} as any;
  map(searchParamsString, (value) => {
    const filterItem = value.split("=");
    searchObject[filterItem[0]] = filterItem[1];
  });
  return searchObject;
};

export const isProduction = () =>
  window.location.hostname === "recruiter.kaderapp.com";

export const getElementHeight = (elementId) => {
  const element = document.getElementById(elementId) as any;

  return `${element.scrollHeight}px`;
};

export default function getColor(
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  isDisabled
) {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isWithinHoverRange) {
      return rangeHoverColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
}

export const getDefaultMapLocation = () => {
  const savedCountry = getCountry();
  const countryObject = siteCountries.find(
    (country) => country.code === (savedCountry || countriesCodes.JOR)
  ) as any;
  return countryObject.location;
};

export const shapeHours = ({ hours }) => {
  if (hours >= 0) {
    let dicimal = 0 as any;
    if (`${hours}`.split(".")?.length > 1) {
      dicimal = `${hours}`.split(".")[1];
      dicimal = convertDecimalToHours(Number(`0.${dicimal}`));
      hours = `${hours}`.split(".")[0];
    }

    return {
      hours: hours || 0,
      dicimal: dicimal ? moment(dicimal, "hh:mm:ss").format("mm") : 0,
    };
  }
};
