import React from "react";
import "./InputErrorBlock.scss";
import { capitalizeFirstLetter } from "lib/helpers/strings";

interface InputErrorBlockProps {
  errorMessage: string;
  icon?;
  rootClassName?: string;
  className?: string;
}

const InputErrorBlock = (props: InputErrorBlockProps) => {
  return (
    <div className={`input-error-block ${props.rootClassName}`}>
      {props.icon && props.icon}
      <span
        className={`input-error-block__error-message ${
          props.icon ? "inline-block ml-2" : ""
        } ${props?.className || ""}`}
      >
        {capitalizeFirstLetter(props.errorMessage)}
      </span>
    </div>
  );
};

export default InputErrorBlock;
