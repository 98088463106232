import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  phone: null,
  code: null,
  otp: null,
  accessToken: null,
  login: {
    password: null,
  },
  user: {},
  profile: {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    passwordConfirmation: null,
    image: null,
  },
  company: { name: null, industry: null, branches: null },
};

const GuestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PHONE:
      return {
        ...state,
        phone: action.value,
      };
    case ActionTypes.SET_CODE:
      return {
        ...state,
        code: action.value,
      };
    case ActionTypes.SET_OTP:
      return {
        ...state,
        otp: action.value,
      };
    case ActionTypes.SET_PASSWORD:
      return {
        ...state,
        login: { ...state.login, password: action.value },
      };
    case ActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.value },
      };
    case ActionTypes.SET_GUEST_USER:
      return {
        ...state,
        user: { ...state.user, ...action.value },
      };
    case ActionTypes.SET_GUEST_TOKEN:
      return {
        ...state,
        accessToken: action.value,
      };
    case ActionTypes.SET_COMPANY_FORM:
      return {
        ...state,
        company: { ...state.company, ...action.value },
      };
    default:
      return state;
  }
};

export default GuestReducer;
