import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useRef,
} from "react";
import useUser from "lib/hooks/useUser";
import { useLocation, useNavigate } from "react-router";
import { StorageKeys } from "lib/constants/storageKeys";
import isNull from "lodash/isNull";
import Loader from "shared/components/Loader";
import { routesConstants, whiteListedRoutes } from "lib/constants/urls";

// List of initial state for all auth data
const initialState = {
  user: {},
} as any;

// Actions
export const COMMON_SESSION = "COMMON_SESSION";

// Shared Reducer For auth context
const Reducer = (notificationsState, action) => {
  switch (action.type) {
    case COMMON_SESSION:
      return {
        notification: action.payload.notification,
        newNotification: action.payload.newNotification,
        toast: action.payload.toast,
        flashMessage: action.payload.flashMessage,
        modal: action.payload.modal,
      };
    default:
      return notificationsState;
  }
};

// Auth state which its provide context for children
const AuthState = ({ children }) => {
  const effectRef = useRef() as any;
  const location = useLocation();
  const navigate = useNavigate();

  effectRef.current = () => {
    setLoading(true);
    let access_token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    if (isNull(access_token)) {
      if (!whiteListedRoutes.includes(location.pathname)) {
        window.location.replace(routesConstants.GUEST_LOGIN);
      }
      setLoading(false);
    } else {
      try {
        rehydrateUser();
        if (location.pathname === routesConstants.BASE_PATHNAME)
          navigate(routesConstants.HOME, { replace: true });
      } catch (e) {}
      setLoading(false);
    }
  };

  const [authState, dispatch] = useReducer(Reducer, initialState);

  const [loading, setLoading] = useState(true);
  const { rehydrateUser } = useUser();

  useEffect(() => {
    effectRef.current();
  }, []);

  return (
    <AuthContext.Provider value={[authState, dispatch]}>
      {loading ? <Loader /> : children}
    </AuthContext.Provider>
  );
};

// Create Auth Context
export const AuthContext = createContext(initialState);

// Export Auth State Context Component
export default AuthState;
