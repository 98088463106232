import { createGlobalStyle } from "./styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'avenir', sans-serif !important;
    font-style: normal;
    color:${({ theme: { colors } }) => colors.gray};
  }


  .bold,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-family: "avenirblack";
    font-weight: normal;
    color: #333333;
  }
  

  .container-sm {
  margin: auto !important;
  max-width: $mq-lg !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media all and (min-width: 640px) {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.container-lg {
  margin: auto !important;
  max-width: $mq-xl !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media all and (min-width: $mq-sm) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.container {
  width: 100%;
  margin: auto;
  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1000px !important;
  }
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}


.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background: #f89c1c;
  border-color: #f89c1c;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #f89c1c;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #f89c1c;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #f89c1c;
}

[dir="rtl"] body{
  font-family: "KaderArabicFont";
}

[dir="rtl"] .bold,
[dir="rtl"] h1,
[dir="rtl"] h2,
[dir="rtl"] h3,
[dir="rtl"] h4,
[dir="rtl"] h5,
[dir="rtl"] h6, [dir="rtl"] span, [dir="rtl"] a{
  font-family: "KaderArabicFont";
}


@media all and (min-width: $mq-md) {
  .md {
    &-disable-animations {
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }
  }
}



`;
