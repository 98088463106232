import { SURVEY_QUESTION_TYPES } from "lib/constants/general";
import { SURVEY_ACTIONS } from "./SurveyActions";

export const optionDefaultValue = {
  "title": "Option",
  "isEnd": false,
  "nextQuestionId": null
}

export const questionInitialValue = {
  title: "",
  description: "",
  type: `${SURVEY_QUESTION_TYPES.PARAGRAPH}`,
  surveyQuestionOptions: [],
  isRequired: false,
  allowOther: false,
  sort: 1,
};

// List of initial state for all survey data
export const surveyInitialState = {
  survey: {
    title: "",
    description: "",
  },
} as any;

const SurveyReducer = (surveyState, action) => {
  switch (action.type) {
    case SURVEY_ACTIONS.SURVEY_DATA_CHANGED:
      return {
        ...surveyState,
        survey: action.payload,
      };
    case SURVEY_ACTIONS.SURVEY_QUESTIONS_CHANGED:
      return {
        ...surveyState,
        survey: {
          ...surveyState?.survey,
          surveyQuestions: action.payload,
        },
      };
    case SURVEY_ACTIONS.SURVEY_DATA_QUESTIONS_ANSWERS:
      return {
        ...surveyState,
        surveyQuestionsAnswers: action.payload,
      };
    case SURVEY_ACTIONS.RESET:
      return surveyInitialState;
    default:
      return surveyState;
  }
};

export default SurveyReducer;
