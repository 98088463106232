import { authApis } from "../../apis/auth";
import { useSelector, useDispatch } from "react-redux";
import { StorageKeys } from "../../constants/storageKeys";
import { setUser, setCompany } from "../../actions/user";
import { storeUserSession, removeUserSession } from "../../helpers/session";

import { firebaseService } from "lib/services/firebase";
import { profileApis, ISendOtpData } from "lib/apis/profile";
import { convertObjectToFormData, isProduction } from "lib/helpers/general";
import IUpdateEmail from "types/IUpdateEmail";
import { routesConstants } from "lib/constants/urls";
import { useCallback } from "react";
import { countriesCodes, PaymentTypesRef } from "lib/constants/general";
import { find } from "lodash";
import useSettings from "../useSettings";
import { companyApis } from "lib/apis/companies";
import * as Sentry from "@sentry/react";
import { setCountry } from "lib/services/LocalStorage";
import { useNavigate } from "react-router-dom";

function useUser() {
  const { user, company } = useSelector(({ User }: any) => User);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { settings } = useSettings();

  const setUserInRedux = (user) => {
    dispatch(setUser(user));
  };
  const setCompanyInRedux = (company) => {
    dispatch(setCompany(company));
  };

  const emailLogin = ({ email, password }) => {
    return new Promise(async (resolve, reject) => {
      try {
        let {
          session: { token },
          recruiter: user,
        } = await authApis.emailLogin({
          email,
          password,
        });
        if (isProduction()) {
          Sentry.configureScope(function (scope) {
            scope.setUser({
              id: user?.id,
            });
          });
        }
        const { company } = user;
        const analytics = firebaseService.initAnalytics() as any;
        analytics?.setUserId(company?.id);
        analytics?.logEvent("recruiter_login", {
          recruiter: `${user.firstName} ${user.lastName}`,
        });

        setTimeout(() => {
          // setUserInRedux(user);
          // setCompanyInRedux(company);
          storeUserSession(token);
          fetchProfile();
          navigate(routesConstants.HOME, { replace: true });
        }, 300);

        resolve(user);
      } catch (e: any) {
        reject(e);
        // console.log("login failed", e);
      }
    });
  };

  const updateCompany = (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        let payload = { ...values };
        if (payload?.file) {
          const formData = await convertObjectToFormData(payload);
          await profileApis.company.updateCompany(formData);
        } else {
          await profileApis.company.updateCompany(payload);
        }

        resolve({});
      } catch (e: any) {
        reject(e);
      }
    });
  };

  const getToken = async () => {
    return localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  };

  const logout = async () => {
    try {
      Sentry.configureScope((scope) => scope.setUser(null));
      const analytics = firebaseService.initAnalytics() as any;
      analytics?.logEvent("recuiter_logged_out", {
        recruiter: `${user.firstName} ${user.lastName}`,
      });
      await authApis.sessions.logout();
      setTimeout(() => {
        setUserInRedux({});
        setCompanyInRedux({});
        removeUserSession();
        navigate(routesConstants.GUEST_LOGIN, { replace: true });
      }, 300);
    } catch (e) {
    } finally {
    }
  };

  const fetchProfile = () => {
    return profileApis.get().then(({ recruiter }) => {
      if (isProduction()) {
        Sentry.configureScope(function (scope) {
          scope.setUser({
            id: recruiter?.id,
          });
        });
      }

      setUserInRedux(recruiter);
      setCountry(recruiter?.country?.code);

      companyApis.wallet
        .get()
        .then((data: any) => {
          setCompanyInRedux({ ...recruiter.company, wallet: data?.wallet });
        })
        .catch(() => {
          setCompanyInRedux({ ...recruiter.company });
        });
    });
  };

  const rehydrateUser = () => {
    const token = getToken() as any;
    if (token) {
      return fetchProfile();
    } else {
      throw new Error("invalid");
    }
  };

  const sendOtp = async (values: ISendOtpData | null, onSuccess?, onError?) => {
    try {
      await profileApis.sendOtp(
        !values
          ? {
              mobileNumber: user?.mobileNumber,
            }
          : values
      );
      onSuccess && onSuccess();
    } catch (error: any) {
      onError && onError(error);
    }
  };

  const verifyOtp = async (code, onSuccess?, onError?, mobileNumber?) => {
    try {
      await profileApis.verifyOtp({
        mobileNumber: mobileNumber || user?.mobileNumber,
        code,
      });
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
    }
  };

  const updateProfile = async (values, onSuccess?, onError?) => {
    try {
      let payload = { ...values };
      if (payload?.file) {
        const formData = await convertObjectToFormData(payload);
        await profileApis.update(formData);
      } else {
        await profileApis.update({ ...payload });
      }
      if (onSuccess) {
        onSuccess();
      }
      rehydrateUser();
    } catch (error: any) {
      onError && onError(error);
    }
  };

  const updateEmail = async (values: IUpdateEmail, onSuccess?, onError?) => {
    try {
      let payload = { ...values };
      await profileApis.updateEmail(payload);
      if (onSuccess) {
        onSuccess();
      }
      rehydrateUser();
    } catch (error: any) {
      onError && onError(error);
    }
  };

  const setAccessToken = async (accessToken) => {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken);
  };

  const getCompanyPaymentTypes = useCallback(() => {
    const companyPaymentTypes = company?.paymentTypes;
    return companyPaymentTypes || [];
  }, [company]);

  const canCompanyPayByCash = () => {
    const companyPaymentTypes = getCompanyPaymentTypes();
    return companyPaymentTypes.includes(PaymentTypesRef.CASH_OR_CHEK);
  };

  const getRole = ({ roleId }) => {
    const roles = settings?.recruiter?.role;
    const roleObject = find(roles, { value: roleId });
    return roleObject || {};
  };

  return {
    emailLogin,
    user,
    setUser,
    logout,
    rehydrateUser,
    setAccessToken,
    company,
    updateCompany,
    sendOtp,
    verifyOtp,
    updateProfile,
    updateEmail,
    getCompanyPaymentTypes,
    canCompanyPayByCash,
    getRole,
  };
}

export default useUser;
