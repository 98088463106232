import { ActionTypes } from "lib/constants/actionTypes";
import { GigForm } from "types/IJobForm";
import { gigsApi } from "lib/apis/gigs";
import map from "lodash/map";
import moment from "moment";
import { sortBy } from "lib/helpers/array";
import range from "lodash/range";
import {
  convertDecimalToHours,
  enumerateDaysBetweenDates,
  getHoursDuration,
} from "lib/helpers/date";
import groupBy from "lodash/groupBy";
import {
  DEFAULT_PAGE_SIZE,
  DOWNLOADABLE_FILE_TYPES,
} from "lib/constants/general";
import sum from "lodash/sum";
import filter from "lodash/filter";
import { cleanObject } from "lib/helpers/object";

export const shapeAttendanceTimeData = async (data) => {
  const dayStartDate = moment(
    `${moment(data.startDate).format("YYYY-MM-DD")} ${moment(
      data.startTime
    ).format("HH:mm")}`,
    "YYYY-MM-DD HH:mm"
  );

  let dayEndDate = null as any;

  if (data.endTime < data.startTime) {
    dayEndDate = moment(
      `${moment(data.startDate).add(1, "days").format("YYYY-MM-DD")} ${moment(
        data.endTime
      ).format("HH:mm")}`,
      "YYYY-MM-DD HH:mm"
    );
  } else {
    dayEndDate = moment(
      `${moment(data.startDate).format("YYYY-MM-DD")} ${moment(
        data.endTime
      ).format("HH:mm")}`,
      "YYYY-MM-DD HH:mm"
    );
  }
  return {
    startDate: `${moment
      .utc(dayStartDate)
      .lang("en")
      .format("YYYY-MM-DD HH:mm")}`,
    endDate: `${moment.utc(dayEndDate).lang("en").format("YYYY-MM-DD HH:mm")}`,
  };
};

const shapeFormDataToSubmit = async (form: GigForm) => {
  const timeSlots = sortBy(form?.timeSlots, "startDate", {
    valueType: "date",
  }) as any;

  // const startDate = moment(timeSlots[0].startDate)
  //   .lang("en")
  //   .format("YYYY-MM-DD");
  // const endDate = moment(timeSlots[timeSlots.length - 1].endDate)
  //   .lang("en")
  //   .format("YYYY-MM-DD");

  const firstTimeSlot = timeSlots[0];

  const startDate = moment(firstTimeSlot.selectedDays[0])
    .lang("en")
    .format("YYYY-MM-DD");
  const endDate = moment(
    firstTimeSlot.selectedDays[firstTimeSlot.selectedDays.length - 1]
  )
    .lang("en")
    .format("YYYY-MM-DD");

  let gigAttendances = [] as any;

  await map(timeSlots, async (timeSlot) => {
    // const allDays = enumerateDaysBetweenDates(
    //   timeSlot.startDate,
    //   timeSlot.endDate
    // );

    map(range(0, timeSlot.numberOfUsers), (attendance) => {
      map(firstTimeSlot.selectedDays, (dayDate) => {
        const dayStartDate = moment(
          `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
            timeSlot.startTime
          ).format("HH:mm")}`,
          "YYYY-MM-DD HH:mm"
        );

        let dayEndDate = null as any;

        if (timeSlot.endTime < timeSlot.startTime) {
          dayEndDate = moment(
            `${moment(dayDate).add(1, "days").format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        } else {
          dayEndDate = moment(
            `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        }

        gigAttendances.push({
          startDate: `${moment
            .utc(dayStartDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          endDate: `${moment
            .utc(dayEndDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          subCategoryId: timeSlot?.subCategoryId.value,
          extra: {
            isInsured: !!(Number(timeSlot.isInsured) === 1),
          },
        });
      });
    });
  });

  const payload = {
    ...form,
    addressId: form?.addressId?.value || form?.addressId || null,

    startDate,
    endDate,
    gigAttendances,
  };
  return payload;
};

export const setGigLocation = (location) => async (dispatch) => {
  if (!location) {
    return;
  }
  dispatch({
    type: ActionTypes.SET_GIG_OFFER,
    value: { location },
  });
};

export const prepareDataFormSubmit = async (form, success) => {
  console.log(form, "formform")
  const copiedValues = JSON.parse(JSON.stringify(form));
  const timeSlots = sortBy(copiedValues?.timeSlots, "startDate", {
    valueType: "date",
  }) as any;

  const firstTimeSlot = timeSlots[0];

  const startDate = moment(firstTimeSlot.selectedDays[0])
    .lang("en")
    .format("YYYY-MM-DD");
  const endDate = moment(
    firstTimeSlot.selectedDays[firstTimeSlot.selectedDays.length - 1]
  )
    .lang("en")
    .format("YYYY-MM-DD");

  let gigAttendances = [] as any;

  await map(timeSlots, async (timeSlot) => {
    map(range(0, timeSlot.numberOfUsers), (attendance) => {
      map(firstTimeSlot.selectedDays, (dayDate) => {
        const dayStartDate = moment(
          `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
            timeSlot.startTime
          ).format("HH:mm")}`,
          "YYYY-MM-DD HH:mm"
        );

        let dayEndDate = null as any;

        if (timeSlot.endTime < timeSlot.startTime) {
          dayEndDate = moment(
            `${moment(dayDate).add(1, "days").format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        } else {
          dayEndDate = moment(
            `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        }

        gigAttendances.push({
          startDate: `${moment
            .utc(dayStartDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          endDate: `${moment
            .utc(dayEndDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          subCategoryId: timeSlot?.subCategoryId.value,
          extra: {
            isInsured: !!(Number(timeSlot.isInsured) === 1),
          },
        });
      });
    });
  });

  const formData = {
    ...copiedValues,
    displayTitle: copiedValues?.displayTitle?.trim(),
    addressId: copiedValues?.addressId?.value || null,
    startDate,
    endDate,
    gigAttendances,
  } as any;

  formData.extra = {
    userFilter: {
      preferences: {
        workEnvironments:
          formData?.jobPreference && Number(formData?.jobPreference)
            ? formData?.jobPreference
            : null,
      },
      gender:
        formData?.gender && Number(formData?.gender) ? formData?.gender : null,
    },
  };
  delete formData?.jobPreference;
  delete formData?.gender;

  const data = {
    ...cleanObject(formData),
  };
  // dispatch({ type: ActionTypes.SET_GIG_OFFER, value: data });
  success && success(data);
};

export const setGigForm = (form: GigForm, success?) => async (dispatch) => {
  const timeSlots = sortBy(form?.timeSlots, "startDate", {
    valueType: "date",
  }) as any;

  const firstTimeSlot = timeSlots[0];

  const startDate = moment(firstTimeSlot.selectedDays[0])
    .lang("en")
    .format("YYYY-MM-DD");
  const endDate = moment(
    firstTimeSlot.selectedDays[firstTimeSlot.selectedDays.length - 1]
  )
    .lang("en")
    .format("YYYY-MM-DD");

  let gigAttendances = [] as any;

  await map(timeSlots, async (timeSlot) => {
    map(range(0, timeSlot.numberOfUsers), (attendance) => {
      map(firstTimeSlot.selectedDays, (dayDate) => {
        const dayStartDate = moment(
          `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
            timeSlot.startTime
          ).format("HH:mm")}`,
          "YYYY-MM-DD HH:mm"
        );

        let dayEndDate = null as any;

        if (timeSlot.endTime < timeSlot.startTime) {
          dayEndDate = moment(
            `${moment(dayDate).add(1, "days").format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        } else {
          dayEndDate = moment(
            `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
              timeSlot.endTime
            ).format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
          );
        }

        gigAttendances.push({
          startDate: `${moment
            .utc(dayStartDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          endDate: `${moment
            .utc(dayEndDate)
            .lang("en")
            .format("YYYY-MM-DD HH:mm")}`,
          subCategoryId: timeSlot?.subCategoryId.value,
          extra: {
            isInsured: !!(Number(timeSlot.isInsured) === 1),
          },
        });
      });
    });
  });

  const formData = {
    ...form,
    addressId: form?.addressId?.value || null,
    startDate,
    endDate,
    gigAttendances,
  } as any;

  // "extra": {
  // },
  formData.extra = {
    userFilter: {
      preferences: {
        workEnvironments:
          formData?.jobPreference && Number(formData?.jobPreference)
            ? formData?.jobPreference
            : 1,
      },
      gender:
        formData?.gender && Number(formData?.gender) ? formData?.gender : null,
    },
  };
  delete formData?.jobPreference;
  delete formData?.gender;

  const data = {
    ...formData,
  };

  dispatch({ type: ActionTypes.SET_GIG_OFFER, value: data });
  success && success(data);
};

export const createGig = () => async (GigOfferForm) => {
  return new Promise(async (resolve, reject) => {
    try {
      let payload = (await shapeFormDataToSubmit(GigOfferForm)) as any;
      payload.attachSurveyId = payload?.attachSurveyId?.id;
      let job = await gigsApi.create(cleanObject(payload));
      resolve(job);
    } catch (e: any) {
      reject(e);
    }
  });
};

export const updateGig = () => async (gigId, GigOfferForm) => {
  return new Promise(async (resolve, reject) => {
    try {
      let payload = (await shapeFormDataToSubmit(GigOfferForm)) as any;
      delete payload.timeSlots;
      delete payload.description;
      delete payload.endDate;
      delete payload.startDate;
      delete payload.gigAttendances;
      delete payload.survey;
      let job = await gigsApi.update({
        gigId,
        values: { ...payload, displayTitle: payload?.displayTitle?.trim() },
      });
      resolve(job);
    } catch (e: any) {
      reject(e);
    }
  });
};

export const getGigEstimatePrice = () => async (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let timeSlots = sortBy(formData?.timeSlots, "startDate", {
        valueType: "date",
      }) as any;

      timeSlots = filter(timeSlots, (timeSlot) => !!timeSlot);

      if (timeSlots?.length) {
        const firstTimeSlot = timeSlots[0];

        const startDate = moment(firstTimeSlot.selectedDays[0])
          .lang("en")
          .format("YYYY-MM-DD");
        const endDate = moment(
          firstTimeSlot.selectedDays[firstTimeSlot.selectedDays.length - 1]
        )
          .lang("en")
          .format("YYYY-MM-DD");

        // const startDate = moment(timeSlots[0].startDate)
        //   .lang("en")
        //   .format("YYYY-MM-DD");
        // const endDate = moment(timeSlots[timeSlots.length - 1].endDate)
        //   .lang("en")
        //   .format("YYYY-MM-DD");

        let gigAttendances = [] as any;

        await map(timeSlots, async (timeSlot) => {
          // const allDays = enumerateDaysBetweenDates(
          //   timeSlot.startDate,
          //   timeSlot.endDate
          // );

          map(range(0, timeSlot.numberOfUsers), (attendance) => {
            map(firstTimeSlot.selectedDays, (dayDate) => {
              const dayStartDate = moment(
                `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
                  timeSlot.startTime
                ).format("HH:mm")}`,
                "YYYY-MM-DD HH:mm"
              );

              let dayEndDate = null as any;

              if (timeSlot.endTime < timeSlot.startTime) {
                dayEndDate = moment(
                  `${moment(dayDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD")} ${moment(timeSlot.endTime).format(
                      "HH:mm"
                    )}`,
                  "YYYY-MM-DD HH:mm"
                );
              } else {
                dayEndDate = moment(
                  `${moment(dayDate).format("YYYY-MM-DD")} ${moment(
                    timeSlot.endTime
                  ).format("HH:mm")}`,
                  "YYYY-MM-DD HH:mm"
                );
              }

              gigAttendances.push({
                startDate: `${moment
                  .utc(dayStartDate)
                  .lang("en")
                  .format("YYYY-MM-DD HH:mm")}`,
                endDate: `${moment
                  .utc(dayEndDate)
                  .lang("en")
                  .format("YYYY-MM-DD HH:mm")}`,
                subCategoryId: timeSlot?.subCategoryId.value,
                extra: {
                  isInsured: !!(Number(timeSlot.isInsured) === 1),
                },
              });
            });
          });
        });

        const payload = {
          startDate,
          endDate,
          gigAttendances,
        };

        const estimation = (await gigsApi.estimatePrice(payload)) as any;
        resolve(estimation?.payment);
      } else {
        resolve({});
      }
    } catch (e: any) {
      reject(e);
    }
  });
};

export const fetchGigs = async (filters?) => {
  try {
    const { gigs } = (await gigsApi.list({
      limit: DEFAULT_PAGE_SIZE,
      ...filters,
    })) as any;
    return gigs;
  } catch (error) {
    // console.log(error, "errorerror");
  }
};

export const fetchGig = async ({ gigId }) => {
  const { gig } = (await gigsApi.get({ id: gigId })) as any;
  return gig;
};

export const extractTimeSlots = ({
  attendances,
  isRepost,
}: {
  attendances;
  isRepost?;
}) => {
  // groupBy(attendances, "subCategoryId");
  //First of all we need to group attendances by sub category id and get time slot for each sub category
  try {
    const groupedBySubcategory = groupBy(
      map(attendances, (attendance) => ({
        ...attendance,
        startTime: moment(attendance.startDate)
          .subtract(1, "days")
          .format("HH:mm:ss"),
        endTime:
          moment(attendance.startDate).minute() ===
            moment(attendance.endDate).minute()
            ? moment(attendance.endDate)
              .add(1, "minute")
              .subtract(1, "days")
              .format("HH:mm:ss")
            : moment(attendance.endDate).subtract(1, "days").format("HH:mm:ss"),
      })),
      "subCategoryId"
    );

    const slots = [] as any;

    map(groupedBySubcategory, (value) =>
      map(groupBy(value, "startTime"), (slot) => {
        const groupedByStartDate = Object.values(
          groupBy(slot, "startDate")
        ) as any;

        slots.push({
          numberOfUsers: groupedByStartDate[0].length,
          startDate: isRepost
            ? null
            : moment(groupedByStartDate[0][0].startDate),
          startTime: moment(groupedByStartDate[0][0].startTime, "HH:mm:ss"),
          endDate: isRepost
            ? null
            : moment(
              groupedByStartDate[groupedByStartDate?.length - 1][0].endDate
            ),
          endTime: moment(
            groupedByStartDate[groupedByStartDate?.length - 1][0].endTime,
            "HH:mm:ss"
          ),
          subCategoryId: {
            label: groupedByStartDate[0][0].subCategory?.titleEn,
            value: groupedByStartDate[0][0].subCategory?.id,
          },
          isInsured: groupedByStartDate[0][0].insurancePricePerHour ? 1 : 0,
          selectedDays: map(
            groupedByStartDate,
            (groupedByStartDateAttendance: any) =>
              groupedByStartDateAttendance[0]?.startDate
          ),
        });
      })
    );
    return slots;
  } catch (error) {
    // console.log(error, "Error Error Error ");
  }
};

export const storePaymentInfo =
  ({ paymentType, walletCardId }, success) =>
    async (dispatch, state) => {
      const { GigOfferForm } = state();
      dispatch({
        type: ActionTypes.SET_GIG_OFFER,
        value: { ...GigOfferForm, paymentType, walletCardId },
      });

      success && success({ ...GigOfferForm, paymentType, walletCardId });
    };

export const resetGigForm = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.RESET,
    value: {},
  });
};

export const getGigHours = ({ attendances }) => {
  if (attendances.length) {
    let hours = sum(
      map(attendances, (attendance) => attendance.numberOfHours)
    ) as any;
    let dicimal = 0 as any;
    if (`${hours}`.split(".")?.length > 1) {
      dicimal = `${hours}`.split(".")[1];
      dicimal = convertDecimalToHours(Number(`0.${dicimal}`));
      hours = `${hours}`.split(".")[0];
    }

    return {
      hours: hours || 0,
      dicimal: dicimal ? moment(dicimal, "hh:mm:ss").format("mm") : 0,
    };
  }
};

export const getUniqueAttendancesUsers = ({ attendances }) => {
  const grouped = groupBy(attendances, "userId");
  const uniqueUsers = [] as any;
  map(grouped, (group, key) => {
    let totalHours = sum(
      map(group, (attendance) => attendance.numberOfHours)
    ) as any;

    let dicimal = 0 as any;
    if (`${totalHours}`.split(".")?.length > 1) {
      dicimal = `${totalHours}`.split(".")[1];
      dicimal = convertDecimalToHours(Number(`0.${dicimal}`));
      totalHours = `${totalHours}`.split(".")[0];
    }

    if (!!key && key !== "null") {
      uniqueUsers.push({
        user: group[0]?.user,
        attendances: sortBy(group, "startDate", {
          valueType: "date",
        }),
        avgRating:
          sum(
            map(
              filter(group, (atten) => atten.creatorRating),
              (attendance) => attendance?.creatorRating
            )
          ) / filter(group, (atten) => atten.creatorRating)?.length,
        spending: sum(map(group, (attendance) => attendance?.totalAmount)),
        avgPricePerHour:
          sum(map(group, (attendance) => attendance?.pricePerHour)) /
          group?.length,
        currency: group[0]?.currency,
        totalHours: totalHours || 0,
        minutes: dicimal ? moment(dicimal, "hh:mm:ss").format("mm") : 0,
      });
    }
  });
  return uniqueUsers;
};

export const updateAttendance = async (
  gigId,
  attendanceId,
  attendanceValues
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let payload = (await shapeAttendanceTimeData(attendanceValues)) as any;
      let job = await gigsApi.attendances.update({
        gigId,
        attendanceId,
        values: { ...payload },
      });
      resolve(job);
    } catch (e: any) {
      reject(e);
    }
  });
};

export const downloadGigsAttendancesCsv = async (
  { filters },
  success?,
  error?
) => {
  try {
    var hiddenElement = document.createElement("a");

    // if (fileType === DOWNLOADABLE_FILE_TYPES.EXCEL) {
    //   const fileData = await gigsApi.attendances.download({
    //     surveyId: surveyState?.survey?.id,
    //   });
    //   try {
    //     const blob = await new Blob([fileData], {
    //       type: "application/csv",
    //     });
    //     const url = window.URL || window.webkitURL;
    //     const link = url.createObjectURL(blob);
    //     hiddenElement.href = link;
    //     // hiddenElement.target = "_blank";
    //     hiddenElement.download = `${moment().format(
    //       "DD/MM/YYYY"
    //     )}-${(surveyState?.survey?.title).replace(" ", "_")}.${fileType}`;
    //     hiddenElement.click();
    //   } catch (fileError) { }
    // } else {
    const fileData = (await gigsApi.attendances.download(filters)) as any;

    hiddenElement.href = `data:text/csv;charset=utf-8-sig,${encodeURI(
      fileData
    )}`;
    hiddenElement.target = "_blank";
    hiddenElement.download = `${moment().format("DD/MM/YYYY HH:mm")}.csv`;
    hiddenElement.click();
    // }
    success && success();
  } catch (fetchSurveyError) {
    error && error();
  }
};
