import React from "react";
import Logo from "../Logo";
import styled from "styled-components";
import "./styles.css";
import LargeBar from "./LargeBar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ProfileDropDown from "./ProfileDropDown";
import useUser from "lib/hooks/useUser";
import JobsSvg from "assets/icons/header/jobs.svg";
import CompelteCreditCardFlow from "../CreditCardFormWraper/CompleteCreditCardFlow";
import { getLinkSearchParams } from "lib/helpers/general";
import { routesConstants } from "lib/constants/urls";
import { FcMenu } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";

interface Props {}

const ButtonIcon = styled.img.attrs({})`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const NormalIcon = styled.div`
  height: 35px;
  width: auto;
  padding-right: 5px;
`;

function ClientHeader(props: Props) {
  const { company, logout } = useUser();
  const [openSidebar, setOpenSidebar] = useState(Boolean);
  const location = useLocation();

  const isSelected = (pathname) => {
    return location.pathname === pathname ? "selected" : "";
  };

  const searchParams = React.useMemo(
    () => getLinkSearchParams(location?.search),
    [location?.search]
  );

  const toggleSideBar = (e?) => {
    e?.stopPropagation();
    const sideBarElement = document.getElementById("side-bar");
    const bodyElement = document.getElementsByTagName("body");
    if (sideBarElement) {
      if (sideBarElement.classList.contains("open-side-bar")) {
        sideBarElement.classList.remove("open-side-bar");
        setOpenSidebar(false);
        bodyElement[0].classList.remove("overflow-hidden");
      } else {
        sideBarElement.classList.add("open-side-bar");
        setOpenSidebar(true);
        bodyElement[0].classList.add("overflow-hidden");
      }
    }
  };

  useEffect(() => {
    if (openSidebar) {
      toggleSideBar();
    }
  }, [location?.pathname]);

  return (
    <div>
      <div className="relative">
        <div className="w-full hidden lg:block">
          <LargeBar company={company} logout={logout} isSelected={isSelected} />
        </div>
        <div className="w-full block lg:hidden">
          <div className="w-full flex p-2 pl-16">
            <NormalIcon
              className={`h-full mt-2 cursor-pointer ${
                isSelected(routesConstants.HOME) ? `text-primary` : ``
              }`}
              onClick={toggleSideBar}
            >
              <FcMenu className="text-primary" size={35} />
            </NormalIcon>

            <Link
              to={routesConstants.HOME}
              className="flex items-center justify-center ml-3"
            >
              <Logo />
            </Link>
            <div className="flex justify-end flex-1 items-center">
              <Link to={routesConstants.HOME} className="h-6 ml-6">
                <ButtonIcon
                  className={`h-full ${
                    isSelected(routesConstants.HOME) ? `text-primary` : ``
                  }`}
                  src={JobsSvg}
                />
              </Link>
              <ProfileDropDown company={company} logout={() => logout()} />
            </div>
          </div>
        </div>
      </div>
      {searchParams?.id && <CompelteCreditCardFlow />}
      {openSidebar && (
        <div
          style={{ background: "rgba(0,0,0,0.6)" }}
          className="absolute top-0 right-0 bottom-0 left-0 z-10"
          onClick={toggleSideBar}
        >
          <AiOutlineClose
            onClick={toggleSideBar}
            className="text-white ml-auto mt-6 mr-6 cursor-pointer"
            size={35}
          />
        </div>
      )}
    </div>
  );
}

export default ClientHeader;
