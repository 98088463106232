import React from "react";

interface CompleteProfileWrapperProps {
  children;
  activeStep;
  onNextStep: () => void;
  handleDone: () => void;
}

const CompleteProfileWrapper = (props: CompleteProfileWrapperProps) => {
  const children = React.Children.toArray(props.children).filter(
    Boolean
  ) as any;
  return React.Children.map(children, (child, key) => {
    return key === props.activeStep
      ? React.cloneElement(child, {
          ...child.props,
          onNextStep: props.onNextStep,
          handleDone: props.handleDone,
        })
      : null;
  });
};

export default CompleteProfileWrapper;
