import { CHAT_BOT_ACTION_TYPES } from "./ChatBotActions";

export const chatBotInitialState = {
  messages: [],
};

export const ChatBotReducer = (state, action) => {
  switch (action.type) {
    case CHAT_BOT_ACTION_TYPES.UPDATE_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
  }
};