import React from "react";
import Svg from "../Svg";
import { KADER_LOGO } from "theme/assetsConfiguration";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: "white",
        zIndex: 99999,
      }}
    >
      <KADER_LOGO className="" width="100" height="100" />
    </div>
  );
};

export default Loader;
