import React from "react";
import styled from "styled-components";
import LogoSvg from "assets/images/logo/logo.svg";

interface Props {
  containerProps?;
  imageProps?;
  src?;
}

const SVG = styled.img.attrs({ className: `h-auto w-24` })``;

const Logo = (props: Props) => {
  return (
    <div {...props.containerProps}>
      <SVG {...props.imageProps} src={props.src || LogoSvg} />
    </div>
  );
};

export default Logo;
