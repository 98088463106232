import React, { createContext, useReducer } from "react";

// List of initial state for all global data
const initialState = {
  newNotification: false,
  notification: {
    open: false,
    duration: 0,
    position: "",
    message: "",
    type: "",
  },
  flashMessage: {
    open: false,
    message: "",
    duration: 0,
  },
} as any;

// Actions
export const COMMON_SESSION = "COMMON_SESSION";
export const NOTIFICATIONS_CHANGED = "NOTIFICATIONS_CHANGED";

// Shared Reducer For Global Context
const Reducer = (notificationsState, action) => {
  switch (action.type) {
    case COMMON_SESSION:
      return {
        notification: action.payload.notification,
        newNotification: action.payload.newNotification,
        toast: action.payload.toast,
        flashMessage: action.payload.flashMessage,
        modal: action.payload.modal,
      };
    default:
      return notificationsState;
  }
};

// Global State Which its provide context for children
const NotificationsState = ({ children }) => {
  const [notificationsState, dispatchState] = useReducer(Reducer, initialState);

  return (
    <NotificationContext.Provider value={[notificationsState, dispatchState]}>
      {children}
    </NotificationContext.Provider>
  );
};

// Create Global Context
export const NotificationContext = createContext(initialState);

// Export Global State Context Component
export default NotificationsState;
