import React from "react";
import useLocales from "lib/hooks/useLocales";
import useSiteLanguages from "lib/hooks/useSiteLanguages";
import { MdLanguage } from "react-icons/md";
// import assetsConfig from "theme/assetsConfiguration";

const LanguageToggler = () => {
  const { changeLanguage } = useSiteLanguages();
  const header = useLocales("header") as any;

  const handleChangeLanguage = () => {
    changeLanguage();
    window.location.reload();
  };

  return (
    <div
      onClick={handleChangeLanguage}
      className="cursor-pointer mt-4 lg:mt-0 flex items-center"
    >
      <MdLanguage className="text-3xl text-grey " />
      {/* {language === siteLanguages.AR.value ? (
        <assetsConfig.icons.flags.unitedStates width="18px" height="21px" />
      ) : (
        <assetsConfig.icons.flags.sau width="18px" height="21px" />
      )} */}
      <span
        className={`leading-none inline-block text-black md:text-base font-medium text-white md:text-black ml-2`}
      >
        {header.language}
      </span>
    </div>
  );
};

export default LanguageToggler;
