import { useContext } from "react";
import { GlobalContext } from "context";

function useDirection() {
  const { dir } = useContext(GlobalContext) as any;
  const isLtr = () => dir === "ltr";

  return { dir, isLtr };
}

export default useDirection;
