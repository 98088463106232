import React, { useRef, useEffect, useContext } from "react";
import "./FlashMessage.scss";
import { MdClose } from "react-icons/md";
import {
  NotificationContext,
  COMMON_SESSION,
} from "lib/contexts/NotificationContext";

const FlashMessage = () => {
  const rootRef = useRef() as any;
  const contentRef = useRef() as any;
  const [notificationsState, dispatchState] = useContext(
    NotificationContext
  ) as any;

  const openFlashMessage = () => {
    if (rootRef && rootRef.current) {
      rootRef.current.classList.add("flash-message__open");
      rootRef.current.style.height = `${contentRef.current.scrollHeight}px`;

      if (notificationsState?.flashMessage?.duration) {
        setTimeout(() => {
          closeFlashMessage();
        }, notificationsState?.flashMessage?.duration);
      }
    }
  };

  const closeFlashMessage = () => {
    if (rootRef && rootRef.current) {
      rootRef.current.classList.remove("flash-message__open");
      rootRef.current.style.height = 0;
      setTimeout(() => {
        dispatchState({
          type: COMMON_SESSION,
          payload: {
            flashMessage: {
              open: false,
              message: "",
            },
          },
        });
      }, 300);
    }
  };

  useEffect(() => {
    if (notificationsState?.flashMessage?.open) {
      setTimeout(() => {
        openFlashMessage();
      }, 300);
    }
  }, [notificationsState.flashMessage]);

  const handleClose = () => {
    closeFlashMessage();
  };

  return notificationsState &&
    notificationsState?.flashMessage &&
    notificationsState?.flashMessage?.open ? (
    <div ref={rootRef} className={"flash-message"}>
      <div ref={contentRef}>{notificationsState.flashMessage.message}</div>
      <MdClose
        className={`${"flash-message__close-icon"} text-background`}
        onClick={handleClose}
      />
    </div>
  ) : null;
};

export default FlashMessage;
