import useLocales from 'lib/hooks/useLocales';
import React from 'react';
import assetsConfig from 'theme/assetsConfiguration';

const WillContactYouSoon = () => {
    const locales = useLocales("willContactYouSoon");
    return (
        <div>
            <assetsConfig.icons.willContactYouSoon width="50%" className="mx-auto" />
            <h1 className="text-center text-primary font-avenir-medium text-2xl">
                {locales.title}
            </h1>
        </div>
    );
}

export default WillContactYouSoon