import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  description: null,
  startDate: null,
  endDate: null,
  location: {},
  timeSlots: null,
};

const gigOfferFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_GIG_OFFER:
      return {
        ...state,
        ...action.value,
      };
    case ActionTypes.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default gigOfferFormReducer;
