import { ActionTypes } from "../../constants/actionTypes";

const initialState = {};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
