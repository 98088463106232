import { useContext } from "react";
import { GlobalContext } from "context";

import get from "lodash/get";

const useLocales = (path = "") => {
  const { locales } = useContext(GlobalContext) as any;

  return path ? get(locales, path || "", {}) : locales;
};

export default useLocales;
