import React, { createContext, useReducer, useMemo } from "react";

export const ScrollingContext: any = createContext(null);

const initialState = {
  scroll: 0,
};

export enum scrollingActionTypes {
  SCROLL = "SCROLL",
}

const ScrollingReducer = (globalState, action) => {
  switch (action.type) {
    case "SCROLL":
      return {
        ...globalState,
        scroll: action.scroll,
      };
  }
};

export const ScrollingProvider = (props) => {
  const [state, dispatch] = useReducer(ScrollingReducer, initialState);

  const scrollingMemo = useMemo(
    () => ({
      setScrolling: async (scroll: any) => {
        dispatch({ type: scrollingActionTypes.SCROLL, scroll });
      },
    }),
    []
  );

  return (
    <ScrollingContext.Provider value={{ ...state, ...scrollingMemo }}>
      {props.children}
    </ScrollingContext.Provider>
  );
};

export default ScrollingContext.Consumer;
