import styled from "styled-components";

interface InnerProps {}

export const WideOutterWrapper = styled.div.attrs({
  className: "w-full h-full flex justify-center"
})``;

export const WideInnerWrapper = styled.div.attrs((props: InnerProps) => ({}))`
  @media screen and (max-width: 1024px) {
    min-width: 55%;
    max-width: 55%;
  }
  @media screen and (max-width: 640px) {
    min-width: 85%;
    max-width: 85%;
  }
`;
