import Axios, { AxiosResponse, AxiosError, AxiosStatic } from "axios";
import { StorageKeys } from "../constants/storageKeys";
import { getLang } from "../services/LocalStorage";
import { routesConstants, whiteListedRoutes } from "lib/constants/urls";
import { isProduction } from "lib/helpers/general";

const getBaseUrl = () => {
  return isProduction()
    ? "https://recruiter-api.kaderapp.com"
    : "https://recruiter-stg.kaderapp.com";
};

const refreshSession = async (token) => {
  await window.localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  request.defaults.headers["Authorization"] = await `Bearer ${token}`;
};

const onSuccess = function (response: AxiosResponse) {
  console.log("Request Success:", response.config);

  if (response?.headers["Authorization-Token"]) {
    refreshSession(response?.headers["Authorization-Token"]);
  }

  return response.data;
};

const onError = function (error: AxiosError) {
  console.log("Request Failed:", error.config);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.log("Status:", error.response.status);
    console.log("Data:", error.response.data);
    console.log("Headers:", error.response.headers);

    switch (error.response.status) {
      case 401:
        localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
        if (!whiteListedRoutes.includes(window.location.pathname)) {
          setTimeout(() => {
            window.location.replace(routesConstants.GUEST_LOGIN);
          }, 500);
        }
        break;
      default:
        break;
    }
    return Promise.reject(error.response.data);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.log("Error Message:", error.message);
  }

  return Promise.reject(error);
};

Axios.interceptors.response.use(onSuccess, onError);
Axios.defaults.baseURL = `${getBaseUrl()}/api/v1`;
Axios.defaults.headers.common["Accept-Language"] = getLang();

Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  StorageKeys.ACCESS_TOKEN
)}`;

Axios["setSession"] = function (token) {
  this.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

Axios["removeSession"] = function (token) {
  delete this.defaults.headers.common["Authorization"];
};

const request = Axios as AxiosStatic & { setSession: any; removeSession: any };

export default request;
