import React from "react";
import RegisterationHeader from "../../components/NeutralHeader";
import SupportFooter from "../../components/SupportFooter";
import {
  WideInnerWrapper,
  WideOutterWrapper,
} from "theme/custom/RegisterWrapper";
import SuspenseWrapper from "shared/components/SuspenseWrapper";

function VerifyLayout(props) {
  return (
    <div>
      <RegisterationHeader />
      <WideOutterWrapper>
        <WideInnerWrapper>
          <SuspenseWrapper>{props.children}</SuspenseWrapper>
          <div className="pt-2">
            <SupportFooter />
          </div>
        </WideInnerWrapper>
      </WideOutterWrapper>
    </div>
  );
}
export default VerifyLayout;
