import React, { useState, useMemo } from "react";
import { GlobalContext } from "../../context";
import Locales from "../services/Locales";
import Axios from "axios";

import { setLang, getLang, getCountry, getDir } from "../services/LocalStorage";
import {
  countriesCodes,
  siteCountries,
  siteLanguages,
} from "lib/constants/general";

function withContext(WrappedComponent) {
  function WithContext(props) {
    const [language] = useState(getLang()) as any;

    console.log(language, "languagelanguagelanguagelanguagelanguage");

    const contextMainData = useMemo(
      () => ({
        language,
        locales: Locales[language],
        dir: getDir(),
        changeLanguage: async () => {
          const savedCountry = getCountry();

          if (savedCountry) {
            const countryObject = siteCountries.find(
              (country) => country.code === savedCountry
            ) as any;
            await (Axios.defaults.headers.common["Accept-Language"] =
              language === countryObject.languages.AR.value
                ? siteLanguages.EN.value
                : siteLanguages.AR.value);
            setLang(
              language === countryObject.languages.AR.value
                ? countryObject.languages.EN.value
                : countryObject.languages.AR.value
            );
          } else {
            await (Axios.defaults.headers.common["Accept-Language"] =
              language === siteLanguages.AR.value
                ? siteLanguages.EN.value
                : siteLanguages.AR.value);
            setLang(
              language === siteLanguages.AR.value
                ? siteLanguages.EN.value
                : siteLanguages.AR.value
            );
          }
        },
      }),
      [language]
    );

    return (
      <GlobalContext.Provider value={contextMainData}>
        <WrappedComponent {...props} />
      </GlobalContext.Provider>
    );
  }

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  WithContext.displayName = `withContext(${wrappedComponentName})`;
  return WithContext;
}

export default withContext;
