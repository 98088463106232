import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  industries: null,
  jobTitles: null,
  actionPrices: [],
  categories: null,
  gigsSubCategories: null,
  nationalities: null,
  countries: null,
  currencies: null,
};

const SharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_INDUSTRIES:
      return {
        ...state,
        industries: action.value,
      };
    case ActionTypes.SET_JOB_TITLES:
      return {
        ...state,
        jobTitles: action.value,
      };
    case ActionTypes.SET_ACTION_PRICES:
      return {
        ...state,
        actionPrices: action.value,
      };
    case ActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.value,
      };
    case ActionTypes.SET_GIGS_SUBCATEGORIES:
      return {
        ...state,
        gigsSubCategories: action.value,
      };
    case ActionTypes.SET_NATIONALITIES:
      return {
        ...state,
        nationalities: action.value,
      };
    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.value,
      };
    case ActionTypes.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.value,
      };

    default:
      return state;
  }
};

export default SharedReducer;
