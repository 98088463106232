import request from ".";
import { StorageKeys } from "../constants/storageKeys";
import get from "lodash/get";
import { convertObjectToFormData } from "lib/helpers/general";

// auth/signup/email
export const authApis = {
  emailLogin: ({ email, password }) =>
    request.post("/auth/login/email", {
      email,
      password,
    }) as any,
  signUp: (values) => request.post("/auth/signup/email", values) as any,
  verify: (values) =>
    request.get("/auth/verify/email", { params: values }) as any,
  forgotEmail: {
    sendCode: (values) =>
      request.post("/auth/forgot/email/send-code", values) as any,
    verifyOtp: (values) =>
      request.post("/auth/forgot/email/verify-code", values) as any,
    resetEmail: (values) =>
      request.post("/auth/forgot/email/reset", values) as any,
  },
  password: {
    forgetPassword: ({ email }) =>
      request.post("/auth/forgot/password", { email }),
    verifyOTPForReset: ({ code, mobileNumber }) =>
      request.post("/auth/forgot/verify-code", {
        code,
        mobileNumber,
      }),
    resetPassword: ({ password, token }) => {
      return request.post("/auth/forgot/password/reset", {
        password,
        token,
      }) as any;
    },
  },
  sessions: {
    refresh: async () => {
      const { session } = (await request.get(
        "/recruiters/sessions/refresh"
      )) as any;
      localStorage.setItem(
        StorageKeys.ACCESS_TOKEN,
        get(session, "token", null)
      );
    },
    logout: () => {
      const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
      const headers = accessToken
        ? { Authorization: accessToken }
        : ({} as any);
      localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
      return request.delete("/recruiters/sessions/expire", {
        headers,
      }) as any;
    },
  },
  company: {
    updateInfo: async (payload, isCreate = false) => {
      const { accessToken } = payload;
      const headers = accessToken
        ? { Authorization: accessToken }
        : ({} as any);
      if (payload.accessToken) {
        delete payload.accessToken;
      }

      let newPayload = await payload;
      if (payload.image) {
        payload.file = payload?.image?.file;
        delete payload.image;
        newPayload = await convertObjectToFormData(payload);
      }

      if (isCreate) {
        return request.post("/companies", newPayload, {
          headers: headers,
        });
      } else {
        delete newPayload?.countryId;
        return request.put("/companies", newPayload, {
          headers,
        });
      }
    },
    uploadLogo: async (payload) => {
      const { accessToken } = payload;
      const headers = accessToken
        ? { Authorization: accessToken }
        : ({} as any);
      const data = new FormData();
      payload.file && data.append("file", payload.file);

      return request.put("/companies/logo-image", data, {
        headers,
      });
    },
  },
};
