import { combineReducers } from "redux";
import User from "./user";
import Settings from "./settings";
import Shared from "./shared";
import Guest from "./guest";
import GigOfferForm from "./gigOfferForm";

export default combineReducers({
  User,
  Settings,
  Guest,
  Shared,
  GigOfferForm,
});
