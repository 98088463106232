import React from "react";
import { SimpleLoader } from "../Loader";

const SuspenseWrapper = (props) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex-1 flex items-center justify-center w-full h-full">
          <SimpleLoader />
        </div>
      }
    >
      {props.children}
    </React.Suspense>
  );
};

export default SuspenseWrapper;
