import React from "react";
import GuestSideBar from "./GuestSideBar";
import _ from "lodash";
import { StorageKeys } from "lib/constants/storageKeys";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "lib/constants/urls";

function GuestLayout(props) {
  const navigate = useNavigate();

  React.useEffect(() => {
    let access_token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    if (!_.isNull(access_token)) {
      navigate(routesConstants.HOME, {
        replace: true,
      });
    }
  });

  return (
    <div className="flex w-full flex-col sm:flex-row">
      <div className="w-full sm:w-1/2">
        <GuestSideBar />
      </div>
      <div className="absolute w-full sm:relative sm:w-1/2">
        {props.children}
      </div>
    </div>
  );
}

export default GuestLayout;
