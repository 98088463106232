import { Roles, permissions } from "lib/constants/general";
import { useCallback, useEffect } from "react";
import get from "lodash/get";
import find from 'lodash/find';
import useSettings from "../useSettings";

// can(1,"gigs", "create")

interface CheckRoleFunc {
  (role: Roles.ADMIN | Roles.EDITOR | Roles.VIEWER): boolean;
}

interface HasPermissionFunc {
  (
    role: Roles.ADMIN | Roles.EDITOR | Roles.VIEWER,
    resource: string,
    resourceAction
  ): boolean;
}

interface UseRoles {}

const useRoles = (options?: UseRoles) => {


  const {getSettingOption} = useSettings();

  const isAdmin: CheckRoleFunc = useCallback(
    (role) => role === Roles.ADMIN,
    []
  );
  const isEditor: CheckRoleFunc = useCallback(
    (role) => role === Roles.EDITOR,
    []
  );
  const isViewer: CheckRoleFunc = useCallback(
    (role) => role === Roles.VIEWER,
    []
  );

  const getRole = ({roleId}) => {
    const roles = getSettingOption("recruiter.role")
    const roleObject = find(roles, {value: roleId});
    return roleObject;
  }

  const hasPermission: HasPermissionFunc = (role, resource, resourceAction) => {
    const permitedActinos = get(permissions, `${role}.${resource}`) as any;
    return permitedActinos?.includes(resourceAction);
  };

  return { isAdmin, isEditor, isViewer, hasPermission, getRole };
};

export default useRoles;
