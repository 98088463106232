import { siteLanguages } from "lib/constants/general";
import { routesConstants } from "lib/constants/urls";
import useLocales from "lib/hooks/useLocales";
import useSiteLanguages from "lib/hooks/useSiteLanguages";
import useUser from "lib/hooks/useUser";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import Styled from "styled-components";
import assetsConfig from "theme/assetsConfiguration";

const SidebarWrapper = Styled.div.attrs({})`
    width: 296px;
    background: white;
    max-height: calc(100vh - 116px);
    overflow: auto;
    @media (max-width: 1024px) {
      z-index: 99;
      overflow-y: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      transition: all 0.3s;
      ${(props) =>
        props.isRtl
          ? `
      left: 0;
      right: -100%;

      `
          : `
      left: -100%;
      right: 0;

      `}
    }    
`;
const SidebarInnerWrapper = Styled.div.attrs({})`
padding: 43px;
`;

const SideBarItem = Styled.div.attrs({})`
    background-color: transparent;
    min-width: 167px;
    width: 100%;
    display: inline-block;
    padding: 15px;
    border-radius: 12px;
    font-weight: 500;
    color: #1F2126;
    font-size: 1rem;
    transition: all 0.3s; 
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: white;
        background-color: ${({ theme: { colors } }) => colors.primary};
    }
    &:hover svg {
        fill: #ffffff;
    }

    &:not(:first-child) {
        margin-top: 34px
    }

    ${(props) =>
      props.isActive
        ? `
    color: white;
    background-color: ${props?.theme.colors.primary};
    & svg {
      fill: #ffffff;

    }

    `
        : ""}
`;

const SideBar = () => {
  const locales = useLocales("sidebar");
  const location = useLocation();
  const { language } = useSiteLanguages();

  const isActive = (pathname) => pathname === location.pathname;

  const { logout, company } = useUser();

  return (
    <SidebarWrapper isRtl={language === siteLanguages.AR.value} id="side-bar">
      <SidebarInnerWrapper>
        <Link to={routesConstants.HOME}>
          <SideBarItem isActive={isActive(routesConstants.HOME)}>
            <assetsConfig.icons.sidebarIcons.dashboardIcon />
            <span className="inline-block ml-3"> {locales.dashboard}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.JOBS}
          style={{ marginTop: 34 }}
          className="block "
        >
          <SideBarItem
            isActive={
              isActive(routesConstants.JOBS) ||
              location?.pathname?.search("job/") >= 0 ||
              location?.pathname?.search("jobs/") >= 0
            }
          >
            <assetsConfig.icons.sidebarIcons.jobsIcon />
            <span className="inline-block ml-3"> {locales.jobs}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.SURVEYS}
          style={{ marginTop: 34 }}
          className="block "
        >
          <SideBarItem
            isActive={
              isActive(routesConstants.SURVEYS) ||
              location?.pathname?.search("survey/") >= 0 ||
              location?.pathname?.search("surveys/") >= 0 ||
              isActive(routesConstants.CREATE_SURVEY) ||
              location?.pathname?.search("surveys-edit/") >= 0
            }
          >
            <assetsConfig.icons.sidebarIcons.documents />
            <span className="inline-block ml-3"> {locales.surveys}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.FAVORITES}
          style={{ marginTop: 34 }}
          className="block "
        >
          <SideBarItem isActive={isActive(routesConstants.FAVORITES)}>
            <assetsConfig.icons.sidebarIcons.jobsIcon />
            <span className="inline-block ml-3"> {locales.favorite}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.TOP_KADEROS}
          style={{ marginTop: 34 }}
          className="block "
        >
          <SideBarItem isActive={isActive(routesConstants.TOP_KADEROS)}>
            <assetsConfig.icons.sidebarIcons.jobsIcon />
            <span className="inline-block ml-3"> {locales.topKaderos}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.SHIFTS_LOGS}
          style={{ marginTop: 34 }}
          className="block "
        >
          <SideBarItem isActive={isActive(routesConstants.SHIFTS_LOGS)}>
            <assetsConfig.icons.sidebarIcons.jobsIcon />
            <span className="inline-block ml-3"> {locales.shifts_logs}</span>
          </SideBarItem>
        </Link>

        <Link
          to={routesConstants.PROFILE_RECRUITER}
          style={{ marginTop: 34 }}
          className="block"
        >
          <SideBarItem isActive={isActive(routesConstants.PROFILE_RECRUITER)}>
            <assetsConfig.icons.sidebarIcons.profileIcon />
            <span className="inline-block ml-3"> {locales.profile}</span>
          </SideBarItem>
        </Link>

        <Link
          to={routesConstants.COMPANY_PROFILE}
          style={{ marginTop: 34 }}
          className="block"
        >
          <SideBarItem isActive={isActive(routesConstants.COMPANY_PROFILE)}>
            <assetsConfig.icons.sidebarIcons.companyIcon />
            <span className="inline-block ml-3"> {locales.company}</span>
          </SideBarItem>
        </Link>
        <Link
          to={routesConstants.PROFILE_BALANCE}
          style={{ marginTop: 34 }}
          className="block"
        >
          <SideBarItem isActive={isActive(routesConstants.PROFILE_BALANCE)}>
            <assetsConfig.icons.sidebarIcons.walletIcon />
            <span className="inline-block ml-3"> {locales.balance}</span>
          </SideBarItem>
        </Link>
        <SideBarItem onClick={logout}>
          <assetsConfig.icons.sidebarIcons.logoutIcon />
          <span className="inline-block ml-3"> {locales.logout}</span>
        </SideBarItem>
      </SidebarInnerWrapper>
    </SidebarWrapper>
  );
};

export default SideBar;
