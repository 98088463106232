import { KaderAppLinks } from "lib/constants/urls";
import { ifArabic } from "lib/helpers/i18n";
import React from "react";
import assetsConfig, { KADER_LOGO } from "theme/assetsConfiguration";
import Svg from "../Svg";

const DownloadApp = () => {
  return (
    <div className="flex  flex-col items-center justify-center h-full">
      <KADER_LOGO height={120} width={150} />
      <p className="py-4 px-4 text-center text-xl font-avenir-medium">
        {ifArabic(
          "لتحصل على تجربة افضل قم بتنزيل تطبيق كادر من المتجر",
          "For better user experiance download kaderapp from store"
        )}
      </p>
      <div className="w-full md:w-1/4 mb-3 mt-8 md:mt-0  flex flex-col items-center md:items-start">
        <div className="w-2/4 mt-4">
          <a
            href={KaderAppLinks.ANDROID}
            target="_blank"
            rel="noopener noreferrer"
          >
            <assetsConfig.icons.androidStore className="w-full bg-black rounded-lg" />
          </a>
        </div>
        <div className="w-2/4 mt-4">
          <a href={KaderAppLinks.IOS} target="_blank" rel="noopener noreferrer">
            <assetsConfig.icons.appleStore className="w-full rounded-lg bg-black" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
