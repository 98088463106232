import request from ".";

export const companyApis = {
  wallet: {
    get: () => request.get("/companies/wallets"),
    cards: {
      list: () => request.get("/companies/wallets/cards"),
      get: ({ cardId }) => request.get(`/companies/wallets/cards/${cardId}`),
      update: ({
        cardId,
        values,
      }: {
        cardId: number;
        values: { isPrimary: boolean };
      }) => request.put(`/companies/wallets/cards/${cardId}`, values),
      create: ({ values }: { values: { checkoutId: string } }) =>
        request.post(`/companies/wallets/cards`, values),
      delete: ({ cardId }) =>
        request.delete(`/companies/wallets/cards/${cardId}`),

      checkoutId: () => request.get(`/companies/wallets/cards/checkout`),
    },
    transactions: {
      list: ({ filters }: { filters? }) =>
        request.get("/companies/wallets/transactions", {
          params: { ...filters },
        }),
      get: ({ transactionId }) =>
        request.get(`/companies/wallets/transactions/${transactionId}`),
      payPending: ({ transactionId, values }) =>
        request.post(
          `/companies/wallets/transactions/${transactionId}/pay`,
          values
        ),
    },
    operations: {
      list: ({ filters }: { filters? }) =>
        request.post("/companies/wallets/operations/list", {
          ...filters,
        }),
      get: ({ operationId }) =>
        request.get(`/companies/wallets/operations/${operationId}`),
      payPending: ({ operationId, values }) =>
        request.post(
          `/companies/wallets/operations/${operationId}/pay`,
          values
        ),
    },
  },
  addresses: {
    list: (filter?) =>
      request.get("/companies/addresses", { params: { ...filter } }) as any,
    update: ({ addressId, values }) => {
      delete values.id;
      delete values.serviceableId;
      delete values.serviceableType;
      delete values.createdAt;
      delete values.updatedAt;
      delete values.deletedAt;
      return request.put(`/companies/addresses/${addressId}`, values) as any;
    },
    create: ({ values }) => request.post(`/companies/addresses`, values) as any,
    delete: ({ addressId }) =>
      request.delete(`/companies/addresses/${addressId}`) as any,
  },
  recruiters: {
    list: (filters?) =>
      request.get("/companies/recruiters", { params: filters }),
    get: ({ recruiterId }) =>
      request.get(`/companies/recruiters/${recruiterId}`),
    update: ({
      recruiterId,
      values,
    }: {
      recruiterId: number;
      values: { isPrimary: boolean };
    }) => request.put(`/companies/recruiters/${recruiterId}`, values),
    create: ({ values }) => request.post(`/companies/recruiters`, values),
    delete: ({ recruiterId }) =>
      request.delete(`/companies/recruiters/${recruiterId}`),
  },
  surveys: {
    attach: ({ surveyId, values }) =>
      request.post(`/compaines/surveys/${surveyId}/attach`, values),
    detach: ({ surveyId, values }) =>
      request.delete(`/compaines/surveys/${surveyId}/attach`, { data: values }),
  },
  favorites: {
    list: (filter?) =>
      request.get("/companies/favorites/users", {
        params: { ...filter },
      }) as any,
    create: ({ values }) =>
      request.post(`/companies/favorites/users`, values) as any,
    delete: ({ userId }) =>
      request.delete(`/companies/favorites/users/${userId}`) as any,
  },
};
