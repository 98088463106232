import styled from "styled-components";

export const FilledButton = styled.button.attrs({})`
  background: ${({ theme: { colors } }) => colors.primary};
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.200577);
  border-radius: 2.4px;
  width: 278px;
  height: 56px;
  color: #ffffff;
  font-weight: 800;
  font-size: 16px;
  padding: 0.5em;
  line-height: 22px;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #E5ADA;
    box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.200577);
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const SecondaryFilledButton = styled.button.attrs({})`
  background: ${({ theme: { colors } }) => colors.red};
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.200577);
  border-radius: 2.4px;
  width: 278px;
  height: 56px;
  color: #ffffff;
  font-weight: 800;
  font-size: 16px;
  padding: 0.5em;
  line-height: 22px;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #E5ADA;
    box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.200577);
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const Button = styled.button.attrs({})`
${props => ({ theme: { colors, shadows } }) =>
  props.color
    ? `
  background: ${colors[props.color]};
  box-shadow: 0px 5.40984px 10.8197px ${shadows[props.color]};
  &:hover {
    background: #E5ADA;
    box-shadow: 5px 0px 10px ${shadows[props.color]};
  }
  `
    : `
    &:hover {
      background: #E5ADA;
      box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.200577);
    }
  `}

  border-radius: ${props => ({ theme: { rounded } }) =>
    props.rounded ? rounded[props.rounded || "base"] : rounded["base"]}
  width: 100%;
  height: 56px;
  color: #ffffff;
  font-weight: 800;
  font-size: 16px;
  padding: 0.5em;
  line-height: 22px;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const TextButton = styled.button.attrs(props => ({
  className: `mt-1 text-grey text-sm focus:outline-none hover:underline ${props.className}`
}))``;
