import { useState } from "react";
import { companyApis } from "lib/apis/companies";
import { find } from "lodash";
import useUser from "../useUser";

const useWalletCards = () => {
  const { company } = useUser();
  const [cards, setCards] = useState(Array) as any;
  const [totalCards, setTotalCards] = useState(Number);

  const getCompanyWalletCards = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await companyApis.wallet.cards.list();
        resolve(data);
      } catch (e: any) {
        reject(e);
      }
    });
  };

  const getCardCheckoutId = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await companyApis.wallet.cards.checkoutId();
        resolve(data);
      } catch (e: any) {
        reject(e);
      }
    });
  };

  const createCreditCard = ({ checkoutId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await companyApis.wallet.cards.create({
          values: { checkoutId },
        });
        resolve(data);
      } catch (e: any) {
        reject(e);
      }
    });
  };

  const deleteWalletCard = ({ cardId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await companyApis.wallet.cards.delete({
          cardId,
        });
        resolve(data);
      } catch (e: any) {
        reject(e);
      }
    });
  };

  const fetchWalletCards = async () => {
    const { walletCards, count } = (await getCompanyWalletCards()) as any;
    setTotalCards(count);
    setCards(walletCards);
  };

  const getCreditCard = (creditCardId) => {
    const creditCard = find(cards, { id: creditCardId });
    return creditCard;
  };

  const getDefaultCreditCard = () => {
    const defaultCreditCard = find(cards, { isPrimary: true }) as any;
    return defaultCreditCard;
  };

  return {
    fetchWalletCards,
    totalCards,
    cards,
    getCardCheckoutId,
    createCreditCard,
    getDefaultCreditCard,
    deleteWalletCard,
    getCreditCard,
  };
};

export default useWalletCards;
