export enum ActionTypes {
  SET_USER = "SET_USER",
  SET_COMPANY = "SET_COMPANY",
  SET_SETTINGS = "SET_SETTINGS",
  SET_JOB_OFFER = "SET_JOB_OFFER",
  SET_JOB_TYPE = "SET_JOB_TYPE",
  SET_INDUSTRIES = "SET_INDUSTRIES",
  SET_COUNTRIES = "SET_COUNTRIES",
  SET_CURRENCIES = "SET_CURRENCIES",
  SET_JOB_TITLES = "SET_JOB_TITLES",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_GIGS_SUBCATEGORIES = "SET_GIGS_SUBCATEGORIES",
  SET_ACTION_PRICES = "SET_ACTION_PRICES",
  SET_PHONE = "SET_PHONE",
  SET_CODE = "SET_CODE",
  SET_OTP = "SET_OTP",
  SET_PASSWORD = "SET_PASSWORD",
  SET_PROFILE = "SET_PROFILE",
  SET_GUEST_USER = "SET_GUEST_USER",
  SET_GUEST_TOKEN = "SET_GUEST_TOKEN",
  SET_COMPANY_FORM = "SET_COMPANY_FORM",
  SET_COMPANY_PROFILE = "SET_COMPANY_PROFILE",
  SET_OTP_CALLBACK = "SET_OTP_CALLBACK",
  SET_LOCATION_CALLBACK = "SET_LOCATION_CALLBACK",
  SET_NATIONALITIES = "SET_NATIONALITIES",
  SET_GIG_OFFER = "SET_GIG_OFFER",
  RESET = "RESET",
}
