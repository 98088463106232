import { isNull } from "lodash";
import request from "axios";
import { StorageKeys } from "../../constants/storageKeys";

export const isLoggedIn = () => {
  let token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  return !isNull(token);
};

export const storeUserSession = (token) => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  request.defaults.headers["Authorization"] = token;
};

export const removeUserSession = () => {
  localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
  request.defaults.headers["Authorization"] = "";
};
