import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";
import { FilledButton } from "shared/components/ButtonGroups";
import Image from "shared/components/Image";
import useUser from "lib/hooks/useUser";
import Modal from "shared/components/Modal";
import { OTP_SENT_ICON } from "theme/assetsConfiguration";
import OtpForm from "react-otp-form";
import { OTP_LENGTH } from "lib/constants/general";
import useLocales from "lib/hooks/useLocales";
import InputErrorBlock from "shared/components/InputErrorBlock";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { SimpleLoader } from "shared/components/Loader";

const VerifyMobileNumber = () => {
  const locales = useLocales("completeProfile.verifyMobileNumber");

  const [isModalOpen, setIsModalOpen] = useState(Boolean);
  const { user, sendOtp, verifyOtp, rehydrateUser } = useUser();
  const [loading, setLoading] = useState(Boolean);
  const [sendOtpError, setSendOtpError] = useState(String);
  const [verifyOtpError, setVerifyOtpError] = useState(String);
  const [result, setResult] = useState(String);
  const onVerifyClick = () => {
    setLoading(true);
    sendOtp(
      null,
      (onSuccess) => {
        setLoading(false);
        toggleModal();
      },
      (error) => {
        setSendOtpError(error?.message);
        setLoading(false);
      }
    );
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleOTP = (value) => {
    if (value?.otpString?.length === OTP_LENGTH) {
      setLoading(true);
      verifyOtp(
        value?.otpString,
        () => {
          setVerifyOtpError("");
          setLoading(false);
          setResult(locales?.successMessage);
          setTimeout(() => {
            rehydrateUser();
          }, 3000);
        },
        () => {
          setLoading(false);
          setVerifyOtpError(locales?.errorMessage);
          setResult("");
        }
      );
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="flex-1 flex items-start">
          <div className="w-10 h-10 rounded-full flex items-center justify-center p-3 bg-primary mt-1">
            <FaPhone size={30} className="text-white" />
          </div>
          <div className="mx-4 flex-1">
            <h1 className="text-xl">{locales.title}</h1>
            <h2 className="text-base mt-2">{locales.subTitle}</h2>
            {loading && !isModalOpen && <SimpleLoader size="small" />}
            {sendOtpError && (
              <InputErrorBlock
                rootClassName="flex items-center text-xl mt-2"
                icon={<IoIosInformationCircleOutline className="opacity-75" />}
                errorMessage={sendOtpError}
                className="text-sm"
              />
            )}
          </div>
        </div>
        <div className="ml-auto">
          <FilledButton onClick={onVerifyClick} className="h-10 w-32">
            {locales.action}
          </FilledButton>
        </div>
      </div>
      {isModalOpen ? (
        <Modal width="35%" open={isModalOpen} close={toggleModal}>
          <div className="flex flex-col items-center justify-center my-8 md:p-5">
            <div className="w-20 h-20 overflow-hidden rounded-full">
              <Image image={OTP_SENT_ICON} alt="OTP Sent icon" />
            </div>
            <h1 className="text-base font-avenir-medium leading-none my-6 text-center">
              {locales.verificationCodeSent}
              <span className="block text-center mt-2 text-primary">
                {user?.mobileNumber}
              </span>
            </h1>
            {!result ? (
              <>
                <OtpForm
                  numberOfInputs={4}
                  showOtp={true}
                  handleOnChange={handleOTP}
                />
                {verifyOtpError && (
                  <InputErrorBlock
                    rootClassName="flex items-center text-xl mt-2"
                    icon={
                      <IoIosInformationCircleOutline className="opacity-75" />
                    }
                    errorMessage={verifyOtpError}
                    className="text-sm"
                  />
                )}
              </>
            ) : (
              <span className="block text-center">{result}</span>
            )}
            {loading && <SimpleLoader size="small" />}
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default VerifyMobileNumber;
