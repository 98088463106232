import React, { useState } from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import Modal from "../Modal";
import QRCodeGenerator from "qrcode.react";
import Image from "../Image";
import useLocales from "lib/hooks/useLocales";
import { IoIosInformationCircleOutline } from "react-icons/io";

const QRCode = ({ value, logo, title }: { value, logo?, title }) => {
  const locales = useLocales("qrCode");
  const [openModal, setOpenModal] = useState(Boolean);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <AiOutlineQrcode
        className="text-3xl text-grey cursor-pointer"
        onClick={toggleModal}
      />
      {openModal ? (
        <Modal width="35%" open={openModal} close={toggleModal}>
          <div className="flex flex-col items-center justify-center my-8 md:p-5">
            {
              !!logo && <div className="w-16 h-16 rounded-lg shadow-lg">
              <Image
                className="w-16 h-16 rounded-lg"
                image={logo}
                alt="Company Logo"
              />
            </div>
            }
            {/* <h1 className="text-base font-avenir-medium leading-none mt-4">{title}</h1> */}
            <div className="flex flex-col items-center my-6">
              <div className="w-10 h-10">
                <IoIosInformationCircleOutline
                  className="text-primary"
                  size="100%"
                />
              </div>
              <span className="flex-1 inline-block ml-1 text-sm text-center">
                {locales.description}
              </span>
            </div>

            <div>
              <QRCodeGenerator value={value} size={200} renderAs="png" />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default QRCode;
