const en = {
  shared: {
    phone: "Phone Number",
    commingSoon: "Coming Soon!",
    search: "Search",
    jod: "JOD",
    hours: "Hour(s)",
    minutes: "Minute(s)",
    totalHours: "Total hours",
    all: "All",
    locationSearch: "Search for a place",
    yes: "Yes",
    cancel: "Cancel",
    location: "Location: ",
    submit: "Submit",
    from: "From",
    to: "to",
    priceRange: "Around",
    priceRangePlaceholder:
      "The estimate price will be available once all fields are filled",
    password: "Password",
    addCard: "Add card",
    changeCard: "Change card",
    jobs: "Jobs",
    spending: "Cost",
    viewAll: "View all",
    costHour: "Cost x hour",
    timing: "Timings",
    avgPricePerHour: "Average price/hour",
    addNewAddress: "Add New",
    save: "Save",
    changeImage: "Change image",
    days: "Day(s)",
    viewFullProfile: "View Full Profile",
    downloadCSV: "Download CSV",
  },
  login: {
    onBoarding: {
      title: "Get matched with the <br /> best talents today",
      subTitle:
        "Kader provides you with a hassle free hiring process. From screening, to hiring to supervision and payment; Kader ensures a flawless end to end solution for all your short term employment needs",
    },
    welcome: "Hiring has never been easier, <br /> register today!",
    forgotPassword: "Forgot password?",
    forgotEmail: "Forgot email?",
    hire: "Sign in",
    // singup: "You don't have an account?",
    singup: "Register now",
    resetPassword: "Let’s Reset Your Password",
    enterMobile: "Enter your mobile number so we can reset your password",
    enterEmail: "Enter your email adders so we can reset your password",
    resetPasswordSubtitle: "It’s ok everyone forgets sometimes",
    resetTitle: "Reset Password",
    loginForm: {
      phone: "Phone Number",
      email: "Email",
      password: "Password",
      validation: {
        "user_already_exists.": "User already exists.",
        "the_given_data_was_invalid.": "The given data was invalid.",
      },
    },
    resetPasswordForm: {
      phone: "Phone Number",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      rules: `
      <li>- Your password must at least be 8 characters</li>
      <li>- Never use your name or number as your password</li>`,
      errors: {
        incorrect: "Wrong verification code",
      },
      messages: {
        doneSuccessfully: "Your password changed successfully",
        doneSuccessfullySubtitle:
          "You will be redirected to the login screen shortly",
        emailSent: "A reset password link sent to your email",
        emailSentSubtitle:
          "Check your email please, you will be redirected to the login screen shortly",
      },
      actions: {
        submit: "Submit",
      },
    },
    confirmAccount: "Confirm Your Account",
    confirmSubtitle:
      "You are one step away! Please enter the verification code sent to your mobile number to proceed.",
    resentCode: "Resend Code",
    smsVerify: "SMS Verification code has been sent to",
    faildReceiveCode: "Didn’t receive code?",
    support: "Need help?",
    terms: "Terms & Conditions",
  },
  register: {
    register: "Register",
    update: "Update",
    haveAccount: "Already have an account?",
    sms: "SMS Verification code has been sent to",
    oneStepAway:
      "You are one step away! Please enter the verification code sent to your mobile number to proceed.",
    resend: "Resend Code",
    form: {
      fields: {
        firstName: {
          label: "First Name",
        },
        lastName: {
          label: "Last Name",
        },
        jobTitle: {
          label: "Job Title",
        },
        email: {
          label: "Email",
        },
        password: {
          label: "Password",
        },
        confirmPassword: {
          label: "Confirm password",
        },
        gender: {
          label: "Gender",
        },
        nationality: {
          label: "Nationality",
        },
        dateOfBirth: {
          label: "Date of birth",
        },
        company: {
          title: { label: "Company Name" },
          description: { label: "Company Description" },
          industry: { label: "Company Industry" },
          numberOfBranches: { label: "Number of Branches" },
          employeesRange: { label: "Employees Range" },
          country: { label: "Select Country" },
        },
      },
    },
    messages: {
      emailSent: "Success",
      emailSentSubtitle:
        "A verification link sent to your email, you will be redirected to the login screen shortly",
    },
  },
  profileForm: {
    title: "Tell us about yourself",
    fields: {
      image: "Upload Profile Picture",
      description: "Description",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
    },
    actions: {
      submit: "Next",
    },
    validation: {
      email: "The email has already been taken.",
    },
    createPassword: {
      title: "Let’s Create Your Password",
      rules: `
      <li>- Your password must at least be 8 characters</li>`,
      fields: {
        password: "Password",
        confirmPassword: "Confirm Password",
      },
      note: "Don't be in a hurry!",
      actions: {
        submit: "Next",
      },
    },
    companyProfile: {
      title: "Set up your company profile",
      editTitle: "Edit your company profile",
      fields: {
        image: "Upload Company Logo",
        description: "Description",
        name: "Company name",
        industry: "Select Industry",
        country: "Select Country",
        size: "Company size",
        branches: "Company branches",
      },
      actions: {
        submit: "Next",
      },
    },
    companyLocation: {
      search: "Search for a place",
      cancel: "Cancel",
      location: "Location: ",
      submit: "Submit",
    },
  },
  header: {
    myJobs: "Jobs",
    myGigs: "Bookings",
    messages: "Messages",
    cv: "CV Search",
    // postJob: "Book quality staff",
    postJob: "Add New Job",
    postGig: "Post a Gig",
    language: "عربي",
    company: "Profile",
    logout: "Logout",
    plans: "Plans",
  },
  sidebar: {
    dashboard: "Dashboard",
    jobs: "Jobs",
    favorite: "Kaderos",
    topKaderos: "Top Kaderos",
    shifts_logs: "Shifts Logs",
    profile: "Profile",
    company: "Company",
    balance: "Balance",
    settings: "Settings",
    logout: "Logout",
    surveys: "Surveys",
  },
  jobs: {
    title: "Jobs",
    pending: "Pending",
    active: "Active",
    paused: "Paused",
    closed: "Closed",
    rejected: "Rejected",
    shortlisted: "Shortlisted",
    distance: (km) => `${km} km away`,
    jobCard: {
      details: "View Job Details",
      activate: "Activate",
      close: "Close",
      pause: "Pause",
      share: "Share Job",
      shortlisted: "Shortlisted",
      applicants: "Applicants",
      pending: "Pending",
      rejected: "Rejected",
      suggested: "Suggested",
      requests: "Interviews Requested",
      kaderos: (kaderos) => `${kaderos} Kaderos`,
      matched: "matched candidates",
      actions: {
        view: "View",
        activate: "Activate",
        close: "Close",
        pause: "Pause",
        share: "Share Job",
        rePost: "Re-post",
      },
    },
    jobInfo: {
      summary: "Job summary",
      requirements: "Requirements",
      experience: "Experience",
      years: "Years",
      salary: "Salary",
      gender: "Gender",
      applicants: "Applicants",
      noApplicants: (jobType) =>
        `There are no new applicants, please check again later.`,
      actions: {
        shortlist: "Shortlist",
        reject: "Reject",
        viewNumber: "Click to view number ",
        call: "Call ",
      },
      at: "at",
      to: "to",
      present: "Present",
      jod: "JOD",
      usd: "USD",
    },
    closeJob: {
      feedBack: {
        closureReason: {
          title: "Why do you want to close the job offer?",
          filled: "I've filled this position",
          notAvailable: "This job is no longer available",
          notRelevant: "Did not find the right candidate for the job",
        },
        closureReasonDetails: {
          title: "How did you fill this position?",
          kader: "Kader",
          facebook: "Facebook",
          worldOfMouth: "Word of mouth",
          newspaper: "Newspaper",
          other: "Other",
        },
        submit: "Submit",
      },
    },
  },
  bookings: {
    title: "Bookings",
    empty: {
      title: "No Booking posted yet",
      action: "Book your staff",
    },
    details: "View Booking Details",
    postedAt: "Posted At ",
    startDate: "Starts At ",
    endDate: "Ends At ",
    duration: (count) => (count > 1 ? "Days" : "Day"),
  },
  company: {
    profile: "Company Profile",
    location: "Location",
    userProfile: "Profile",
    configuration: "Configuration",
    pushNotifications: "Push Notifications",
    edit: "Edit",
    changePassword: "Change Password",
    credit: "Credit balance: ",
    submitButton: "Save changes",
    changeImage: "Change Image",
    fields: {
      companyName: "Company Name",
      companyDescription: "Company Description",
      companyIndustry: "Company Industry",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone Number",
      email: "Email",
    },
    addresses: {
      title: "Addresses",
      addressesForm: {
        title: "New Address",
        fields: {
          title: {
            label: "Address Title",
          },
          street: {
            label: "Street",
          },
          area: {
            label: "Area",
          },
          building: {
            label: "Building number",
          },
          instructions: {
            label: "Instructions",
          },
        },
        actions: {
          save: "Save",
        },
      },
      newAddress: "New address",
      emptyAddresses: "There are no addresses",
      instructions: "Instructions",
      deleteAlert: {
        title: "Are you sure you want to delete this address?",
        action: "Yes",
        reject: "No",
      },
    },
    recruiters: {
      title: "Recruiters",
      addNew: "New Recruiter",
    },
    statistics: {
      title: "Jobs dashboard",
      averageRating: "Rating",
      completedJobs: "Completed Jobs",
      totalNumberOfHours: "Total Hours",
    },
    balance: {
      title: "Balance",
      addCreditCard: "Add Credit Card",
      cards: {
        title: "Cards",
        addCard: "Add card",
        columns: {
          holder: "Card Holder",
          expiry: "Expiry",
          last4digits: "Last 4 digits",
          brand: "Brand",
          actions: "Actions",
        },
      },
      transactions: {
        title: "Transactions",
        empty: "There are no transactions",
        payPending: "Pay",
      },
    },
  },
  applicant: {
    credits: "Credits",
    experience: "Experience:",
    actions: {
      shortlist: "Shortlist",
      reject: "Reject",
      viewNumber: "Click to view number ",
      call: "Call ",
      requestInterview: "Request an interview",
    },
    at: "at",
    to: "to ",
    present: "Present",
    bio: "Bio",
    experienceTitle: "Experience",
    education: "Education",
    language: "Language",
    noApplicants: (jobType) =>
      `There are no new applicants, please check again later.`,
    distance: (km) => `${km} km away`,
    noExperience: "No Experience",
    noBio: "No bio",
    year: "Year",
    month: "Month",
  },
  filters: {
    title: "Filters",
    select: "Select...",
    education: "Education",
    experience: "Work experience",
    nationality: "Nationality",
    gender: "Gender",
    any: "Any",
    male: "Male",
    female: "Female",
  },
  plans: {
    title: "Choose your plan",
    choose: "Choose",
    jod: "JOD",
    connections: "Credits: ",
    confirmationModal: {
      title: "Are you sure you want to purchase this bundle?",
      subTitle: "By clicking yes, you are agreeing to pay ",
      subTitle2: "One of our team members will contact you soon.",
      actions: {
        yes: "Yes",
        no: "No",
      },
    },
    successModal: {
      title: "Process completed successfully",
      subTitle: "One of our team members will contact you soon.",
      done: "Thanks",
    },
  },
  postJob: {
    stepper: {
      jobDescription: "Job Description",
      requirements: "Requirements",
      location: "Job Location",
    },
    category: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Select Your Job Category",
      required: "You must select a Category before you proceed",
      actions: {
        submit: "Next",
      },
    },
    titles: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Select Your Job Title",
      required: "You must select a Job title before you proceed",
      actions: {
        submit: "Next",
        search: "Search titles",
      },
    },
    jobDescription: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Describe Your Job Offer",
      form: {
        fields: {
          description:
            "Looking  for an energetic candidate to work with us from 8 am till 5 pm, applicant must have good communication and Microsoft skills.",
          jobType: "Employment Type",
          shift: "Shift",
          salary: "Salary",
          card: "Credit card",
          title: "Tom's weading, Sara's birthday ... etc",
        },
        actions: {
          submit: "Job requirements",
        },
        morning: "Morning",
        afternoon: "Afternoon",
        night: "Night",
        internship: "Internship",
        fullTime: "Full Time",
        partTime: "Part Time",
      },
    },
    jobRequirements: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Fill Your Job Requirements",
      form: {
        fields: {
          experience: "Experience",
          minEducation: "Minimum education level",
          gender: "Gender",
        },
        year: "Year",
        actions: {
          submit: "Mark job location",
        },
      },
    },
    jobLocation: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Set Job Location",
      actions: {
        submit: "Post a Job Offer",
      },
    },
  },
  postGig: {
    category: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Select Your Gig Category",
      required: "You must select a Category before you proceed",
      actions: {
        submit: "Next",
      },
    },
    jobDescription: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: (step) => (step === 1 ? "Booking details" : "Payment details"),
      lastStep: "Last step",
      form: {
        fields: {
          schedule: "Schedule your booking",
          location: "Job Location",
          timeSlots: "Add time/date slot",
          requirements: "Additional requirements (Optional)",
          description:
            "e.x must wear white shirt and black shoes. The work environment is excellent with great team spirit.",
          date: "to work from",
          lookingFor: "I am looking for",
          on: "On",
          staff: "How many employees are needed?",
          startTime: "Start Time",
          endTime: "End Time",
          insurance: "Do you wish to add insurance to this job?",
          insuranceSubTitle:
            "Kaderos are insured against personal accidents while on the job.",
          title: "Tom's weading, Sara's birthday ... etc",
          titleLabel: "Name Your Job",
          gender: "Gender",
          jobPreference: "Job Preference",
          survey: {
            title: "Add survey (Optional)",
            attach: "Attach survey",
            change: "Change survey",
          },
        },
        jod: "JOD",
        actions: {
          next: "Next",
          submit: "Submit Job Request",
          submitAndDuplicate: "Submit and create another one",
          done: "Done",
          cancel: "Cancle",
          add: "Add",
          save: "Save",
        },
        timeSlot: {
          insurance: "With insurance",
          edit: "Edit",
          note: "On the next day",
        },
        errors: {
          duration: "Booking should start after 12 hours from the posted time",
        },
      },
      paymentSection: {
        title: "Payment",
        balance: "Use balance first",
        cards: "Credit card",
        selectedCreditCard: "Selected credit card",
        cashOrChek: "Cash or Chek",
        bookingTotal: "Total",
        taxTotal: "Tax Total",
        insuranceTotal: "Insurance Total",
        total: "Total",
        submit: "Save",
        balanceShort: "Balance",
      },
      edit: {
        success: "Time slot updated successfully",
      },
    },
    jobLocation: {
      stepTitle: (current, total) => `Step ${current} out of ${total}`,
      title: "Set booking location",
      actions: {
        submit: "Post a Job Offer",
      },
    },
    repost: {
      title: "Would you like to repost an old booking?",
      repost: "Re-post",
      modal: {
        title: "Repost booking",
        repostBookingCard: {
          view: "View",
          repost: "Re-post",
        },
        viewAll: "View All",
      },
    },
  },
  insufficient: {
    title: "You're out of Kader Credits!",
    subTitle: "To make a connection, you need to purchase a bundle",
    submit: "Purchase Credits",
  },
  noJobs: {
    title: "No Posted Jobs",
    subTitle: "No jobs posted",
    button: "Post a Job",
  },
  newJob: {
    title: "New Job",
    jobOffer: {
      title: "Post a job",
      description:
        "Find the best candidate for your business among a pool of candidates. Connect directly with applicants through our flexible credit model",
    },
    booking: {
      title: "Book quality staff on hourly basis",
      description:
        "Looking for temporary staff to cover a shift? Usher an event? Our pre-vetted and quality Kaders got you covered",
      action: "Book quality staff",
    },
    hireing: {
      title: "We hire for you!",
      description:
        "Sit back, relax and let us find the right candidate for your business. We handle all the process from screening, interviewing and background checks temporary staff to cover a shift? Usher an event? Our pre-vetted and quality Kaders got you covered",
    },
    actions: {
      next: "Next",
      back: "Back",
    },
    success: {
      title: "We’re matching you with qualified Kaderos workers.",
      back: "Back to Dashboard",
      addNew: "Add New Job",
    },
  },
  editJob: {
    title: "Edit Job",
    deleteModal: {
      title: "Are you sure you want to delete this job?",
      subTitle: "Once deleted, you will not be able to undo.",
      cancel: "Cancel",
      ok: "Yes. I'am sure!",
    },
  },
  repostJob: {
    title: "Repost job",
  },
  gigView: {
    matched: "Matched candidates",
    matching: "Matching candidates",
    matchingSubTitle: "We are handpicking the best viable candidate for you",
    start: "Start",
    end: "End",
    gigAddress: {
      title: "Gig Address",
    },
    notes: {
      title: "Aditional Notes:",
      placeholder: "Type here...",
    },
    gigWalletCard: {
      title: "Gig Credit Card",
      emptyWallet: {
        title: "This booking is not attached to a credit card",
        action: "Add Credit Card",
      },
      changeCard: "Change card",
    },
    attendances: {
      title: "Kaderos",
    },
    attendance: {
      delete: {
        title: "Are you sure you want to delet this day?",
        ok: "Yes",
        cancel: "Cancel",
      },
      startTime: "Start Time",
      endTime: "End Time",
      arriveTime: "Arrive Time",
      leaveTime: "Leave Time",
      titles: "Job Titles",
      price: "Price per hour",
      duration: "Duration",
      hours: "Hours",
      workingHours: "Working hours",
      totalAmount: "Total Amount",
      day: " day",
      insurancePricePerHour: "Insurance Price Per Hour",
      rating: {
        title: (value) =>
          `How would you rate the attendance of this day ${value} ?`,
        reason: "Could you tell us why?",
        other: "Could you please provide us the other reason",
        submit: "Submit",
        submitted: "Attendance rating submitted",
      },
      callClick: {
        directContact: "Direct contact",
        whatsApp: "WhatsApp",
        view: "View",
      },
      noUser: "Matching users...",
      empty: "There are no attendances",
    },
  },
  user: {
    bio: "Bio",
    titles: "Job titles",
    experience: "Experience",
    education: "Education",
    noEducation: "No Education",
    languages: "Languages",
  },
  success: {
    job: "Job Posted",
    gig: "Matching you with qualified Kaderos in progress",
    action: "Done",
    none: "",
  },
  home: {
    myJobs: "Recent Jobs",
    overview: "Overview",
    shiftsInfo: "Shifts Info",
    jobs: "Jobs",
    gigs: "Bookings",
    repost: "Repost Job",
  },
  verification: {
    email: {
      title: "",
      loading: "Please wait we are verifying your email address...",
      verified: "Your email now is verified",
      redirect: "Login",
    },
  },
  forgotEmail: {
    title: "Verify your mobile number",
    resetTitle: "Enter your new email address",
    sendCode: "Send verification code",
    resetEmail: "Reset",
    seconds: "Seconds",
    messages: {
      emailSent: "A verification link sent to your email",
      emailSentSubtitle:
        "Check your email please, you will be redirected to the login screen shortly",
    },
  },
  completeProfile: {
    verifyMobileNumber: {
      title: "Account update required",
      subTitle: "Please verify your mobile number",
      action: "Verify",
      successMessage: "Your phone number now is verified",
      errorMessage: "Wrong code",
      verificationCodeSent: "SMS Verification code has been sent to",
    },
    verifyEmailAddress: {
      title: "Account update required",
      subTitle:
        "Please verify your email address, a verification link sent to your email.",
      action: "Verify",
      successMessage: "A verification link sent to your email",
    },
  },
  recruiterProfile: {
    country: "Country",
    nationality: "Nationality",
    emptyLocation: "Add your location",
    location: "Location",
    gender: "Gender",
    empty: (text) => `Add your ${text}`,
    editProfile: "Edit your information",
    mobileNumber: "Mobile Number",
    email: "Email address",
    emailSent:
      "A verification link sent to your email, please verify your new email.",
    updateMobile: {
      title: "Update your mobile number",
      form: {
        fields: {
          phoneNumber: {
            label: "Phone number",
          },
          password: {
            label: "Password",
          },
        },
      },
    },
  },
  profile: {
    sideBar: {
      profile: "Profile",
      company: "Company",
      bookings: "Dashboard",
      wallet: "Balance",
    },
  },
  companyProfile: {
    employeesRange: "Employees Range",
    industry: "Industry",
    numberOfBranches: "Branches",
  },
  qrCode: {
    title: "Company QR Code",
    description:
      "This is a unique QR code for your to keep safe. Kaderos punch in and out by scanning this QR code prior to the start and end of each job.",
  },
  currencies: {
    JOD: "JOD",
    SAR: "SAR",
  },
  creditCardRedirect: {
    loading: "Please wait we are creating your card...",
    success: "Your card created successfully",
    subTitle: "We will redirect you after 5s",
  },
  payPendingTransactions: {
    title: "Pay pending transactions",
    balance: "Use balance first",
    cards: "Credit card",
    selectedCreditCard: "Selected credit card",
    cashOrChek: "Cash or Chek",
    bookingTotal: "Total",
    taxTotal: "Tax Total",
    insuranceTotal: "Insurance Total",
    total: "Total",
    submit: "Pay",
  },
  creditCardForm: {
    create: {
      title: "Add credit card",
      submit: "Submit",
    },
    edit: {
      title: "Change default credit card",
      submit: "Change card",
    },
    delete: {
      alert: "Are you sure?",
      yes: "Yes",
      no: "No",
    },
  },
  willContactYouSoon: {
    title: "Our customer support team will contact you shortly",
  },
  inviteRecruiter: {
    createTitle: "Create New Recruiter",
    editTitle: "Edit Recruiter",
    form: {
      fields: {
        firstName: {
          label: "First Name",
        },
        lastName: {
          label: "Last Name",
        },
        email: {
          label: "Email Address",
        },
        phone: {
          label: "Mobile number",
        },
        password: {
          label: "Password",
        },
        jobTitle: {
          label: "Job Title",
        },
        role: {
          label: "Role",
        },
      },
      actions: {
        submit: "Send Invite",
        edit: "Update recruiter",
      },
    },
    created: {
      title: "Invitation link was sent to",
      action: "Thanks",
    },
  },
  surveyBuilder: {
    title: "Survey Builder",
    form: {
      fields: {
        title: {
          label: "Title",
          placeholder: "Survey title",
        },
        description: {
          label: "Description",
          placeholder: "Survey description",
        },
        questions: {
          label: "Survey Questions",
          questionHeader: (index) => `Question number ${index}`,
          fields: {
            title: {
              label: "Title",
              placeholder: "Title",
            },
            description: {
              label: "Description",
              placeholder: "Description",
            },
            type: {
              label: "Type",
              placeholder: "Type",
            },
            logic: {
              label: "Choose Question",
              placeholder: "Choose Question",
            },
            options: {
              label: "Options",
              optionPlaceholder: "Option",
              other: "Other",
              addOption: "Add Option",
              or: " or ",
              addOther: "Add other",
            },
            isRequired: {
              label: "Is required",
            },
            allowOther: {
              label: "Is this question has another answer",
            },
          },
        },
        actions: {
          sort: "Sort Questions",
          stopSprting: "Finish Sorting",
          sortDescription:
            "You can change the sort of questions by Dragging and Dropping the question card.",
        },
      },
      actions: {
        submit: "Save",
        cancel: "Cancel",
        addAnotherQuestion: "Add another question",
        addQuestion: "Add question",
        addLogic: "Logic",
      },
    },
    deleteAlert: {
      title: "Are you sure you want to delete this question?",
      yes: "Yes",
      no: "No",
    },
  },
  surveyScreen: {
    downloadTitle: "Download survey as CSV",
    download: "Download",
    responses: "Responses",
    downloadModal: {
      title: "Download file as",
      fileTypes: {
        xls: "EXCEL File",
        csv: "CSV File",
      },
      action: "Download",
    },
    submittedAt: "Submitted at",
    numberOf: "of",
  },
  notFoundScreen: {
    title: "Sorry this survey is not found",
  },
  surveys: {
    title: "Surveys",
    attachSurveyModal: {
      title: "Your surveys list",
      attach: "Attach",
    },
  },
  favorites: {
    title: "Kaderos",
    unFavourite: {
      title:
        "Are you sure you want to remove this kadero from your Kaderos list?",
    },
  },
  topPerforming: {
    title: "Top Performing Kaderos",
    empty: "No top performing kaderos yet",
  },
  shiftsLogs: {
    title: "Shifts Logs",
    table: {
      columns: {
        name: "Name",
        title: "Job title",
        location: "Location",
        dateOfAttendance: "Date of Attendance",
        clockIn: "Clock In",
        clockOut: "Clock Out",
        totalHours: "Total Hours",
        rating: "Rating",
      },
    },
  },
};

export default en;
