import React from "react";
import RegisterationHeader from "shared/components/NeutralHeader";
import SupportFooter from "shared/components/SupportFooter";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import useSiteLanguages from "lib/hooks/useSiteLanguages";
import { siteLanguages } from "lib/constants/general";
import {
  WideInnerWrapper,
  WideOutterWrapper,
} from "theme/custom/RegisterWrapper";

function RegisterLayout(props) {
  const { language } = useSiteLanguages();
  const handleGoBack = () => props.history.goBack();
  return (
    <div>
      <RegisterationHeader />
      <div className="p-10 px-6 md:px-16 ">
        <div
          className="w-10 h-10 bg-black flex items-center justify-center rounded cursor-pointer"
          onClick={handleGoBack}
        >
          {language === siteLanguages.AR.value ? (
            <IoIosArrowForward className="text-white text-xl" />
          ) : (
            <IoIosArrowBack className="text-white text-xl" />
          )}
        </div>
      </div>
      <WideOutterWrapper>
        <WideInnerWrapper>
          {props.children}
          <div className="pt-2">
            <SupportFooter />
          </div>
        </WideInnerWrapper>
      </WideOutterWrapper>
    </div>
  );
}
export default RegisterLayout;
