import React from "react";
import styled from "styled-components";
import Logo from "../../Logo";

import { Link } from "react-router-dom";

import useSiteLanguages from "lib/hooks/useSiteLanguages";
import useLocales from "lib/hooks/useLocales";
import ProfileDropDown from "../ProfileDropDown";
import { getLang } from "lib/services/LocalStorage";
import useUser from "lib/hooks/useUser";
import useRoles from "lib/hooks/useRoles";
import { Resources, ResourcesActions } from "lib/constants/general";
import { routesConstants } from "lib/constants/urls";
// import Svg from "shared/components/Svg";
import assetsConfig from "theme/assetsConfiguration";
import { ReactComponent as PlusIcon } from "assets/icons/svg/plus.svg";
import LanguageToggler from "./LanguageToggler";
import QRCode from "shared/components/QRCode";
// import QRCode from "shared/components/QRCode";

interface Props {
  isSelected;
  company;
  logout;
}

const IconWrapper = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  color: #fff;
  background: ${({ theme: { colors } }) => colors.primary};
`;

const NewJobButton = styled.button.attrs({})`
  display: flex;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.solitude};
  color: white;
  transition: all 0.4s;
  padding: 10px;
  margin-left: 13px;
  margin-right: 13px;
  border-radius: 12px;
`;

const HeaderWrapper = styled.div.attrs({})`
  border: 1px solid #ebecef;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const LargeBar = (props: Props) => {
  // const { changeLanguage } = useSiteLanguages();
  const header = useLocales("header") as any;

  const { company, user } = useUser();
  const { hasPermission } = useRoles();

  // const handleChangeLanguage = () => {
  //   changeLanguage();
  //   window.location.reload();
  // };

  return (
    <HeaderWrapper className="flex px-3 md:px-16">
      <div className="flex-1 flex items-center">
        <Link to="/dashboard">
          <Logo imageProps={{ className: "w-32" }} />
        </Link>
        {hasPermission(user?.role, Resources.GIGS, ResourcesActions.CREATE) && (
          <Link
            to={`${
              company?.id
                ? routesConstants.NEW_JOB
                : routesConstants.COMPANY_PROFILE
            }`}
            className="ml-24"
          >
            <NewJobButton className="w-full">
              {/* <IoMdAddCircle size={30} className="text-primary" /> */}
              <IconWrapper>
                <PlusIcon className="fill-current" />
              </IconWrapper>
              <h1 className="ml-2 text-primary font-avenir-medium">
                {header.postJob}
              </h1>
            </NewJobButton>
          </Link>
        )}
      </div>
      <div className="flex justify-end items-center pr-2">
        <div className="shadow rounded">
          <QRCode value={company?.qrCode} title="Company " />
        </div>
        <div className="mx-2 w-0.5 h-7 bg-primary opacity-50" />
        <LanguageToggler />

        <ProfileDropDown
          company={props.company}
          logout={() => props.logout()}
        />
      </div>
    </HeaderWrapper>
  );
};

export default LargeBar;
