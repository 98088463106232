import React from "react";
// import styles from "./SimpleLoader.module.scss";

interface SimpleLoaderProps {
  size?;
}

const SimpleLoader = (props: SimpleLoaderProps) => {
  const { size } = props;
  return (
    <div className={`simple-loader ${size ? `simple-loader__${size}` : ""}`} />
  );
};

export default SimpleLoader;
