import request from ".";

export const surveysApis = {
  list: (filters?) => request.post("/compaines/surveys/list", filters) as any,
  create: (values) => request.post("/compaines/surveys", values) as any,
  show: ({ surveyId }) => request.get(`/compaines/surveys/${surveyId}`) as any,
  update: ({ surveyId, values }) =>
    request.put(`/compaines/surveys/${surveyId}`, values) as any,
  delete: ({ surveyId }) =>
    request.delete(`/compaines/surveys/${surveyId}`) as any,
  downloadExcel: ({ surveyId }) =>
    request.get(`/compaines/surveys/${surveyId}/download-xlsx`, {
      headers: { accept: "application/vnd.ms-excel" },
      responseType: "arraybuffer",
    }) as any,
  downloadCsv: ({ surveyId }) =>
    request.get(`/compaines/surveys/${surveyId}/download-csv`) as any,
  questions: {
    create: ({ surveyId, values }) =>
      request.post(`/compaines/surveys/${surveyId}/questions`, values) as any,
    list: ({ surveyId }) =>
      request.get(`/compaines/surveys/${surveyId}/questions`) as any,
    listWithAnswers: ({ surveyId }) =>
      request.get(`/compaines/surveys/${surveyId}/questions/answers`) as any,
    listWithUserAnswers: ({ surveyId, filters }) =>
      request.post(
        `/compaines/surveys/${surveyId}/questions/user-answers`,
        filters
      ) as any,
    show: ({ surveyId, questionId }) =>
      request.get(
        `/compaines/surveys/${surveyId}/questions/${questionId}`
      ) as any,
    update: ({ surveyId, questionId, values }) =>
      request.put(
        `/compaines/surveys/${surveyId}/questions/${questionId}`,
        values
      ) as any,
    sort: ({ surveyId, values }) =>
      request.put(
        `/compaines/surveys/${surveyId}/questions/sorts`,
        values
      ) as any,
    delete: ({ surveyId, questionId }) =>
      request.delete(
        `/compaines/surveys/${surveyId}/questions/${questionId}`
      ) as any,
    options: {
      create: ({ surveyId, surveyQuestionId, values }) =>
        request.post(
          `/compaines/surveys/${surveyId}/questions/${surveyQuestionId}/options`,
          values
        ) as any,
      update: ({
        surveyId,
        surveyQuestionId,
        surveyQuestionOptionId,
        values,
      }) =>
        request.put(
          `/compaines/surveys/${surveyId}/questions/${surveyQuestionId}/options/${surveyQuestionOptionId}`,
          values
        ) as any,
      delete: ({ surveyId, surveyQuestionId, surveyQuestionOptionId }) =>
        request.delete(
          `/compaines/surveys/${surveyId}/questions/${surveyQuestionId}/options/${surveyQuestionOptionId}`
        ) as any,
    },
  },
};
