import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaStoreAlt } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import useLocales from "lib/hooks/useLocales";
import get from "lodash/get";

import CompanyPlaceholder from "../CompanyPlaceholder";
import QRCode from "shared/components/QRCode";
import useUser from "lib/hooks/useUser";
import assetsConfig from "theme/assetsConfiguration";
import { ifArabic } from "lib/helpers/i18n";

const InVisibleFrames = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const CompanyProfileWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  left: auto;
  top: calc(100%);
  margin-bottom: -1px;
  z-index: 1000000;
  animation-name: ${InVisibleFrames};
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

const MenuButtonsContainer = styled.div.attrs({ className: "w-48" })``;
const MenuButton = styled.div.attrs({
  className: "flex flex-row w-full items-center p-2 hover:bg-grey",
})`
  display: flex;
  flex-direction: row;
`;

const ProfileDropDown = (props) => {
  const header = useLocales("header") as any;
  const { company, user, getRole } = useUser();

  return (
    <div className="ml-auto mega-container relative">
      <CompanyPlaceholder
        image={
          get(user, "profileImage", false)
            ? user?.profileImage
            : assetsConfig.images.userAvatarSvg
        }
        name={window.innerWidth > 600 ? get(user, "firstName", "") : ""}
        role={ifArabic(
          getRole({ roleId: user?.role })?.titleAr,
          getRole({ roleId: user?.role })?.titleEn
        )}
      />
      <CompanyProfileWrapper className="mega">
        <Link to="/recruiter-profile" className="hover:text-black">
          <MenuButtonsContainer>
            <MenuButton>
              <FaStoreAlt size={20} color={"#303232"} />
              <div className="ml-2">{header.company}</div>
            </MenuButton>
          </MenuButtonsContainer>
        </Link>
        <MenuButtonsContainer>
          <MenuButton onClick={() => props.logout()} className="cursor-pointer">
            <IoIosLogOut size={20} color={"#303232"} />
            <div className="ml-2">
              <span>{header.logout}</span>
            </div>
          </MenuButton>
        </MenuButtonsContainer>
      </CompanyProfileWrapper>
    </div>
  );
};

export default ProfileDropDown;
