import React, { useEffect, useState } from "react";
import useLocales from "lib/hooks/useLocales";
import ClientHeader from "shared/components/ClientHeader";
import SuspenseWrapper from "shared/components/SuspenseWrapper";
import { useDispatch, useSelector } from "react-redux";
import { resetGigForm } from "lib/actions/gigJobs";
import { useLocation } from "react-router";

const CreateGigLayout = (props) => {
  const locales = useLocales("postGig.jobDescription");
  const dispatch = useDispatch();
  const location = useLocation();
  const [step, setStep] = useState(1);

  useEffect(() => {
    const pathnameArray = location?.pathname?.slice(1).split("/");
    if (pathnameArray?.length) {
      if (pathnameArray[1] === "payment") {
        setStep(2);
      } else {
        setStep(1);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(resetGigForm());
    };
  }, []);
  return (
    <div className="h-full">
      <ClientHeader />
      <div className="container pt-3 p-5 sm:pt-6 pb-16">
        <div className="flex items-center mx-auto" style={{ maxWidth: 500 }}>
          <div className="w-24 h-24">
            <div className="w-full h-full flex items-center justify-center border-2 border-primary rounded-full">
              <p className="text-lg"> {step} - 2</p>
            </div>
          </div>
          <div className="ml-2">
            <h1 className="text-3xl sm:text-4xl font-avenir-medium text-center">
              {locales.title(step)}
            </h1>
            {step === 2 && (
              <span className="text-lg text-gray">{locales.lastStep}</span>
            )}
          </div>
        </div>
        <SuspenseWrapper>{props.children}</SuspenseWrapper>
      </div>
    </div>
  );
};

export default CreateGigLayout;
