import React from "react";
import useLocales from "lib/hooks/useLocales";

function SupportFooter() {
  const login = useLocales("login");
  return (
    <div className="text-center text-gray-400 text-lg">
      {login.support}{" "}
      <a
        href="mailto:info@kaderapp.com"
        className="text-primary font-avenir-medium hover:underline cursor-pointer"
      >
        info@kaderapp.com
      </a>
    </div>
  );
}

export default SupportFooter;
