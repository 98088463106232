import LoginFirstBackgroundPng from "assets/images/guest/background-1.png";
import LoginSecondBackgroundPng from "assets/images/guest/background-2.png";
// import KaderLogoSvg from "assets/images/logo/logo.svg";
import UserAvatarSvg from "assets/icons/userAvatar.svg";
import CompanyLogoPlaceholder from "assets/icons/business-and-trade.svg";
import OtpSentIcon from "assets/icons/otp-sent-icon.png";
import MarkerIconSvg from "assets/icons/location/marker.svg";
import VisaFullColorJPG from "assets/icons/VisaFullColor.jpeg";
import KaderWhiteLogoJPG from "assets/images/logo/logo.jpg";

import { ReactComponent as KaderLogoSvg } from "assets/images/logo/logo.svg";
import { ReactComponent as PlusIcon } from "assets/icons/svg/plus.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/svg/notification.svg";
import { ReactComponent as SaudiArabiFlagIcon } from "assets/icons/svg/flags/133-saudi-arabia.svg";
import { ReactComponent as UnitedStatesFlagIcon } from "assets/icons/svg/flags/226-united-states.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/svg/dashboard.svg";
import { ReactComponent as JobsIcon } from "assets/icons/svg/jobs.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/svg/profile.svg";
import { ReactComponent as CompanyIcon } from "assets/icons/svg/company.svg";
import { ReactComponent as WalletIcon } from "assets/icons/svg/wallet.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/svg/settings.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/svg/logout.svg";
import { ReactComponent as TimeIcon } from "assets/icons/svg/time.svg";
import { ReactComponent as ReOrderIcon } from "assets/icons/svg/re-order.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/svg/calendar.svg";
import { ReactComponent as PrinterIcon } from "assets/icons/svg/printer.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/svg/navigation-arrow-right.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/svg/navigation-arrow-left.svg";
import { ReactComponent as CoinIcon } from "assets/icons/svg/coin.svg";
import { ReactComponent as LocationIcon } from "assets/icons/svg/location.svg";
import { ReactComponent as InfoIcon } from "assets/icons/svg/info.svg";
import { ReactComponent as SearchIcon } from "assets/icons/svg/search.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/svg/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/svg/chevron-down.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/svg/chevron-right.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/svg/chevron-left.svg";
import { ReactComponent as PhoneCallIcon } from "assets/icons/svg/phone.svg";
import { ReactComponent as EditIcon } from "assets/icons/svg/edit.svg";
import { ReactComponent as MasterCardSvg } from "assets/icons/mc_symbol.svg";
import { ReactComponent as CalendarSuccess } from "assets/icons/svg/calendar-success.svg";
import { ReactComponent as GigSuccessIcon } from "assets/icons/svg/successful-ils.svg";
import { ReactComponent as WhatsAppIcon } from "assets/icons/svg/whats-app.svg";
import { ReactComponent as AppleStoreIcon } from "assets/icons/kader-app-store.svg";
import { ReactComponent as AndroidStoreIcon } from "assets/icons/kader-google-play.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/svg/delete.svg";
import { ReactComponent as WillContactYouSoon } from "assets/icons/svg/willContactYouSoon.svg";
import { ReactComponent as GlobalIcon } from "assets/icons/svg/globe.svg";
import { ReactComponent as Documents } from "assets/icons/svg/documents.svg";
import { ReactComponent as ChatBotIcon } from "assets/icons/chat-bot.svg";

export const LoginFirstBackground = LoginFirstBackgroundPng;
export const LoginSecondBackground = LoginSecondBackgroundPng;

export const KADER_LOGO = KaderLogoSvg;

export const OTP_SENT_ICON = OtpSentIcon;

const assetsConfig = {
  icons: {
    mapPrimaryMarker: MarkerIconSvg,
    visaFullColor: VisaFullColorJPG,
    masterCardSvg: MasterCardSvg,
    plusIcon: PlusIcon,
    notificationIcon: NotificationIcon,
    calendarSuccess: CalendarSuccess,
    appleStore: AppleStoreIcon,
    androidStore: AndroidStoreIcon,
    flags: {
      sau: SaudiArabiFlagIcon,
      unitedStates: UnitedStatesFlagIcon,
    },
    sidebarIcons: {
      dashboardIcon: DashboardIcon,
      jobsIcon: JobsIcon,
      profileIcon: ProfileIcon,
      companyIcon: CompanyIcon,
      walletIcon: WalletIcon,
      settingsIcon: SettingsIcon,
      logoutIcon: LogoutIcon,
      documents: Documents,
    },
    time: TimeIcon,
    reOrder: ReOrderIcon,
    calendar: CalendarIcon,
    printer: PrinterIcon,
    arrowRight: RightArrowIcon,
    arrowLeft: LeftArrowIcon,
    coin: CoinIcon,
    location: LocationIcon,
    info: InfoIcon,
    search: SearchIcon,
    chevronUp: ChevronUpIcon,
    chevronDown: ChevronDownIcon,
    chevronRight: ChevronRightIcon,
    chevronLeft: ChevronLeftIcon,
    phoneCall: PhoneCallIcon,
    edit: EditIcon,
    gigSuccessIcon: GigSuccessIcon,
    whatsAppIcon: WhatsAppIcon,
    deleteIcon: DeleteIcon,
    willContactYouSoon: WillContactYouSoon,
    globalIcon: GlobalIcon,
    chatBotIcon: ChatBotIcon
  },
  images: {
    companyLogoPlaceholder: CompanyLogoPlaceholder,
    userAvatarSvg: UserAvatarSvg,
    kaderWhiteLogoJPG: KaderWhiteLogoJPG,
  },
};

export default assetsConfig;
