import { ActionTypes } from "../constants/actionTypes";

export const setIndustries = (data: Array<any>) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_INDUSTRIES, value: data });
};

export const setCountries = (data: Array<any>) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_COUNTRIES, value: data });
};

export const setCurrencies = (data: Array<any>) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_CURRENCIES, value: data });
};

export const setSettings = (data: Array<any>) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_SETTINGS, value: data });
};
