import React, { useState, memo } from "react";
import "./Image.scss";

interface ImageProps {
  image;
  alt;
  fallBack?;
  fallBackType?: "image" | "icon";
  className?: string;
}

const Image = (props: ImageProps) => {
  const [loading, setLoading] = useState(Boolean);
  const [isPlaceholder, setIsPlaceholder] = useState(Boolean);

  return !props.image || loading || isPlaceholder ? (
    props?.fallBackType && props?.fallBackType === "image" ? (
      <img
        src={props.fallBack}
        alt={props.alt}
        className={`image ${props?.className || ""}`}
      />
    ) : (
      <div className={`image ${props?.className || ""}`}>{props.fallBack}</div>
    )
  ) : (
    <img
      src={props.image}
      alt={props.alt}
      onError={() => {
        setIsPlaceholder(true);
      }}
      className={`image ${props?.className || ""}`}
      onLoad={() => setLoading(false)}
    />
  );
};

export default memo(Image);
