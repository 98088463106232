import React from "react";
import styled from "styled-components";
import * as AssetsConfiguration from "theme/assetsConfiguration";
import useLocales from "lib/hooks/useLocales";
import { getLang } from "lib/services/LocalStorage";

interface Props {}
const BackgroundWrapper = styled.div.attrs({})`
  height: 100vh;
  background: linear-gradient(128.8deg, #4860d8 -40.15%, #721fb3 100%);
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-height: 100vh;
  }
`;

const ContentContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Info = styled.div`
  padding-top: 4.25rem;
`;

function GuestSideBar(props: Props) {
  const locales = useLocales();
  return (
    <BackgroundWrapper className="w-full relative md:h-full">
      <ContentContainer>
        <Info className="text-white text-bold hidden sm:block">
          <h1
            className=" text-3xl text-white sm:text4xl md:text-5xl leading-none"
            dangerouslySetInnerHTML={{ __html: locales.login.onBoarding.title }}
          />

          <h1
            className="text-sm text-white sm:text-lg leading-tight md:leading-relaxed mt-4 text-light"
            dangerouslySetInnerHTML={{
              __html: locales.login.onBoarding.subTitle,
            }}
          />
        </Info>

        <div className="flex">
          <div className="w-1/2">
            <img
              src={AssetsConfiguration.LoginFirstBackground}
              alt="Kader recruiter login background"
            />
          </div>
          <div className="w-1/2 flex items-end">
            <img
              src={AssetsConfiguration.LoginSecondBackground}
              alt="Kader recruiter login background"
            />
          </div>
        </div>
        <div className="absolute bottom-0 right-0 left-0 sm:relative p-2 text-center sm:text-left font-regular text-white text-lg ">
          <span className="">{locales.login.support}</span>{" "}
          <a
            href="mailto:info@kaderapp.com"
            className="text-primary font-avenir-medium hover:underline cursor-pointer "
          >
            info@kaderapp.com
          </a>
          <a
            href={`https://kaderapp.com/${getLang()}/terms-and-conditions-of-use`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-75 hover:text-white hover:underline cursor-pointer block transition duration-300"
          >
            {locales.login.terms}
          </a>
        </div>
      </ContentContainer>
    </BackgroundWrapper>
  );
}

export default GuestSideBar;
