import React from "react";
import useLocales from "lib/hooks/useLocales";
import { MdEmail } from "react-icons/md";
import { SimpleLoader } from "shared/components/Loader";

const VerifyEmailAddress = () => {
  const locales = useLocales("completeProfile.verifyEmailAddress");
  return (
    <div>
      <div className="flex items-center">
        <div className="flex-1 flex items-start">
          <div className="w-10 h-10 rounded-full flex items-center justify-center p-3 bg-primary mt-1">
            <MdEmail size={30} className="text-white" />
          </div>
          <div className="mx-4 flex-1">
            <h1 className="text-xl">{locales.title}</h1>
            <h2 className="text-base mt-2">{locales.subTitle}</h2>
            <SimpleLoader size="small" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailAddress;
