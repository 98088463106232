import request from ".";
import IUpdateEmail from "types/IUpdateEmail";

export interface ISendOtpData {
  mobileNumber: number;
  password?: string;
}

interface IVerifyOtp extends ISendOtpData {
  code: number;
}

export const profileApis = {
  get: () => request.get("/profile") as any,
  update: (values) => request.put("/profile", values) as any,
  updateEmail: (values: IUpdateEmail) =>
    request.put("/profile/email", values) as any,
  sendOtp: (data: ISendOtpData) =>
    request.put("/profile/phone/send-code", data) as any,
  verifyOtp: (data: IVerifyOtp) =>
    request.put("/profile/phone/verify-code", data) as any,
  resetPassword: () => request.put("/profile/reset-password") as any,
  company: {
    updateCompany: async (values) => request.put("/companies", values),
  },
};
