import isArray from "lodash/isArray";
import isEmpty from 'lodash/isEmpty';

export const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "null" ||
      obj[propName] === "" ||
      (isArray(obj[propName]) && !obj[propName].length)
    ) {
      delete obj[propName];
    }
  }

  return obj;
};


export const cleanObject = (obj, exclude?) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "null" ||
      obj[propName] === "" ||
      (isArray(obj[propName]) && !obj[propName].length) ||
      (typeof obj[propName] === "object" && isEmpty(obj[propName]))
    ) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object" && !isEmpty(obj[propName])) {
      if (exclude && exclude?.length && exclude.includes(propName)) {
      } else {
        obj[propName] = cleanObject(obj[propName]);
        if (isEmpty(obj[propName])) {
          delete obj[propName];
        }
      }
    }
  }
  return obj;
};
