import useChatBot from "lib/hooks/useChatBot";
import useUser from "lib/hooks/useUser";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import Styled from "styled-components";
import assetsConfig from "theme/assetsConfiguration";
import { theme } from "theme/global/theme";

const ChatBotIconWrapper = Styled.div`
position: fixed;
bottom: 50px;
right: 50px;
width: 75px;
height: 75px;
background: ${(props) => props.theme.rgbColors.primary(0.5)};
// padding: 20px;
border-radius: 9999px;
box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.190982);
cursor: pointer;

&:hover {
background: ${(props) => props.theme.rgbColors.primary(1)};
& svg {
    fill: white;
}
}
`;

const ChatBotIcon = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeRoom, setActiveRoom] = React.useState(Object);
  const { getActiveRooms, createRoom } = useChatBot();

  const fetchActiveRoom = async () => {
    try {
      const activeRooms = await getActiveRooms();
      if (activeRooms) {
        setActiveRoom(activeRooms);
      }
    } catch (error: any) {}
  };

  React.useEffect(() => {
    if (user?.preferences?.allowChatBot) {
      fetchActiveRoom();
    }
  }, []);

  const onClick = async () => {
    if (activeRoom?.id) {
      navigate(`/chatbot/${activeRoom?.id}`);
    } else {
      try {
        const createdRoomData = (await createRoom()) as any;
        // setActiveRoom(createdRoomData);
        navigate(`/chatbot/${createdRoomData?.id}`);
      } catch (error: any) {}
    }
    setActiveRoom({});
  };

  return user?.preferences?.allowChatBot &&
    !location?.pathname?.includes("/chatbot") ? (
    <ChatBotIconWrapper
      onClick={onClick}
      className="flex items-center justify-center"
    >
      {/* <Link
        to={`/chatbot/${activeRoom?.id}`}
        className="w-full h-full flex items-center justify-center"
      > */}
      <assetsConfig.icons.chatBotIcon width="50%" fill={theme.colors.primary} />
      {/* </Link> */}
    </ChatBotIconWrapper>
  ) : null;
};

export default ChatBotIcon;
