import { siteCountries, siteLanguages } from "lib/constants/general";

/**
 * This function is used on client side to get the default browser language
 */
export const getBrowserLanguage = () => {
  return navigator.language.includes(siteLanguages.EN.value)
    ? siteLanguages.EN.value
    : siteLanguages.AR.value;
};

export const setLang = (language) => {
  sessionStorage.setItem("lang", language);
};

export const getLang = () => {
  return sessionStorage.getItem("lang") || siteLanguages.EN.value;
};

export const getDir = () => {
  const savedCountry = getCountry();
  if (savedCountry) {
    const countryObject = siteCountries.find(
      (country) => country.code === savedCountry
    ) as any;
    return getLang() === countryObject.languages.AR.value
      ? countryObject.languages.AR.dir
      : countryObject.languages.EN.dir;
  } else {
    return getLang() === siteLanguages.AR.value
      ? siteLanguages.AR.dir
      : siteLanguages.EN.dir;
  }
};

////////////////////
export const setCountry = (country) => {
  console.log(country, "countrycountrycountrycountrycountry");
  sessionStorage.setItem("ctry", country);
};

export const getCountry = () => {
  return sessionStorage.getItem("ctry");
};
