const arEG = {
  shared: {
    phone: "رقم الهاتف",
    commingSoon: "قريبا!",
    search: "إبحث",
    jod: "د.أ",
    hours: "ساعة",
    minutes: "دقيقة",
    totalHours: "مجموع الساعات",
    all: "الكل",
    locationSearch: "ابحث عن المكان",
    cancel: "إلغاء",
    location: "الموقع: ",
    submit: "حفظ",
    from: "من",
    to: "إلى",
    priceRange: "حول",
    priceRangePlaceholder:
      "سيتم إحتساب القيمة التقريبية بعد إدخال المعلومات المطلوبة",
    addCard: "أضف بطاقة",
    changeCard: "تعديل البطاقة",
    jobs: "الوظائف",
    spending: "التكلفة",
    viewAll: "عرض الكل",
    costHour: "السعر بالساعة",
    timing: "التوقيت",
    avgPricePerHour: "سعر الساعة)القريبي)",
    addNewAddress: "أضف عنوان جديد",
    save: "حفظ",
    changeImage: "تحديث الصورة",
    days: "ايام",
    viewFullProfile: "الملف الشخصي",
    downloadCSV: "تحميل CSV",
  },
  login: {
    onBoarding: {
      // ملء الشواغر الخاصة بك اليوم!
      title: "تزودك كادر بأفضل المواهب",
      subTitle:
        "يوفر لكم كادر عملية توظيف مريحه و خالية من المتاعب من عملية التوظيف و الإشراف و إجراءات الدفع , يضمن كادر إدارة خالية من المشاكل لجميع احتياجات التوظيف قصيرة الأجل.",
    },
    welcome: "التوظيف أسهل من أي وقت مضى<br /> سجل اليوم!",
    forgotPassword: "نسيت كلمة المرور؟",
    forgotEmail: "نسيت البريد الإكتروني؟",
    hire: "تسجيل الدخول",
    singup: "ليس لديك حساب؟",
    resetPassword: "دعنا نعيد ضبط كلمة مرورك",
    enterMobile:
      "أدخل رقم هاتفك المحمول حتى نتمكن من إعادة تعيين كلمة المرور الخاصة بك",
    enterEmail:
      "أدخل عنوان بريدك الإلكتروني حتى نتمكن من إعادة تعيين كلمة المرور الخاصة بك",
    resetPasswordSubtitle: "لا بأس أن الجميع ينسى أحيانًا",
    resetTitle: "أعد ضبط كلمة السر",
    loginForm: {
      phone: "رقم الهاتف",
      email: "البريد الإلكتروني",
      password: "كلمة السر",
      validation: {
        "user_already_exists.": "المستخدم موجود اصلا.",
        "the_given_data_was_invalid.": "البيانات المعطاة كانت صالحة.",
      },
    },
    resetPasswordForm: {
      phone: "رقم الهاتف",
      email: "البريد الإلكتروني",
      password: "كلمة السر",
      confirmPassword: "تأكيد كلمة السر",
      rules: `
        <li>- يجب أن تكون كلمة مرورك على الأقل 8 أحرف </li>
        <li>- لا تستخدم اسمك أو رقمك كلمة المرور الخاصة بك </li>`,
      errors: {
        incorrect: "رمز التحقق خاطئ",
      },
      actions: {
        submit: "إرسال",
      },
      messages: {
        doneSuccessfully: "تم تغيير كلمة المرور الخاصة بك بنجاح",
        doneSuccessfullySubtitle:
          "ستتم إعادة توجيهك إلى شاشة تسجيل الدخول قريبًا",
        emailSent: "تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
        emailSentSubtitle:
          "تحقق من بريدك الإلكتروني من فضلك ، سيتم إعادة توجيهك إلى شاشة تسجيل الدخول قريبًا",
      },
    },
    confirmAccount: "اكد حسابك",
    confirmSubtitle:
      "أنت على بعد خطوة واحدة! الرجاء إدخال رمز التحقق الذي تم إرساله إلى رقم هاتفك المحمول للمتابعة.",
    resentCode: "أعد إرسال الرمز",
    smsVerify: "تم إرسال رمز التحقق من الرسائل القصيرة إلى",
    faildReceiveCode: "لم أستلم الرمز؟",
    support: "تحتاج مساعدة؟",
    terms: "الأحكام والشروط",
  },
  register: {
    register: "تسجيل",
    update: "حفظ",
    haveAccount: "هل لديك حساب؟",
    sms: "تم إرسال رمز التحقق من الرسائل القصيرة إلى",
    oneStepAway:
      "أنت على بعد خطوة واحدة! الرجاء إدخال رمز التحقق الذي تم إرساله إلى رقم هاتفك المحمول للمتابعة.",
    resend: "أعد إرسال الرمز",
    form: {
      fields: {
        firstName: {
          label: "الإسم الأول",
        },
        lastName: {
          label: "الإسم الأخير",
        },
        jobTitle: {
          label: "العنوان الوظيفي",
        },
        email: {
          label: "البريد الإلكتروني",
        },
        password: {
          label: "كلمة السر",
        },
        confirmPassword: {
          label: "تأكيد كلمة السر",
        },
        gender: {
          label: "الجنس",
        },
        dateOfBirth: {
          label: "تاريخ الميلاد",
        },
        nationality: {
          label: "الجنسية",
        },
        company: {
          title: { label: "إسم الشركة" },
          description: { label: "وصف الشركة" },
          industry: { label: "قطاع الشركة" },
          numberOfBranches: { label: "عدد الفروع" },
          employeesRange: { label: "عدد الموظفين" },
          country: { label: "إختر الدولة" },
        },
      },
    },
    messages: {
      emailSent: "تمت العملية بنجاح",
      emailSentSubtitle:
        "تم إرسال رابط التحقق إلى بريدك الإلكتروني ، سيتم إعادة توجيهك إلى شاشة تسجيل الدخول قريبًا",
    },
  },
  profileForm: {
    title: "أخبرنا عن نفسك",
    fields: {
      image: "صورة الملف الشخصي",
      firstName: "الإسم الأول",
      lastName: "الإسم الأخير",
      email: "البريد الإلكتروني",
    },
    actions: {
      submit: "التالي",
    },
    validation: {
      email: "البريد الإلكتروني تم أخذه.",
    },
    createPassword: {
      title: "لنقم بإنشاء كلمة المرور الخاصة بك",
      rules: `
        <li>- يجب أن تكون كلمة مرورك على الأقل 8 أحرف </li>`,
      fields: {
        password: "كلمة السر",
        confirmPassword: "تأكيد كلمة السر",
      },
      note: "لا تكون في عجلة من امرك!",
      actions: {
        submit: "التالي",
      },
    },
    companyProfile: {
      title: "قم بإعداد حساب شركتك",
      editTitle: "تعديل بيانات الشركة",
      fields: {
        image: "شعار الشركة",
        name: "إسم الشركة",
        description: "وصف",
        industry: "اختر الصناعة",
        country: "إختر الدولة",
        size: "حجم الشركة",
        branches: "فروع الشركة",
      },
      actions: {
        submit: "التالي",
      },
    },
    companyLocation: {
      search: "ابحث عن المكان",
      cancel: "إلغاء",
      location: "الموقع: ",
      submit: "حفظ",
    },
  },
  header: {
    myJobs: "الوظائف",
    myGigs: "وظائف بالساعة",
    messages: "الرسائل",
    cv: "إبحث",
    postJob: "اضف وظيفة جديدة",
    postGig: "Post a Gig",
    language: "English",
    company: "المعلومات الشخصية",
    logout: "تسجيل الخروج",
    plans: "الحزم",
  },
  sidebar: {
    dashboard: "الصفحة الرئيسية",
    jobs: "الوظائف",
    favorite: "المفضلين",
    profile: "المعلومات الشخصية",
    company: "معلومات الشركة",
    balance: "الرصيد",
    settings: "الإعدادات",
    logout: "تسجيل الخروج",
    surveys: "الاستبيانات",
  },
  jobs: {
    title: "الوظائف",
    pending: "قيد الانتظار",
    active: "الفعالة",
    paused: "الموقفة",
    closed: "المغلقة",
    rejected: "مرفوض",
    shortlisted: "المؤهلين",
    distance: (km) => `يبعد ${km} كيلومترات`,
    jobCard: {
      details: "تفاصيل الوظيفة",
      activate: "تفعيل",
      close: "إغلاق",
      pause: "إيقاف",
      share: "نشر الوظيفة",
      shortlisted: "المؤهلين",
      applicants: "المتقدمين",
      pending: "قيد المراجعة",
      rejected: "مرفوضين",
      suggested: "المقترحون",
      requests: "المقابلات المطلوبة",
      kaderos: (kaderos) => `${kaderos} عدد الموظفين`,
      actions: {
        view: "عرض",
        activate: "تفعيل",
        close: "إغلاق",
        pause: "إيقاف",
        share: "نشر الوظيفة",
        rePost: "إعادة نشر",
      },
    },
    jobInfo: {
      summary: "تفاصيل الوظيفة",
      requirements: "المتطلبات",
      experience: "الخبرة",
      salary: "الراتب",
      gender: "الجنس",
      applicants: "المتقدمين",
      noApplicants: (jobType) => `لا يوجد متقدمين`,
      actions: {
        shortlist: "المؤهلين",
        reject: "رفض",
        viewNumber: "إضغط لرؤية الرقم ",
        call: "إتصل بـ ",
      },
      at: "at",
      to: "to",
      present: "Present",
      jod: "د.أ",
      usd: "دولار",
    },
    closeJob: {
      feedBack: {
        closureReason: {
          title: "لماذا تريد اغلاق الشاغر؟ هل واجهتك مشكلة؟",
          filled: "لقد ملئ هذا الشاغر",
          notAvailable: "هذا الشاغر اصبح غير متوفر",
          notRelevant: "لم اجد المرشح المناسب لهذه الوظيفة",
        },
        closureReasonDetails: {
          title: "كيف ملئت هذا الشاغر؟",
          kader: "كادر",
          facebook: "فيسبوك",
          worldOfMouth: "كلام الناس، توصية الناس",
          newspaper: "الصحف",
          other: "طريقة اخرى",
        },
        submit: "إرسال",
      },
    },
  },
  bookings: {
    title: "اعمال بالساعة",
    empty: {
      title: "No Booking posted yet",
      action: "Book your staff",
    },
    details: "View Booking Details",
    postedAt: "Posted At ",
    startDate: "Starts At ",
    endDate: "Ends At ",
    duration: (count) => (count > 1 ? "Days" : "Day"),
  },
  company: {
    profile: "حساب الشركة",
    location: "الموقع",
    userProfile: "المعلومات الشخصية",
    configuration: "Configuration",
    pushNotifications: "إرسال اشعارات",
    edit: "تعديل",
    changePassword: "تغيير كلمة المرور",
    credit: "الرصيد: ",
    submitButton: "حفظ البيانات",
    changeImage: "تغيير الصورة",
    fields: {
      companyName: "إسم الشركة",
      companyDescription: "وصف الشركة",
      companyIndustry: "قطاع الشركة",
      firstName: "الإسم الأول",
      lastName: "الإسم الأخير",
      phone: "رقم الهاتف",
      email: "البريد الإلكتروني",
    },
    addresses: {
      title: "العناوين",
      addressesForm: {
        title: "إضافة عنوان جديد",
        fields: {
          title: {
            label: "وصف العنوان",
          },
          street: {
            label: "الشارع",
          },
          area: {
            label: "المنطفة",
          },
          building: {
            label: "رقم البناية",
          },
          instructions: {
            label: "تعليمات",
          },
        },
        actions: {
          save: "حفظ بيانات العنوان",
        },
      },
      newAddress: "عنوان جديد",
      emptyAddresses: "لا يوجد عناوين",
      instructions: "تعليمات",
      deleteAlert: {
        title: "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
        action: "نعم",
        reject: "لا",
      },
    },
    recruiters: {
      title: "الموظفين",
      addNew: "موظف جديد",
    },
    statistics: {
      title: "إحصائيات الحجوزات",
      averageRating: "متوسط التقييم",
      completedJobs: "الحجوزات المكتملة",
      totalNumberOfHours: "إجمالي عدد الساعات",
    },
    balance: {
      title: "الميزانية",
      addCreditCard: "Add Credit Card",
      cards: {
        title: "البطاقات",
        addCard: "أضف بطاقة",
        columns: {
          holder: "حامل البطاقة",
          expiry: "تاريخ الإنتهاء",
          last4digits: "آخر 4 أرقام",
          brand: "العلامة التجارية",
          actions: "العمليات",
        },
      },
      transactions: {
        title: "المعاملات",
        empty: "لا توجد معاملات",
        payPending: "إدفع",
      },
    },
  },
  applicant: {
    credits: "رصيد",
    experience: "الخبرة:",
    actions: {
      shortlist: "ترشيح",
      reject: "رفض",
      viewNumber: "إضغط لرؤية رقم الهاتف ",
      call: "إتصل بـ ",
      requestInterview: "طلب مقابلة",
    },
    at: " في ",
    to: " إلى ",
    present: " الوقت الحالي ",
    bio: "السيرة الذاتية",
    experienceTitle: "الخبرة",
    education: "التعليم",
    language: "اللغة",
    noApplicants: (jobType) => `لا يوجد متقدمين`,
    distance: (km) => `يبعد ${km} كيلومترات`,
    noExperience: "لا يوجد خبرة",
    noBio: "لا يوجد وصف",
    year: "سنة",
    month: "شهر",
  },
  filters: {
    title: "تصفية المتقدمين",
    select: "إختر",
    education: "التعليم",
    experience: "الخبرة",
    nationality: "الجنسية",
    gender: "الجنس",
    any: "أي",
    male: "ذكر",
    female: "أنثى",
  },
  plans: {
    title: "إختر الحزمة التي تناسبك",
    choose: "إختر",
    jod: "د.أ",
    connections: "رصيد: ",
    confirmationModal: {
      title: "هل أنت متأكد أنك تريد شراء هذا الحزمة؟",
      subTitle: "من خلال الضغط على نعم, أنت توافق على دفع",
      subTitle2: "سيتصل بك أحد أعضاء فريقنا قريبًا.",
      actions: {
        yes: "نعم",
        no: "لا",
      },
    },
    successModal: {
      title: "اكتملت العملية بنجاح",
      subTitle: "سيتصل بك أحد أعضاء فريقنا قريبًا.",
      done: "شكراََ",
    },
  },
  postJob: {
    stepper: {
      jobDescription: "وصف الوظيفة",
      requirements: "المتطلبات",
      location: "حدد موقع الوظيفة",
    },
    category: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "اختر الفئة",
      required: "يجب عليك تحديد فئة قبل المتابعة",
      actions: {
        submit: "التالي",
      },
    },
    titles: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "إختر المسمى الوظيفي",
      required: "يجب عليك تحديد المسمى الوظيفي قبل المتابعة",
      actions: {
        submit: "التالي",
        search: "إبحث عن المسميات",
      },
    },
    jobDescription: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "تفاصيل الوظيفة",
      form: {
        fields: {
          description:
            "إذا كنت تبحث عن مرشح نشط للعمل معنا من الساعة 8 صباحًا حتى 5 مساءً ، فيجب أن يتمتع المتقدم بالاتصال الجيد ومهارات Microsoft.",
          jobType: "نوع الوظيفة",
          shift: "الفترة",
          salary: "الراتب",
          card: "البطاقة",
          title: "عيد ميلاد سارة, خطوبة محمد.... الخ",
        },
        actions: {
          submit: "نشر الوظيفة",
        },
        morning: "صباحاََ",
        afternoon: "بعد الظهر",
        night: "مسائاََ",
        internship: "تدريب",
        fullTime: "دوام كامل",
        partTime: "دوام جزئي",
      },
    },
    jobRequirements: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "املأ متطلبات الوظيفة",
      form: {
        fields: {
          experience: "الخبرات (عدد السنوات)",
          minEducation: "الحد الأدنى لمستوى التعليم",
          gender: "الجنس",
        },
        year: "سنة",
        actions: {
          submit: "تحديد موقع الوظيفة",
        },
      },
    },
    jobLocation: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "موقع الوظيفة",
      actions: {
        submit: "انشر عرض عمل",
      },
    },
  },
  postGig: {
    stepper: {
      jobDescription: "وصف الوظيفة",
      requirements: "المتطلبات",
      location: "موقع الوظيفة",
    },
    category: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "اختر الفئة",
      required: "يجب عليك تحديد فئة قبل المتابعة",
      actions: {
        submit: "التالي",
      },
    },
    jobDescription: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: (step) => (step === 1 ? "تفاصيل الوظيفة" : "تفاصيل الدفع"),
      lastStep: "الخطوة الأخيرة",
      form: {
        fields: {
          schedule: "مواعيد العمل",
          location: "الموقع",
          timeSlots: " إضافة موعد",
          requirements: "متطلبات إضافية",
          description:
            "مثلاََ... يجب ان يرتدي بنطال اسود, يجب ان يكون عضو فعال في فريف العمل الخ..",
          date: "للعمل من",
          lookingFor: "أبحث عن",
          on: "في تاريخ",
          staff: "عدد الموظفين المطلوبين؟",
          startTime: "وقت البدء",
          endTime: "وقت الإنتهاء",
          insurance: "هل ترغب في إضافة تأمين لهذه الوظيفة؟",
          insuranceSubTitle:
            "الكاديروز مؤمنون ضد الحوادث في حال حدوث اصابة عمل خلال تواجده في الوظيفة.",
          title: "عيد ميلاد سارة, خطوبة محمد.... الخ",
          titleLabel: " اسم الوظيفة",
          gender: "الجنس",
          jobPreference: "طبيعة العمل المفضلة",
          survey: {
            title: "إضافة إستبيان (اختياري)",
            attach: "إرفاق إستبيان",
            change: "تغيير الإستبيان",
          },
        },
        jod: "د.أ",
        actions: {
          next: "التالي",
          done: "حفظ",
          submit: "نشر الوظيفة",
          submitAndDuplicate: "نشر الوظيفة وإنشاء وظيفة اخرى",
          cancel: "إلغاء",
          add: "إضافة",
          save: "حفظ",
        },
        timeSlot: {
          insurance: "مؤمن",
          edit: "تعديل",
          note: "في اليوم التالي",
        },
        errors: {
          duration: "يجب أن يبدأ الحجز بعد 12 ساعة من الوقت المعلن",
        },
      },
      paymentSection: {
        title: "الدفع",
        balance: "استخدام الميزانية",
        cards: "البطاقات",
        selectedCreditCard: "البطاقة المختارة",
        cashOrChek: "نقداََ او شيك",
        bookingTotal: "إجمالي السعر",
        taxTotal: "إجمالي الضرائب",
        insuranceTotal: "إجمالي التأمين",
        total: "مجموع",
        submit: "حفظ",
        balanceShort: "الميزانية",
      },
      edit: {
        success: "تم تحديث خانة الوقت بنجاح",
      },
    },
    jobLocation: {
      stepTitle: (current, total) => `الخطوة ${current} من ${total}`,
      title: "موقع الوظيفة",
      actions: {
        submit: "انشر عرض عمل",
      },
    },
    repost: {
      title: "هل تود إعادة نشر حجز قديم؟",
      repost: "إعادة نشر",
      modal: {
        title: "إعادة نشر حجز",
        repostBookingCard: {
          view: "رؤية",
          repost: "إعادة نشر",
        },
        viewAll: "رؤية جميع الحجوزات",
      },
    },
  },
  insufficient: {
    // title: "You're out of Kader Credits!",
    title: "رصيدك في كادر لا يكفي لإتمام هذه العملية",
    subTitle: "لإجراء اتصال ، تحتاج إلى شراء حزمة",
    submit: "الذهاب الى الحزم",
  },
  noJobs: {
    title: "لا يوجد وظائف",
    subTitle: "لم يتم نشر إي وظيفة إلى الان",
    button: "إنشر وظيفة",
  },
  newJob: {
    title: "وظيفة جديدة",
    jobOffer: {
      title: "إضافة وظيفة",
      description:
        "تبحث عن الموظف المثالي؟ تواصل مع العديد من المواهب المتوفرة عن طريق ميزة الرصيد المرن في التطبيق",
    },
    booking: {
      title: "إطلب كادر مؤهل بالساعة",
      description:
        "كادر تقابل وتؤهل وتشبك مجموعة من الكوادر بوظائف مرنة على شكل ساعات او موياومات",
      action: "إطلب كادر مؤهل",
    },
    hireing: {
      title: "كادر توظف لأجلك",
      description: "لا تقلق، احنا منوظف عنك",
    },
    actions: {
      next: "التالي",
      back: "العودة",
    },
    success: {
      title: "شكرا لك, نحن نطابقك مع كديروز مؤهلين",
      back: "الرجوع الى الصفحة الرئيسية",
      addNew: "أضافة حجز جديد",
    },
  },
  editJob: {
    title: "تعديل الوظيفة",
    deleteModal: {
      title: "هل أنت متأكد أنك تريد حذف هذه الوظيفة؟",
      subTitle: "بمجرد الحذف ، لن تتمكن من التراجع.",
      cancel: "إلغاء",
      ok: "نعم. أنا متأكد!",
    },
  },
  repostJob: {
    title: "إعادة نشر الوظيفة",
  },
  gigView: {
    matched: "المرشحين المتطابقين",
    matching: "مطابقة المرشحين",
    matchingSubTitle: "نحن نختار بعناية أفضل مرشح بالنسبة لك",
    gigAddress: {
      title: "موقع الحجز",
    },
    notes: {
      title: "Aditional Notes:",
      placeholder: "Type here...",
    },
    gigWalletCard: {
      title: "بطاقة الدفع المختارة",
      emptyWallet: {
        title: "هذا الحجز غير مرفق ببطاقة ائتمان",
        action: "إضافة بطاقة ائتمان",
      },
      changeCard: "تعديل البطاقة",
    },
    attendances: {
      title: "العمال",
    },
    attendance: {
      delete: {
        title: "هل انت متاكد من انك تريد حذف بيانات هذا اليوم؟",
        ok: "نعم",
        cancel: "لا",
      },
      startTime: "وقت البدء",
      endTime: "وقت الإنتهاء",
      arriveTime: "وقت الوصول",
      leaveTime: "وقت المغادرة",
      titles: "المسميات الوظيفية",
      price: "السعر بالساعة",
      duration: "المدة",
      hours: "ساعات",
      workingHours: "ساعات العمل",
      totalAmount: "المبلغ الكلي",
      day: " يوم",
      insurancePricePerHour: "سعر التأمين بالساعة",
      rating: {
        title: (value) => `ما هو تقييمك لهذا الحضور${value}؟`,
        // title:(value) => `How would you rate the attendance of this day ${value} ?`,
        reason: "هل يمكن أن تخبرنا لماذا؟",
        other: "هل يمكنك تزويدنا بالسبب الآخر",
        submit: "إرسال",
        submitted: "تم تقييم هذا الحضور",
      },
      callClick: {
        directContact: "اتصال مباشر",
        whatsApp: "WhatsApp",
        view: "إظهار الرقم",
      },
      noUser: "قيد البحث...",
      empty: "لا يوجد حضور",
    },
  },
  user: {
    bio: "الوصف",
    titles: "المسميات الوظيفية",
    experience: "الخبرة",
    education: "التعليم",
    noEducation: "لا يوجد لدينا معلومات عن تعليم المرشح",
    languages: "اللغات",
  },
  success: {
    job: "تم نشر الوظيفة",
    gig: "تم نشر الحجز!",
    action: "شكراََ",
    none: "",
  },
  home: {
    myJobs: "الوظائف",
    overview: "نظرة عامة",
    jobs: "الوظائف",
    gigs: "وظائف بالساعة",
    repost: "إعادة نشر",
  },
  verification: {
    email: {
      title: "",
      loading: "الرجاء الانتظار حتى يتم التحقق من عنوان بريدك الإلكتروني ...",
      verified: "تم التحقق من بريدك الإلكتروني الآن",
      redirect: "تسجيل الدخول",
    },
  },
  forgotEmail: {
    title: "تحقق من رقم هاتفك المحمول",
    resetTitle: "أدخل عنوان بريدك الإلكتروني الجديد",
    sendCode: "أرسل رمز التحقق",
    resetEmail: "إعادة تعيين",
    seconds: "ثواني",
    messages: {
      emailSent: "تم إرسال رابط التحقق إلى بريدك الإلكتروني",
      emailSentSubtitle:
        "تحقق من بريدك الإلكتروني من فضلك ، سيتم إعادة توجيهك إلى شاشة تسجيل الدخول قريبًا",
    },
  },
  completeProfile: {
    verifyMobileNumber: {
      title: "مطلوب تحديث الحساب",
      subTitle: "يرجى تاكيد رقم هاتفك المحمول",
      action: "تأكيد",
      successMessage: "تم تأكيد رقم هاتفك الآن",
      errorMessage: "رمز خاطئ",
      verificationCodeSent: "تم إرسال رمز التحقق عبر الرسائل القصيرة إلى",
    },
    verifyEmailAddress: {
      title: "مطلوب تحديث الحساب",
      subTitle: "يرجى التحقق من عنوان البريد الإلكتروني الخاص بك",
      action: "تأكيد",
      successMessage: "تم إرسال رابط التحقق إلى بريدك الإلكتروني",
    },
  },
  recruiterProfile: {
    country: "الدولة",
    nationality: "الجنسية",
    emptyLocation: "قم بإضافة موقعك",
    location: "موقعك",
    gender: "الجنس",
    empty: (text) => `قم بإضافة ${text}`,
    editProfile: "تعديل المعلومات",
    mobileNumber: "رقم الهاتف",
    email: "البريد الإلكتروني",
    emailSent:
      "تم إرسال رابط التحقق إلى بريدك الإلكتروني ، يرجى التحقق من بريدك الإلكتروني الجديد.",
    updateMobile: {
      title: "قم بتحديث رقم هاتفك المحمول",
    },
  },
  profile: {
    sideBar: {
      profile: "المعلومات الشخصية",
      company: "الشركة",
      bookings: "إحصائيات الحجوزات",
      wallet: "الميزانية",
    },
  },
  companyProfile: {
    employeesRange: "عدد الموظفين",
    industry: "القطاع",
    numberOfBranches: "عدد الفروع",
  },
  qrCode: {
    title: "رمز الاستجابة السريعة للشركة",
    description:
      "هذا رمز QR فريد لكي تحافظ عليه بأمان. Kaderos يدخل ويخرج عن طريق مسح رمز الاستجابة السريعة هذا قبل بداية ونهاية كل وظيفة.",
  },
  currencies: {
    JOD: "د.أ",
    SAR: "ريال سعودي",
  },
  creditCardRedirect: {
    loading: "الرجاء الانتظار ...",
    success: "تم إنشاء بطاقتك بنجاح",
    subTitle: "سوف يتم إعادة توجيهك بعد ٥ ثواني",
  },
  payPendingTransactions: {
    title: "دفع المعاملات",
    balance: "استخدام الميزانية",
    cards: "البطاقات",
    selectedCreditCard: "البطاقة المختارة",
    cashOrChek: "نقداََ او شيك",
    bookingTotal: "إجمالي السعر",
    taxTotal: "إجمالي الضرائب",
    insuranceTotal: "إجمالي التأمين",
    total: "مجموع",
    submit: "إدفع",
  },
  creditCardForm: {
    create: {
      title: "أضف بطاقة",
      submit: "إرسال",
    },
    edit: {
      title: "تغيير البطاقة الافتراضية",
      submit: "غير البطاقة",
    },
    delete: {
      alert: "هل أنت متأكد؟",
      yes: "نعم",
      no: "لا",
    },
  },
  willContactYouSoon: {
    title: "سيقوم فريق دعم العملاء لدينا بالاتصال بك قريبًا",
  },
  inviteRecruiter: {
    createTitle: "أضف موظف جديد",
    editTitle: "تعديل البيانات",
    form: {
      fields: {
        firstName: {
          label: "الاسم الأول",
        },
        lastName: {
          label: "الكنية",
        },
        email: {
          label: "عنوان البريد الإلكتروني",
        },
        phone: {
          label: "رقم الهاتف المحمول",
        },
        password: {
          label: "كلمه السر",
        },
        jobTitle: {
          label: "عنوان وظيفي",
        },
        role: {
          label: "الصلاحية",
        },
      },
      actions: {
        submit: "ارسل دعوة",
        edit: "حفظ",
      },
    },
    created: {
      title: "تم إرسال رابط الدعوة إلى",
      action: "شكرا",
    },
  },
  surveyBuilder: {
    title: "",
    form: {
      fields: {
        title: {
          label: "Title",
          placeholder: "عنوان الاستبيان",
        },
        description: {
          label: "Description",
          placeholder: "وصف الاستبيان",
        },
        questions: {
          label: "الأسئلة",
          questionHeader: (index) => `Question number ${index}`,
          fields: {
            title: {
              label: "السؤال",
              placeholder: "السؤال",
            },
            description: {
              label: "وصف السؤال",
              placeholder: "وصف السؤال",
            },
            type: {
              label: "نوع السؤال",
              placeholder: "نوع السؤال",
            },
            logic: {
              label: "اختر سؤالا",
              placeholder: "اختر سؤالا",
            },
            options: {
              label: "الخيارات",
              optionPlaceholder: "الخيارات",
              other: "أخرى",
              addOption: "إضافة خيار",
              or: " أو ",
              addOther: "أضف أخرى",
            },
            isRequired: {
              label: "هل هذا الحقل إجباري",
            },
            allowOther: {
              label: "",
            },
          },
        },
        actions: {
          sort: "أعد ترتيب الأسئلة",
          stopSprting: "الإنتهاء من الترتيب",
          sortDescription:
            "يمكنك تغيير ترتيب الأسئلة عن طريق سحب وإسقاط بطاقة السؤال.",
        },
      },
      actions: {
        submit: "حفظ",
        cancel: "إلغاء",
        addAnotherQuestion: "إضافة سؤال اخر",
        addQuestion: "إضافة سؤال",
        addLogic: "منطق",
      },
    },
    deleteAlert: {
      title: "هل أنت متأكد أنك تريد حذف هذا السؤال؟",
      yes: "نعم",
      no: "لا",
    },
  },
  surveyScreen: {
    downloadTitle: "تنزيل الاستبيان بتنسيق CSV",
    download: "تنزيل",
    responses: "الأجوبة",
    downloadModal: {
      title: "تحميل الملف بصيغة",
      fileTypes: {
        xls: "ملف اكسل",
        csv: "ملف CSV",
      },
      action: "تحميل",
    },
    submittedAt: "أجيبت في تاريخ",
    numberOf: "من",
  },
  notFoundScreen: {
    title: "عذرا لم يتم العثور على هذا الاستبيان",
  },
  surveys: {
    title: "الاستبيانات",
    attachSurveyModal: {
      title: "قائمة الاستبيانات الخاصة بك",
      attach: "إرفاق",
      change: "تغيير الاستبيان",
    },
  },
  favorites: {
    title: "الكاديروز",
    unFavourite: {
      title:
        "هل أنت متأكد أنك تريد إزالة هذا الكاديرو من قائمة الكاديروز لديك؟",
    },
  },
  topPerforming: {
    title: "الكاديروز الأكثر أداء",
    noData: "لا توجد بيانات",
  },
  shiftsLogs: {
    title: "سجلات الحضور",
    table: {
      columns: {
        name: "الاسم",
        title: "العنوان الوظيفي",
        location: "الموقع",
        dateOfAttendance: "تاريخ الحضور",
        clockIn: "ساعة الدخول",
        clockOut: "ساعة الخروج",
        totalHours: "إجمالي الساعات",
        rating: "التقييم",
      },
    },
  },
};

export default arEG;
