import React from "react";

interface Props {
  image;
  name;
  role;
}

function CompanyPlaceholder(props: Props) {
  return (
    <div className="flex items-end cursor-pointer">
      <div className="text-right mb-1 ml-auto">
        <span className="block font-avenir-medium">{props.name}</span>
        <span className="block text-xs text-grey font-light">{props.role}</span>
      </div>
      <div className="ml-3 overflow-hidden border" style={{ borderRadius: 12 }}>
        <div
          style={{
            backgroundImage: `url(${props.image})`,
            backgroundSize: "cover",
            backgroundOrigin: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "3.125rem",
            width: "3.125rem",
            borderRadius: 12,
          }}
        />
      </div>
    </div>
  );
}

export default CompanyPlaceholder;
