import React, { useState } from "react";
import Loader from "shared/components/Loader";
import moment from "moment";
import "moment/locale/ar";
import { siteLanguages } from "lib/constants/general";
import { getLang } from "lib/services/LocalStorage";

function withImports(WrappedComponent) {
  function WithImports(props) {
    const [loading, setLoading] = useState(true);
    const importEnglishStyles = () => {
      moment.locale(siteLanguages.EN.value);
      // @ts-ignore
      import("css/tailwind.css").then(() => {
        toggleLoader();
      });
    };

    const importArabicStyles = () => {
      moment.locale(siteLanguages.AR.value);
      // @ts-ignore
      import("css/tailwind.rtl.css").then(() => {
        toggleLoader();
      });
    };

    const toggleLoader = () => {
      setTimeout(() => {
        setLoading(false);
      }, 750);
    };

    if (getLang() === siteLanguages.EN.value) {
      importEnglishStyles();
    } else {
      importArabicStyles();
    }
    // @ts-ignore
    // import("css/custom.css");

    return <>{loading ? <Loader /> : <WrappedComponent {...props} />}</>;
  }

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  WithImports.displayName = `withImports(${wrappedComponentName})`;
  return WithImports;
}

export default withImports;
