import moment from "moment";
import { routesConstants } from "./urls";

export const OTP_LENGTH = 4;

export enum countriesValues {
  JORDAN = "3",
  KSA = "2",
}

export enum gigStatuses {
  PENDING = 1,
  IN_PROGRESS = 2,
  FINISHED = 3,
  DUE = 4,
  NO_SHOW = 5,
}

export enum attendancesStatusesValues {
  PENDING = 1,
  IN_PROGRESS = 2,
  FINISHED = 3,
  DUE = 4,
  NO_SHOW = 5,
}

export enum statusesColors {
  pending = "orange", //Gigs and attendances
  "in-progress" = "primary", //Gigs and attendances
  assigned = "primary",
  completed = "green",
  overdue = "red",
  cancelled = "light-orange",

  //Attendances
  finished = "green",
  due = "light-orange",
  "no-show" = "red",
}

export const DEFAULT_PAGE_SIZE = 5;

/**
 * Site languages
 */

export const siteLanguages = {
  AR: {
    value: "ar",
    dir: "rtl",
  },
  AR_EG: {
    value: "ar-EG",
    dir: "rtl",
  },
  EN: {
    value: "en",
    dir: "ltr",
  },
};

export enum MessageTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const yesNoOptions = [
  {
    titleEn: "Yes",
    titleAr: "نعم",
    value: 1,
  },
  {
    titleEn: "No",
    titleAr: "لا",
    value: 0,
  },
];

export const routes = {
  PROFILE: {
    MAIN: routesConstants.PROFILE_RECRUITER,
    COMPANY: routesConstants.PROFILE_COMPANY,
    BOOKINGS_STATISTICS: routesConstants.PROFILE_BOOKING_STATISTICS,
    COMPANY_WALLET: routesConstants.PROFILE_BALANCE,
  },
  GUEST: {
    LOGIN: routesConstants.GUEST_LOGIN,
  },
};

export enum Roles {
  ADMIN = 1,
  EDITOR = 2,
  VIEWER = 3,
}

export enum ResourcesActions {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
  READ = "READ",
}

export enum Resources {
  GIGS = "gigs",
  ADDRESSES = "addresses",
  RECRUITERS = "recruiters",
  COMPANY = "company",
  INVITES = "INVITES",
}

export const permissions = {
  [Roles.ADMIN]: {
    [Resources.GIGS]: Object.values(ResourcesActions),
    [Resources.ADDRESSES]: Object.values(ResourcesActions),
    [Resources.RECRUITERS]: Object.values(ResourcesActions),
    [Resources.COMPANY]: Object.values(ResourcesActions),
    [Resources.INVITES]: Object.values(ResourcesActions),
  },
  [Roles.EDITOR]: {
    [Resources.GIGS]: Object.values(ResourcesActions),
    [Resources.ADDRESSES]: [ResourcesActions.READ],
    [Resources.RECRUITERS]: [ResourcesActions.READ],
    [Resources.COMPANY]: [ResourcesActions.READ],
    [Resources.INVITES]: false,
  },
  [Roles.VIEWER]: {
    [Resources.GIGS]: [ResourcesActions.READ],
    [Resources.ADDRESSES]: [ResourcesActions.READ],
    [Resources.RECRUITERS]: [ResourcesActions.READ],
    [Resources.COMPANY]: [ResourcesActions.READ],
    [Resources.INVITES]: false,
  },
};

export const DEFAULT_DAET_FORMAT = "YYYY-MM-DD";

export enum PaymentTypesRef {
  BALANCE_CARD = 1,
  CARD = 2,
  CASH_OR_CHEK = 3,
}

export enum transactionStatuses {
  PENDING = 1,
  SUCCESS = 2,
  FAILD = 3,
}

export enum countriesCodes {
  EGY = "EGY",
  JOR = "JOR",
}

export const siteCountries = [
  {
    code: countriesCodes.JOR,
    path: "jordan",
    languages: {
      EN: siteLanguages.EN,
      AR: siteLanguages.AR,
    },
    location: {
      lat: 31.975712,
      lng: 35.865474,
    },
  },
  {
    code: countriesCodes.EGY,
    path: "egypt",
    languages: {
      EN: siteLanguages.EN,
      AR: siteLanguages.AR_EG,
    },
    location: {
      lat: 30.033333,
      lng: 31.233334,
    },
  },
];

export const pendingTransactionsGroupStatuses = [
  transactionStatuses.PENDING,
  transactionStatuses.FAILD,
];
// can(1,"gigs", "create")

export const asttendacesStatusGroup = [
  {
    value: [2],
    titleAr: "قيد العمل",
    titleEn: "In Progress",
  },
  {
    value: [1],
    titleAr: "قيد الإنتظار",
    titleEn: "Pending",
  },
  {
    value: [3, 4],
    titleAr: "السجل",
    titleEn: "History",
  },
];

export enum dateDropdownValues {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  LAST_YEAR = "LAST_YEAR",
  ALL_TIMES = "ALL_TIMES",
  CUSTOM_DATE_RANGE = "CUSTOM_DATE_RANGE",
}

export const dateDropdownOptions = [
  {
    labelEn: "Today",
    labelAr: "اليوم",
    value: dateDropdownValues.TODAY,
  },
  {
    labelEn: "Yesterday",
    labelAr: "أمس",
    value: dateDropdownValues.YESTERDAY,
  },
  {
    labelEn: "This week",
    labelAr: "هذا الأسبوع",
    value: dateDropdownValues.THIS_WEEK,
  },
  {
    labelEn: "This Month",
    labelAr: "هذا الشهر",
    value: dateDropdownValues.THIS_MONTH,
  },
  {
    labelEn: "This Year",
    labelAr: "هذه السنة",
    value: dateDropdownValues.THIS_YEAR,
  },
  {
    labelEn: "Last Week",
    labelAr: "الأسبوع الماضي",
    value: dateDropdownValues.LAST_WEEK,
  },
  {
    labelEn: "Last Month",
    labelAr: "الشهر الماضي",
    value: dateDropdownValues.LAST_MONTH,
  },
  {
    labelEn: "Last Year",
    labelAr: "السنة الماضية",
    value: dateDropdownValues.LAST_YEAR,
  },
  {
    labelEn: "All Times",
    labelAr: "جميع الأوقات",
    value: dateDropdownValues.ALL_TIMES,
  },
  {
    labelEn: "Custom Date Range",
    labelAr: "نطاق التاريخ المخصص",
    value: dateDropdownValues.CUSTOM_DATE_RANGE,
  },
];

export enum SURVEY_QUESTION_TYPES {
  PARAGRAPH = 1,
  CHECKBOXES = 2,
  MULTIPLE_CHOICE = 3,
  UPLOAD_IMAGE = 4,
  DATE = 5,
  TIME = 6,
  PHONE = 7,
  LOCATION = 8,
}

export const VALUES_ELIGIBLE_WITH_OTHER_OPTION = [
  SURVEY_QUESTION_TYPES.CHECKBOXES,
  SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE,
];

export enum SURVEY_TABS {
  QUESTIONS = "QUESTIONS",
  RESPONSES = "RESPONSES",
}

export enum RESPONSES_TABS {
  SUMMARY = "SUMMARY",
  QUESTION = "QUESTION",
  INDIVIDUAL = "INDIVIDUAL",
}

export const surveyScreenTabs = [
  {
    key: SURVEY_TABS.QUESTIONS,
    labelEn: "Questions",
    labelAr: "أسئلة",
  },
  {
    key: SURVEY_TABS.RESPONSES,
    labelEn: "Responses",
    labelAr: "استجابات",
  },
];

export const surveyScreenResponsesTabs = [
  {
    key: RESPONSES_TABS.SUMMARY,
    labelEn: "Summary",
    labelAr: "ملخص",
  },
  {
    key: RESPONSES_TABS.INDIVIDUAL,
    labelEn: "Individual",
    labelAr: "فرد",
  },
];

export enum DOWNLOADABLE_FILE_TYPES {
  EXCEL = "xlsx",
  CSV = "csv",
}
