import "firebase/analytics";
import firebase from "firebase/app";
import { firebaseConfig } from "lib/constants/urls";
import { isProduction } from "lib/helpers/general";

export const firebaseService = {
  init: () => {
    if (isProduction()) {
      firebase.initializeApp(firebaseConfig);
    }
  },
  initAnalytics: () => {
    if (isProduction()) {
      firebase.analytics();
    }
  },
};
