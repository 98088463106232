import React from "react";
import { chatBotInitialState, ChatBotReducer } from "./ChatBotReducer";

export const ChatBotContext: any = React.createContext(null);

export const ChatBotProvider = (props) => {
  const [chatbotState, dispatchChatbotState] = React.useReducer(
    ChatBotReducer,
    chatBotInitialState
  );

  return (
    <ChatBotContext.Provider value={{ chatbotState, dispatchChatbotState }}>
      {props.children}
    </ChatBotContext.Provider>
  );
};

export default ChatBotProvider;
