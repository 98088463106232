const colors = {
  primary: "#453182",
  blackish: "#31383D",
  blacker: "#333333",
  grey: "#A8B6C5",
  gray: "#1F2126",
  "dark-gray": "#525A74",
  red: "#FF0A2E",
  blue: "#0084FF",
  // solitude: "#F0EDFA",
  orange: "#FF9800",
  "light-orange": "#F89C1C",
  green: "#6EE08B",
  "white-smoke": "#F5F5F5",
  "ghost-white": "#FAF9FF",
  "cobalt-gray": "#BABFC8",
  solitude: "#EBECEF",
  white: "#fff",
};

const rgbColors = {
  primary: (opacity) => `rgba(69, 49, 130, ${opacity ?? 1})`,
  orange: (opacity) => `rgba(255, 152, 0, ${opacity ?? 1})`,
  green: (opacity) => `rgba(110, 224, 139, ${opacity ?? 1})`,
  "dark-gray": (opacity) => `rgba(82, 90, 116, ${opacity ?? 1})`,
  "cobalt-gray": (opacity) => `rgba(186, 191, 200, ${opacity ?? 1})`,
};

const shadows = {
  green: "rgba(126, 211, 33, 0.3)",
  red: "rgba(255, 10, 46, 0.404911)",
  primary: "rgba(69, 49, 130, 0.3)",
};

const linears = {
  purple: "linear-gradient(164.81deg, #453182 -40.15%, #721FB3 100%)",
  bubble: "linear-gradient(131.78deg, #453182 -40.15%, #721FB3 100%)",
};

const breakpoints = ["31.25em", "43.75em", "46.875em"];
const fontSizes = [
  "1.2rem",
  "1.4rem",
  "1.6rem",
  "1.8rem",
  "2.4rem",
  "2.8rem",
  "3.2rem",
  "4.0rem",
  "4.8rem",
  "6.4rem",
];
const space = [
  "0",
  ".4rem",
  ".8rem",
  "1.2rem",
  "1.6rem",
  "2.0rem",
  "3.2rem",
  "4.8rem",
  "6.4rem",
  "9.6rem",
];

const rounded = {
  sm: "0.5rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
};

export interface StyleClosetTheme {
  breakpoints: string[];
  fontSizes: string[];
  space: string[];
  colors: { [key in keyof typeof colors]: string };
  shadows: { [key in keyof typeof shadows]: string };
  rounded: { [key in keyof typeof rounded]: string };
  linears: { [key in keyof typeof linears]: string };
  rgbColors;
}

export const theme: StyleClosetTheme = {
  breakpoints,
  fontSizes,
  space,
  colors,
  shadows,
  rounded,
  linears,
  rgbColors,
};
